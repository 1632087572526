import React, { useState, useEffect } from "react";
import logo from "assets/img/logo.png";
import axios from "axios";
import Cookies from "js-cookie";
import { HStack, useToast } from "@chakra-ui/react";
import config from "config";
import { Link as RouterLink } from "react-router-dom";

// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  DarkMode,
} from "@chakra-ui/react";

// Assets
// import SignUpImage from "assets/img/SignUpImage.png";

// Custom Components
import GradientBorder from "components/GradientBorder/GradientBorder";

// Api Service
import { usePostData } from "apiService";
import InfoBubble from "misc/InfoBubble";

function SignUp() {
  const titleColor = "white";
  const textColor = "gray.400";
  const toast = useToast()

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstname, setFirstName] = useState("");
  const [name, setName] = useState("");
  const [access_request_token, setAccessRequestToken] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSent, setIsSent] = useState(false);
  const { postData } = usePostData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRegister = async (event) => {
    event.preventDefault();

    // Check if password is not empty
    if (username === "" || password === "" || firstname === "" || name === "" || access_request_token === "") {
      toast({
        title: "Oops",
        description: "Veuillez remplir tous les champs",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      })
      return;
    }

    // check if the both passwords are the same
    if (password !== confirmPassword) {
      toast({
        title: "Oops",
        description: "Les mots de passe ne correspondent pas",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      })
      return;
    }

    try {
      // TODO : replace using APISERVICE
      const response = await axios.post(config.apiUrl + "/register", {
        name,
        firstname,
        username,
        password,
        access_request_token
      });
      // display success message
      setIsSent(true);
      toast({
        title: "Demande envoyée avec succès",
        description: "Votre demande d'accès a été envoyée avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      })

    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status === 400) {
        // Affiche un message d'erreur spécifique pour les erreurs 400
        toast({
          title: "Oops",
          description: error.response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        // Affiche un message d'erreur général pour les autres types d'erreurs
        toast({
          title: "Oops",
          description: error.response.data.message || "Une erreur s'est produite lors de l'envoi de la demande d'accès.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };

  if (!isSent) {
    return (
      <form onSubmit={handleRegister}>
        <Flex position='relative'>
          <Flex
            minH='100vh'
            h={{ base: "fit-content" }}
            w='100%'
            mx='auto'
            me={{ base: "auto", lg: "50px", xl: "auto" }}>
            <GradientBorder
              mt={{ base: "100px" }}
              mb={{ base: "100px" }}
              style={{
                position: "relative",
                left: "50%",
                transform: "translate(-50%, 0%)"
              }}>
              <Flex
                background='transparent'
                borderRadius='30px'
                direction='column'
                p='40px'
                minW={{ base: "unset", md: "430px", xl: "450px" }}
                w='100%'
                mx={{ base: "0px" }}
                bg={{
                  base: "rgb(19,21,56)",
                }}>
                <Flex
                  style={{ userSelect: "none" }}
                  position='relative'
                  left='50%'
                  transform='translate(-50%, 0%)'
                  px='50px'>
                  <Flex
                    direction='column'
                    w='100%'
                    alignItems='center'
                    background='transparent'>
                    <img src={logo} width='150px'></img>
                    <Heading color={titleColor} fontSize='32px' mb='10px'>
                      {config.appName}
                    </Heading>
                    <Text
                      mb='36px'
                      ms='4px'
                      color={textColor}
                      fontWeight='bold'
                      align='center'
                      fontSize='14px'>
                      Demander un accès à la plateforme
                    </Text>

                    {/* Champ token */}
                    <FormControl alignContent='center' w='100%' maxW='350px'>
                      <HStack>
                      <FormLabel color='white' mr="-10px">Token d'accès au serveur</FormLabel>
                      <InfoBubble message="Ce token est nécessaire à la demande d'accès au serveur." />
                      </HStack>
                      <GradientBorder mb='24px' borderRadius='20px'>
                        <Input
                          value={access_request_token}
                          onChange={(e) => setAccessRequestToken(e.target.value)}
                          color='white'
                          bg='rgb(19,21,54)'
                          border='transparent'
                          borderRadius='20px'
                          placeholder='Token'
                        />
                      </GradientBorder>
                    </FormControl>

                    {/* Champ Nom */}
                    <FormControl alignContent='center' w='100%' maxW='350px'>
                      <FormLabel color='white'>Nom</FormLabel>
                      <GradientBorder mb='24px' borderRadius='20px'>
                        <Input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          color='white'
                          bg='rgb(19,21,54)'
                          border='transparent'
                          borderRadius='20px'
                          placeholder='Votre nom'
                        />
                      </GradientBorder>
                    </FormControl>

                    {/* Champ Prénom */}
                    <FormControl alignContent='center' w='100%' maxW='350px'>
                      <FormLabel color='white'>Prénom</FormLabel>
                      <GradientBorder mb='24px' borderRadius='20px'>
                        <Input
                          value={firstname}
                          onChange={(e) => setFirstName(e.target.value)}
                          color='white'
                          bg='rgb(19,21,54)'
                          border='transparent'
                          borderRadius='20px'
                          placeholder='Votre prénom'
                        />
                      </GradientBorder>
                    </FormControl>

                    {/* Identifiant */}
                    <FormControl alignContent='center' w='100%' maxW='350px'>
                      <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='normal'

                        color='white'>
                        identifiant
                      </FormLabel>
                      <GradientBorder
                        mb='24px'
                        w={{ base: "100%", lg: "fit-content" }}
                        borderRadius='20px'>
                        <Input
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          color='white'
                          bg='rgb(19,21,54)'
                          border='transparent'
                          borderRadius='20px'
                          fontSize='sm'
                          size='lg'
                          w={{ base: "100%", md: "346px" }}
                          maxW='100%'
                          h='46px'
                          placeholder='Votre identifiant'
                        />
                      </GradientBorder>
                    </FormControl>

                    {/* Mot de passe */}
                    <FormControl alignContent='center' w='100%' maxW='350px'>
                      <FormLabel
                        type='password'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='normal'
                        color='white'>
                        Mot de passe
                      </FormLabel>
                      <GradientBorder
                        mb='24px'
                        w={{ base: "100%", lg: "fit-content" }}
                        borderRadius='20px'>
                        <Input
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          color='white'
                          bg='rgb(19,21,54)'
                          border='transparent'
                          borderRadius='20px'
                          fontSize='sm'
                          size='lg'
                          w={{ base: "100%", md: "346px" }}
                          maxW='100%'
                          type='password'
                          placeholder='Votre mot de passe'
                        />
                      </GradientBorder>
                    </FormControl>

                    {/* Champ Confirmation du mot de passe */}
                    <FormControl alignContent='center' w='100%' maxW='350px'>
                      <FormLabel color='white'>Confirmation du mot de passe</FormLabel>
                      <GradientBorder mb='24px' borderRadius='20px'>
                        <Input
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          color='white'
                          bg='rgb(19,21,54)'
                          border='transparent'
                          borderRadius='20px'
                          type='password'
                          placeholder='Confirmez votre mot de passe'
                        />
                      </GradientBorder>
                    </FormControl>

                    {/* Demander un accès */}
                    <Button
                      variant='brand'
                      fontSize='16px'
                      type='submit'
                      w='100%'
                      maxW='350px'
                      h='45'
                      mb='20px'
                      mt='20px'>
                      DEMANDER UN ACCÈS
                    </Button>
                    <Flex
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      maxW='100%'
                      mt='0px'>
                      <Text color={textColor} fontWeight='medium'>
                        Vous avez déjà un compte ?
                        <Link as={RouterLink} to="/auth/signin" color={titleColor} ms='5px' fontWeight='bold'>
                          Se connecter
                        </Link>
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </GradientBorder>
          </Flex>
        </Flex>
      </form>

    );
  } else {
    return (
      <Flex position='relative'>
        <Flex

          minH='100vh'
          h={{ base: "fit-content" }}
          w='100%'
          mx='auto'
          me={{ base: "auto", lg: "50px", xl: "auto" }}
          direction='column'
          justifyContent='center'
          alignItems='center'
          background='transparent'
          borderRadius='30px'
        >
          <img src={logo} width='150px'></img>
          <Heading color={titleColor} fontSize='32px' mb='10px'>
            {config.tenantName}
          </Heading>
          <Text
            ms='4px'
            color={textColor}
            fontWeight='bold'
            align='center'
            padding='20px'
            fontSize='18px'>
            Votre demande d'accès a bien été envoyée.<br />Vous pourrez vous connecter dès que votre demande aura été validée.
          </Text>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            maxW='100%'
            mt='0px'>
            <Text color={textColor} fontWeight='medium'>
              Vous avez déjà un compte ?
              <Link as={RouterLink} to="/auth/signin" color={titleColor} ms='5px' fontWeight='bold'>
                Se connecter
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export default SignUp;
