/*

      =========================================================
      * XGuard
      =========================================================
      
      * Product Page: https://www.nelx.net/xguard
      * Copyright 2024 NELX (https://www.nelx.net/)
      
      =========================================================
*/

import React, { useEffect, useState } from "react";
import logo from "assets/img/logo.png";
import axios from "axios";
import Cookies from "js-cookie";
import { useToast } from "@chakra-ui/react";
import config from "config";
import { Link as RouterLink, useHistory, Redirect } from "react-router-dom";

// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  DarkMode,
} from "@chakra-ui/react";

// Assets
import signInImage from "assets/img/signInImage.png";

// Custom Components
import GradientBorder from "components/GradientBorder/GradientBorder";

function SignIn() {
  const titleColor = "white";
  const textColor = "gray.400";
  const toast = useToast()
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // If token is already set, redirect to dashboard
  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = Cookies.get("token");
        if(token === undefined) {
          return;
        }
        // TODO : replace using APISERVICE
        const response = await axios.get(`${config.apiUrl}/checkToken`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
          window.location.href = "/";
        }
      } catch (error) {
        return;
      }
    };

    checkToken();
  }, [Cookies.get("token")]);

  // Handle login
  const handleLogin = async (event) => {
    event.preventDefault();
    try {

      // Check if password and username are not empty
      if (username === "" || password === "") {
        toast({
          title: "Oops",
          description: "Veuillez remplir tous les champs",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        })
        return;
      }

      // set token expiration
      const token_expiration = rememberMe ? 365 : 1;
      // TODO : replace using APISERVICE
      const response = await axios.post(config.apiUrl + "/login", {
        username,
        password,
        token_expiration,
      });

      // Set token in cookies
      const { token } = response.data;
      Cookies.set("token", token, { expires: token_expiration });

      // Show success message
      toast({
        title: "Connexion réussie",
        description: "Bienvenue sur " + config.appName + " !",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      })

      // Redirect to dashboard
      setIsAuthenticated(true);
      window.location.href = "/";

    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Affiche un message d'erreur spécifique pour les erreurs 400
        toast({
          title: "Oops",
          description: error.response.data.message || "Vos identifiants sont incorrects",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      } else if (error.code === "ERR_NETWORK") {
        // Affiche un message d'erreur spécifique pour les erreurs réseau
        toast({
          title: "Oops",
          description: "Impossible de se connecter au serveur",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        // Affiche un message d'erreur général pour les autres types d'erreurs
        toast({
          title: "Oops",
          description: "Vos identifiants sont incorrects",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      }
    }    
  };

  if(isAuthenticated) {
    return <Redirect to="/admin" />;
  }

  return (
    <form onSubmit={handleLogin}>

      <Flex position='relative'>
        <Flex
          minH='100vh'
          h={{ base: "120vh", lg: "fit-content" }}
          w='100%'
          mx='auto'
          me={{ base: "auto", lg: "50px", xl: "auto" }}>
          <GradientBorder
            mt={{ base: "100px"}} 
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0%)"
            }}>
            <Flex
              background='transparent'
              borderRadius='30px'
              direction='column'
              p='40px'
              minW={{ base: "unset", md: "430px", xl: "450px" }}
              w='100%'
              mx={{ base: "0px" }}
              bg={{
                base: "rgb(19,21,56)",
              }}>
              <Flex
                style={{ userSelect: "none" }}
                position='relative'
                left='50%'
                transform='translate(-50%, 0%)'
                px='50px'>
                <Flex
                  direction='column'
                  w='100%'
                  alignItems='center'
                  background='transparent'>
                  <img src={logo} width='150px'></img>
                  <Heading color={titleColor} fontSize='32px' mb='10px'>
                    {config.appName}
                  </Heading>
                  <Text
                    mb='36px'
                    ms='4px'
                    color={textColor}
                    fontWeight='bold'
                    fontSize='14px'>
                    Entrez vos identifiants pour vous connecter
                  </Text>

                  {/* Identifiant */}
                  <FormControl alignContent='center' w='100%' maxW='350px'>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='normal'

                      color='white'>
                      identifiant
                    </FormLabel>
                    <GradientBorder
                      mb='24px'
                      w={{ base: "100%", lg: "fit-content" }}
                      borderRadius='20px'>
                      <Input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        color='white'
                        bg='rgb(19,21,54)'
                        border='transparent'
                        borderRadius='20px'
                        fontSize='sm'
                        size='lg'
                        w={{ base: "100%", md: "346px" }}
                        maxW='100%'
                        h='46px'
                        placeholder='Votre identifiant'
                      />
                    </GradientBorder>
                  </FormControl>

                  {/* Mot de passe */}
                  <FormControl alignContent='center' w='100%' maxW='350px'>
                    <FormLabel
                      type='password'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='normal'
                      color='white'>
                      Mot de passe
                    </FormLabel>
                    <GradientBorder
                      mb='24px'
                      w={{ base: "100%", lg: "fit-content" }}
                      borderRadius='20px'>
                      <Input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        color='white'
                        bg='rgb(19,21,54)'
                        border='transparent'
                        borderRadius='20px'
                        fontSize='sm'
                        size='lg'
                        w={{ base: "100%", md: "346px" }}
                        maxW='100%'
                        type='password'
                        placeholder='Votre mot de passe'
                      />
                    </GradientBorder>
                  </FormControl>

                  {/* Se souvenir de moi */}
                  <FormControl display='flex' alignContent='center' w='100%' maxW='350px'>
                    <DarkMode>
                      <Switch
                        id='remember-login'
                        colorScheme='brand'
                        me='10px'
                        isChecked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)} />
                    </DarkMode>
                    <FormLabel
                      htmlFor='remember-login'
                      mb='0'
                      ms='1'
                      fontWeight='normal'
                      color='white'>
                      Se souvenir de moi
                    </FormLabel>
                  </FormControl>

                  {/* Bouton de connexion */}
                  <Button
                    variant='brand'
                    fontSize='16px'
                    type='submit'
                    w='100%'
                    maxW='350px'
                    h='45'
                    mb='20px'
                    mt='20px'>
                    CONNEXION
                  </Button>

                  {/* Lien vers la page d'enregistrement' */}
                  <Flex
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    maxW='100%'
                    mt='0px'>
                    <Text color={textColor} fontWeight='medium'>
                      Vous n'avez pas de compte ?
                      <Link as={RouterLink} to="/auth/signup" color={titleColor} ms='5px' fontWeight='bold'>
                        Demander un compte
                      </Link>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </GradientBorder>
        </Flex>
      </Flex>
    </form>

  );
}

export default SignIn;
