import React from "react";

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Icon,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Loading from "../../misc/Loading";
import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";

// Table Components
import TableUsers from "components/Tables/TableUsers";

// Data
import { tablesProjectData } from "variables/general";

// Icons
import { AiFillCheckCircle } from "react-icons/ai";

import { useFetchData, usePostData } from "apiService";
import TableArrestsToValidate from "components/Tables/TableArrestsToValidate";

function validation() {

  const { data: arrestsToValidate } = useFetchData('arrestsToValidate');
  const [arrests, setArrests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const { postData } = usePostData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (arrestsToValidate) {
      setArrests(arrestsToValidate);
      setIsLoading(false);
    }
  }, [arrestsToValidate]);

  const validateArrest = async (arrestId) => {
    const response = await postData('validateArrest', { id: arrestId });
    if(response) {
      setArrests(arrests.filter(arrest => arrest.id !== arrestId));
      toast({
        title: "Procès-verbal validé",
        description: "Le procès-verbal a été validé avec succès.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      });
    }
  };

  const rejectArrest = async (arrestId, reason) => {
    const response = await postData('rejectArrest', { id: arrestId, reason });
    if(response) {
      setArrests(arrests.filter(arrest => arrest.id !== arrestId));
      toast({
        title: "Procès-verbal rejeté",
        description: "Le procès-verbal a été rejeté avec succès.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      });
    }
  };

  if (isLoading) return <Loading />;

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      {/* Authors Table */}
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <Text fontSize='lg' color='#fff' fontWeight='bold' mb='.5rem'>
          Validation des procès-verbaux en attente
        </Text>
        <Flex align='center'>
          {arrests && arrests.length > 0 ? (
          <Text fontSize='sm' color='gray.400' fontWeight='normal'>
            <Text fontWeight='bold' as='span'>
              {arrests ? arrests.length : 0}
            </Text>{' '}
            PV(s) en attente de validation.
          </Text>
          ) : (
          <Text fontSize='sm' color='gray.400' fontWeight='normal' mb='15px'>
            Aucun PV en attente de validation.
          </Text>
          )}
        </Flex>
        {arrests.length > 0 && (
        <CardBody>
          <Table variant='simple' color='#fff'>
            <Thead>
              <Tr my='.8rem' ps='0px' color='gray.400'>
                <Th
                  ps='0px'
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Rédacteur
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  N° PV
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Date de rédaction
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {arrests.map((row, index, arr) => {
                return (
                  <TableArrestsToValidate
                    arrest={row}
                    onValidate={validateArrest}
                    onReject={rejectArrest}
                    lastItem={index === arr.length - 1 ? true : false}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
        )}
      </Card>
    </Flex>
  );
}

export default validation;
