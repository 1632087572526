import React from "react";
import { Tooltip } from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Icon } from "@chakra-ui/react";
import imgInterrogation from "assets/img/interrogation.png";

const InfoBubble = ({ message }) => {
  return (
    <Tooltip
      label={message}
      hasArrow
      bg='rgba(0, 0, 155, 0.8)'
      color='white'
      border='1px'
      borderColor='blue.400'
      p='2'
      boxShadow='md'
      fontSize='sm'
      borderRadius='md'
      placement='top'
    >
      <span>
        <Icon
          as={AiOutlineInfoCircle}
          color='blue.400'
          w='20px'
          h='20px'
          ml='10px'
          cursor='pointer'
        />
      </span>
    </Tooltip>
  );
};

export default InfoBubble;