import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Text,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  useToast,
  Table,
  Thead,
  Th,
  Tbody,
  Icon,
  Select,
} from "@chakra-ui/react";
import { useFetchData, usePostData } from "apiService";
import Tablegrades from "components/Tables/TableGrades";
import { AiOutlineProfile, AiOutlinePlus } from "react-icons/ai";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Loading from "misc/Loading";
import TableGrades from "components/Tables/TableGrades";

function ListGrades() {
  const { data: allGrades } = useFetchData('grades');
  const { data: currentUsers } = useFetchData('users/0');
  const [grades, setGrades] = useState([]);
  const { data: allSectors } = useFetchData('sectors');
  const { data: mySectors } = useFetchData('mySector');
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [newGradeName, setnewGradeName] = useState("");
  const [isAddingGrade, setIsAddingGrade] = useState(false);
  const { postData } = usePostData();
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(grades.length / itemsPerPage);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const saveNewHierarchy = async (id, newOrder) => {
    if (!id) return;
    newOrder = newOrder.filter((id) => id !== null);
    const response = await postData('updateSectorHierarchy', { id: id, hierarchy: newOrder });

    if (!response) {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la mise à jour de la hiérarchie.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  const handleMoveUp = (id) => {
    const index = grades.findIndex((grade) => grade.id === id);
    if (index > 0) {
      const newGrades = [...grades];
      // Échanger les positions dans le tableau
      [newGrades[index], newGrades[index - 1]] = [newGrades[index - 1], newGrades[index]];
      setGrades(newGrades);
    }
  };

  const handleMoveDown = (id) => {
    const index = grades.findIndex((grade) => grade.id === id);
    if (index < grades.length - 1) {
      const newGrades = [...grades];
      // Échanger les positions dans le tableau
      [newGrades[index], newGrades[index + 1]] = [newGrades[index + 1], newGrades[index]];
      setGrades(newGrades);
    }
  };

  useEffect(() => {
    if (mySectors && mySectors.length > 0) {
      setSelectedSector(mySectors[0].id);
    }
  }, [mySectors]);


  useEffect(() => {
    if (grades.length > 0) {
      const newOrder = grades.filter((grade) => grade.id !== null).map((grade) => grade.id);
      saveNewHierarchy(selectedSector, newOrder);
    }
  }, [grades]);

  useEffect(() => {
    if (allGrades.length > 0) {
      setGrades(allGrades);
      setLoading(false);
    }
  }, [allGrades]);

  useEffect(() => {
    setSectors(allSectors);
    setSelectedSector(allSectors[0]?.id);
  }, [allSectors]);

  useEffect(() => {
    if (selectedSector && allGrades.length > 0 && allSectors.length > 0 && allSectors) {

      const filteredGrades = allGrades.filter((grade) => grade.secteur_id == selectedSector);
      try {
        const sectorHierarchy = allSectors.find((sector) => sector.id == selectedSector)?.hierarchy;
        if (sectorHierarchy) {
          const hierarchyIds = JSON.parse(sectorHierarchy);
          const sortedGrades = filteredGrades.sort((a, b) => {
            const indexA = hierarchyIds.indexOf(a.id);
            const indexB = hierarchyIds.indexOf(b.id);
            return indexA - indexB;
          });

          setGrades(sortedGrades);
        }
      } catch (error) {
        console.error("Error sorting grades:", error);
        setGrades(filteredGrades);
      }
    }
  }, [selectedSector, allGrades, allSectors]);

  const handleAddGrade = async () => {
    if (newGradeName.trim() === "") {
      toast({
        title: "Erreur",
        description: "Le nom du grade ne peut pas être vide.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      const response = await postData('grades', { name: newGradeName, sector: selectedSector });

      if (response) {
        setnewGradeName("");
        setIsAddingGrade(false);
        setGrades([{ id: response.id, name: newGradeName }, ...grades]);

        toast({
          title: "Succès",
          description: "Le nouveau grade a été ajouté avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Erreur",
          description: "Une erreur s'est produite lors de l'ajout du grade.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error adding grade:", error);
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de l'ajout du grade.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleSectorChange = (e) => {
    setSelectedSector(e.target.value);
  };

  const handleDeleteGrade = async (id) => {
    try {
      const response = await postData('DeleteGrade', { id });

      if (response) {
        // Refresh crime data
        setGrades(grades.filter(crime => crime.id !== id));

        toast({
          title: "Grade supprimé",
          description: "Le grade a été supprimé",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
    catch (error) {
      toast({
        title: "Oops",
        description: "Impossible de supprimer le grade",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  if (loading) return (<Loading />);

  return (
    <>
      <Card my='22px' overflowX={{ sm: 'scroll', xl: 'hidden' }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex direction='row' justify='space-between' w={"100%"}>
            <Flex direction='column' align='flex-start' w='50%'>
              <Flex alignItems='center'>
                <Text fontSize='lg' color='#fff' fontWeight='bold' mb='.5rem'>
                  Grades pour le secteur :
                </Text>
                <Select
                  value={selectedSector}
                  w='200px'
                  fontSize={"lg"}
                  mb={".4rem"}
                  ml={2}
                  fontWeight={"bold"}
                  border='0px'
                  bg='transparent'
                  color='brand.100'
                  _hover={{ borderColor: '#fff' }}
                  _focus={{ borderColor: '#fff', boxShadow: 'none' }}
                  onChange={handleSectorChange}
                >
                  {sectors.map((sector) => (
                    <option style={{ backgroundColor: '#030617', color: 'white' }} key={sector.id} value={sector.id}>
                      {sector.name}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Flex align='center'>
                <Icon
                  as={AiOutlineProfile}
                  color='green.500'
                  w='15px'
                  h='15px'
                  me='5px'
                />
                <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                  <Text fontWeight='bold' as='span' color='gray.400'>
                    {grades.length}
                  </Text>{" "}
                  grades
                </Text>
              </Flex>
            </Flex>
            {/* Choice of the sector */}
            <Button
              mr={2}
              colorScheme='green'
              onClick={() => setIsAddingGrade(true)}
            >
              <Icon as={AiOutlinePlus} w='20px' h='20px' mr={2} />
              Ajouter un nouveau grade
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          {grades.length < 1 ? (
            <Text color='gray.400' mb='20px'>
              Vous n'avez aucun grade pour le moment dans le secteur sélectionné.
            </Text>
          ) : (
            <>
              <Table variant='simple' color='#fff'>
                <Thead>
                  <Tr my='.8rem' ps='0px'>
                    <Th
                      color='gray.400'
                      fontFamily='Plus Jakarta Display'
                      borderBottomColor='#56577A'>
                      Nom
                    </Th>
                    <Th borderBottomColor='#56577A'></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {grades.slice(startIndex, endIndex).map((row) => (
                    <TableGrades
                      key={row.id}
                      id={row.id}
                      name={row.name}
                      handleDeleteGrade={handleDeleteGrade}
                      handleMoveUp={handleMoveUp}
                      handleMoveDown={handleMoveDown}
                      lastItem={row.id === grades[grades.length - 1].id}
                      firstItem={row.id === grades[0].id}
                    />
                  ))}
                </Tbody>
              </Table>
            </>
          )}
        </CardBody>
        {/* Pagination */}
        {totalPages > 1 && (
          <Flex justify="flex-start" mt="20px" mb="20px">
            <Button
              variant="ghost"
              color="white"
              _hover={{ bg: "brand.100" }}
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              <BiChevronLeft fontSize={32} />
            </Button>
            {Array.from({ length: totalPages }).map((_, index) => (
              <Button
                key={index}
                variant={currentPage === index + 1 ? "solid" : "ghost"}
                bg={currentPage === index + 1 ? "brand.200" : "transparent"}
                _hover={{ bg: "brand.100" }}
                color="white"
                onClick={() => goToPage(index + 1)}
                mx="2px"
              >
                {index + 1}
              </Button>
            ))}
            <Button
              variant="ghost"
              color="white"
              _hover={{ bg: "brand.100" }}
              onClick={nextPage}
              disabled={endIndex >= grades.length}
            >
              <BiChevronRight fontSize={32} />
            </Button>
          </Flex>
        )}
      </Card>


      {/* Modal for adding new crime */}
      <Modal isOpen={isAddingGrade} onClose={() => setIsAddingGrade(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un nouveau grade</ModalHeader>
          <ModalBody>
            <Input
              placeholder="Nom du grade"
              value={newGradeName}
              onChange={(e) => setnewGradeName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddGrade}>
              Ajouter
            </Button>
            <Button onClick={() => setIsAddingGrade(false)}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ListGrades;