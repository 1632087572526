import React, { useState, useEffect } from 'react';
import {
  Flex,
  ScaleFade,
  FormControl,
  FormLabel,
  Select,
  Input,
  Button,
  Heading,
  HStack,
  VStack,
  Text,
  Textarea,
  Avatar,
  Box,
  useToast,
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import Card from "components/Card/Card";
import CardCitoyen from 'components/Card/CardCitoyen';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';

import { useFetchData, usePostData } from 'apiService';
import PV from 'components/PV/PV';
import Loading from 'misc/Loading';

function NewArrest() {

  // Datepicker
  registerLocale('fr', fr);
  const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
    <Input
      onClick={onClick}
      value={value}
      ref={ref}
      style={{
        backgroundColor: 'transparent',
        color: 'white',
        border: '1px solid #E2E8F0',
        borderRadius: '5px',
        padding: '10px',
        width: '100%',
      }}
    />
  ));

  const toast = useToast();
  const { postData } = usePostData();

  const { data: citizens } = useFetchData('citizens');
  const { data: allUsers } = useFetchData('users');
  const { data: crimes } = useFetchData('crimes');
  const { data: arrest_types } = useFetchData('arrestsTypes');
  const { data: currentUserAPI } = useFetchData('users/0');
  const [error, setError] = useState('');

  const [currentUser, setCurrentUser] = useState(null);

  const [selectedArrestType, setSelectedArrestType] = useState(null);

  const [seizedObjects, setSeizedObjects] = useState(null);
  const [description, setDescription] = useState(null);
  const [descriptionSize, setDescriptionSize] = useState(0);

  const [selectedCrime, setSelectedCrime] = useState(null);
  const [selectedCrimes, setSelectedCrimes] = useState([]);

  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [participants, setParticipants] = useState([]);

  const [selectedCitizen, setSelectedCitizen] = useState(null);
  const [newCitizenData, setNewCitizenData] = useState({
    name: '',
    firstname: '',
    birthdate: '',
    address: '',
    phone: '',
    work: '',
    nationality: '',
    hair: '',
    ethnicity: '',
    sex_id: '',
    image_url: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (currentUserAPI) {
      setCurrentUser(currentUserAPI[0]);
    }
  }, [currentUserAPI]);

  const handleCitizenChange = (event) => {
    setSelectedCitizen(event.target.value);
  }

  const handleParticipantChange = (event) => {
    const participant = event.target.value;
    if (!participants.includes(participant)) {
      setParticipants([...participants, participant]);
    }
    setSelectedParticipant('');
  }

  const handleDescriptionChange = (e) => {
    try {
      setDescription(e.target.value);
      setDescriptionSize(e.target.value.length);
      setError(''); // Reset error state if change is successful
    } catch (err) {
      setError('Une erreur est survenue lors de la modification de la description.');
      console.error(err);
    }
  };

  const handleCrimeChange = (event) => {
    const crime = event.target.value;
    if (!selectedCrimes.includes(crime)) {
      setSelectedCrimes([...selectedCrimes, crime]);
    }
    setSelectedCrime('');
  }

  const handleNewCitizenInputChange = (event) => {
    const { name, value } = event.target;
    setNewCitizenData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleSave = async () => {

    // Check if all required fields are filled
    if (selectedCitizen == 'create') {
      if (!newCitizenData.name || !newCitizenData.firstname) {
        toast({
          title: "Informations du citoyen manquantes",
          description: "Veuillez renseigner le nom et le prénom du citoyen.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right"
        });
        return;
      }
    }

    if (!selectedArrestType) {
      toast({
        title: "Type de procès-verbal manquant",
        description: "Veuillez sélectionner un type de procès-verbal.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right"
      });
      return;
    }

    // 1. Créer un citoyen si besoin
    const response = await postData('arrests', {

      // Citoyen
      createCitizen: selectedCitizen == 'create' ? true : false,
      newCitizenData: selectedCitizen == 'create' ? newCitizenData : selectedCitizen,

      // Arrestation
      arrest: {
        // arrest.user_id, arrest.citizen_id, arrest.seized_objects, arrest.redaction, arrest.arrest_type_id
        user_id: currentUser.id,
        citizen_id: selectedCitizen == 'create' ? null : selectedCitizen,
        seized_objects: seizedObjects,
        redaction: description,
        arrest_type_id: selectedArrestType,
      },

      // Participants
      participants: participants,

      // Crimes
      crimes: selectedCrimes,

    });

    if (response) {
      toast({
        title: "Procès-verbal enregistré",
        description: "Le procès-verbal a bien été enregistré.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right"
      });

      // Reset the form
      setSelectedCitizen('');
      setNewCitizenData({
        name: '',
        firstname: '',
        birthdate: '',
        address: '',
        phone: '',
        work: '',
        nationality: '',
        hair: '',
        ethnicity: '',
        sex_id: '',
        image_url: '',
      });
      setSeizedObjects('');
      setDescription('');
      setSelectedArrestType('');
      setSelectedCrime('');
      setSelectedCrimes([]);
      setSelectedParticipant('');
      setParticipants([]);

    } else {
      toast({
        title: "Erreur lors de l'enregistrement",
        description: "Une erreur est survenue lors de l'enregistrement du procès-verbal. Le texte est peut-être trop long ou des données ne sont pas valides.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right"
      });
    }
  }

  return (
    <Flex direction='column' pt={"5%"} pb={"5%"}>
      <ScaleFade initialScale={0.1} in={true}>
        <Flex direction='column' color={'white'}>
          <Heading align="center">Nouveau procès-verbal</Heading>

          {/* 1. Auteur */}
          <Card mt={"20px"} mb={"10px"}>
            <Heading fontSize={"22px"} borderBottom={"1px solid white"} pb={"5px"}>1. Auteur</Heading>
            <Text fontSize='sm' color='gray.400' mt="10px" mb={"10px"}>🔸Sélectionner un citoyen existant ou créer un nouveau</Text>
            <FormControl>
              <Select mt={"10px"} _hover={{ backgroundColor: '#030617', transform: 'scale(1.005)', cursor: 'pointer' }}
                border={"none"} value={selectedCitizen} onChange={handleCitizenChange} color={selectedCitizen == 'create' ? 'lightgreen' : 'white'}>
                <option style={{ backgroundColor: '#030617', color: 'gray' }} value="">Sélectionner un citoyen</option>
                <option style={{ backgroundColor: '#030617', color: 'lightgreen' }} value="create">Créer un nouveau citoyen</option>
                {citizens && citizens.map(citizen => (
                  <option style={{ backgroundColor: '#030617', color: 'white' }} key={citizen.id} value={citizen.id}>{citizen.firstname} {citizen.name}</option>
                ))}
              </Select>
            </FormControl>
            {selectedCitizen && selectedCitizen != 'create' && <CardCitoyen idCitoyen={selectedCitizen} displayClearArrests={false} />}
            {selectedCitizen == 'create' && (
              <Box mt={"10px"}>
                <ScaleFade initialScale={0.1} in={true}>
                  <Card>
                    <VStack spacing={4} align="left" mb={"20px"} mt={"20px"}>
                      {/* Première ligne */}
                      <HStack spacing={4} align="center">
                        <Box position="relative">
                          <Avatar borderRadius="15px" size="2xl" name={newCitizenData.firstname} src={newCitizenData.image_url} onClick={() => document.getElementById('image_url_input').focus()} cursor="pointer" />
                        </Box>
                        <VStack spacing={4} align="stretch">
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>URL de l'image de profil</Text>
                          <Input id="image_url_input" name="image_url" value={newCitizenData.image_url} onChange={handleNewCitizenInputChange} />
                        </VStack>
                      </HStack>
                    </VStack>

                    <VStack spacing={4} align="stretch" mb={"20px"}>
                      {/* Deuxième ligne */}
                      <HStack spacing={4} align="center">
                        <VStack spacing={4} align="stretch" flex={1}>
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>Prénom<Text as="span" color="coral">*</Text></Text>
                          <Input name="firstname" value={newCitizenData.firstname} onChange={handleNewCitizenInputChange} />
                        </VStack>
                        <VStack spacing={4} align="stretch" flex={1}>
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>Nom<Text as="span" color="coral">*</Text></Text>
                          <Input name="name" value={newCitizenData.name} onChange={handleNewCitizenInputChange} />
                        </VStack>
                        <VStack spacing={4} align="stretch" flex={1}>
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>Sexe<Text as="span" color="coral">*</Text></Text>
                          <Select name="sex_id" value={newCitizenData.sex_id} onChange={handleNewCitizenInputChange}>
                            <option style={{ backgroundColor: '#030617', color: 'white' }} value="1">Homme</option>
                            <option style={{ backgroundColor: '#030617', color: 'white' }} value="2">Femme</option>
                            <option style={{ backgroundColor: '#030617', color: 'white' }} value="3">Autre</option>
                          </Select>
                        </VStack>
                        <VStack spacing={4} align="stretch" flex={1}>
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>Date de naissance</Text>
                          <Box w="100%">
                            <DatePicker
                              name="birthdate"
                              selected={newCitizenData.birthdate ? new Date(newCitizenData.birthdate) : null}
                              width="100%"
                              onChange={(date) =>
                                setNewCitizenData({
                                  ...newCitizenData,
                                  birthdate: date,
                                })
                              }
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              customInput={
                                <Input type='text' style={{ backgroundColor: 'transparent', color: 'white', border: '1px solid #E2E8F0', borderRadius: '5px', padding: '10px', width: '100%' }}
                                  onClick={(e) => e.target.focus()}
                                />
                              }
                            />
                          </Box>
                        </VStack>
                      </HStack>
                    </VStack>

                    <VStack spacing={4} align="stretch" mb={"20px"}>
                      {/* Troisième ligne */}
                      <HStack spacing={4} align="center">
                        <VStack spacing={4} align="stretch" flex={1}>
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>Adresse</Text>
                          <Input name="address" value={newCitizenData.address} onChange={handleNewCitizenInputChange} />
                        </VStack>
                        <VStack spacing={4} align="stretch" flex={1}>
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>Téléphone</Text>
                          <Input name="phone" value={newCitizenData.phone} onChange={handleNewCitizenInputChange} />
                        </VStack>
                        <VStack spacing={4} align="stretch" flex={1}>
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>Profession</Text>
                          <Input name="work" value={newCitizenData.work} onChange={handleNewCitizenInputChange} />
                        </VStack>
                      </HStack>
                    </VStack>

                    <VStack spacing={4} align="stretch" mb={"20px"}>
                      {/* Quatrième ligne */}
                      <HStack spacing={4} align="center">
                        <VStack spacing={4} align="stretch" flex={1}>
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>Nationalité</Text>
                          <Input name="nationality" value={newCitizenData.nationality} onChange={handleNewCitizenInputChange} />
                        </VStack>
                        <VStack spacing={4} align="stretch" flex={1}>
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>Couleur de cheveux</Text>
                          <Input name="hair" value={newCitizenData.hair} onChange={handleNewCitizenInputChange} />
                        </VStack>
                        <VStack spacing={4} align="stretch" flex={1}>
                          <Text fontSize='sm' color='gray.400' fontWeight='bold'>Ethnicité</Text>
                          <Input name="ethnicity" value={newCitizenData.ethnicity} onChange={handleNewCitizenInputChange} />
                        </VStack>
                      </HStack>
                    </VStack>
                  </Card>
                </ScaleFade>
              </Box>
            )}
          </Card>

          {/* 2. Participants */}
          <Card mb={"10px"}>
            <Heading fontSize={"22px"} borderBottom={"1px solid white"} pb={"5px"} mb={"10px"}>2. Participants</Heading>
            <Text fontSize='sm' color='gray.400' mb={"10px"}>🔸Choisir un ou plusieurs participants, actuellement {participants.length} participant(s) sélectionné(s)</Text>
            <FormControl>
              <Select mt={"10px"} _hover={{ backgroundColor: '#030617', transform: 'scale(1.005)', cursor: 'pointer' }}
                border={"none"} value={selectedParticipant} onChange={handleParticipantChange}>
                <option style={{ backgroundColor: '#030617', color: 'gray' }} value="">Ajouter un participant</option>
                {allUsers && allUsers.map(user => (
                  <option style={{ backgroundColor: '#030617', color: 'white' }} key={user.id} value={user.id}>{user.firstname} {user.name}</option>
                ))}
              </Select>
            </FormControl>
            {participants && participants.length > 0 ? (
              <VStack spacing={4} align="stretch" m={"10px"}>
                {participants.map((participant, index) => (
                  <Card transition="all 0.3s ease-in-out"
                    _hover={{
                      transform: "scale(1.01)",
                      backgroundColor: "#1A1F37",
                    }}>
                    <HStack spacing={4} align="center" key={index}>
                      <Text fontSize='sm' color='gray.400' fontWeight='bold'>{allUsers.find(user => user.id == participant).firstname} {allUsers.find(user => user.id == participant).name}</Text>
                      <Box flex="1" />
                      <Button colorScheme="red" size="sm" onClick={() => setParticipants(participants.filter(p => p != participant))}>Retirer</Button>
                    </HStack>
                  </Card>
                ))}
              </VStack>
            ) : (
              <Text fontSize='sm' color='gray.400' m="10px">Aucun participant</Text>
            )}
          </Card>

          {/* 3. Crimes et délits */}
          <Card mb={"10px"}>
            <Heading fontSize={"22px"} borderBottom={"1px solid white"} pb={"5px"} mb={"10px"}>3. Infractions</Heading>
            <Text fontSize='sm' color='gray.400' mt="10px">🔸Ajouter une ou plusieurs infractions, actuellement {selectedCrimes.length} infraction(s) sélectionné(s). <br />🔸Astuce: Écrivez le nom de l'infraction pour la trouver plus rapidement.</Text>
            <FormControl>
              <Select mt={"10px"} _hover={{ backgroundColor: '#030617', transform: 'scale(1.005)', cursor: 'pointer' }}
                border={"none"} value={selectedCrime} onChange={handleCrimeChange}>
                <option style={{ backgroundColor: '#030617', color: 'gray' }} value="">Ajouter une infraction</option>
                {crimes && crimes.map(crime => (
                  <option style={{ backgroundColor: '#030617', color: 'white' }} key={crime.id} value={crime.id}>{crime.name}</option>
                ))}
              </Select>
            </FormControl>
            {selectedCrimes && selectedCrimes.length > 0 ? (
              <VStack spacing={4} align="stretch" m={"10px"}>
                {selectedCrimes.map((crime, index) => (
                  <Card transition="all 0.3s ease-in-out"
                    _hover={{
                      transform: "scale(1.01)",
                      backgroundColor: "#1A1F37",
                    }}>
                    <HStack spacing={4} align="center" key={index}>
                      <Text fontSize='sm' color='gray.400' fontWeight='bold'>{crimes.find(c => c.id == crime).name}</Text>
                      <Box flex="1" />
                      <Button colorScheme="red" size="sm" onClick={() => setSelectedCrimes(selectedCrimes.filter(c => c != crime))}>Retirer</Button>
                    </HStack>
                  </Card>
                ))}
              </VStack>
            ) : (
              <Text fontSize='sm' color='gray.400' m="10px">Aucune infraction</Text>
            )}
          </Card>

          {/* 4. Rédaction */}
          <Flex justify={"space-between"}>
            <Box width="calc(50% - 5px)" height="100%">
              <Card mb={"10px"}>
                <Heading fontSize={"22px"} borderBottom={"1px solid white"} pb={"5px"} mb={"10px"} >4. Rédaction</Heading>
                <Text fontSize='sm' color='gray.400' mt="10px">🔸 Rédiger le procès-verbal</Text>
                {/* 4.1 Type du PV */}
                <FormControl>
                  <FormLabel fontSize='sm' color='gray.400' fontWeight='bold'>Choisir un type de procès-verbal<Text as="span" color="coral">*</Text></FormLabel>
                  <Select value={selectedArrestType} onChange={(e) => setSelectedArrestType(e.target.value)}>
                    <option style={{ backgroundColor: '#030617', color: 'gray' }} value="">Sélectionner un type</option>
                    {arrest_types && arrest_types.map(type => (
                      <option style={{ backgroundColor: '#030617', color: 'white' }} key={type.id} value={type.id}>{type.name}</option>
                    ))}
                  </Select>
                </FormControl>
                {/* 4.2 Objet(s) saisi(s) */}
                <FormControl mt={"20px"}>
                  <FormLabel fontSize='sm' color='gray.400' fontWeight='bold'>Objet</FormLabel>
                  <Input value={seizedObjects} onChange={(e) => setSeizedObjects(e.target.value)} />
                </FormControl>
                {/* 4.3 Rapport */}
                <FormControl mt={"20px"}>
                  <FormLabel fontSize='sm' color='gray.400' fontWeight='bold'>Rapport</FormLabel>
                  <Textarea maxLength={7000} placeholder="Rédiger le rapport ici" height={"600px"} value={description} onChange={handleDescriptionChange} />
                  <Text fontSize="sm" color={descriptionSize > 7000 ? "red" : "gray.400"}>
                    {descriptionSize} / 7000
                  </Text>
                </FormControl>
              </Card>
            </Box>

            {/* 5. Preview du PV */}
            <Box width="calc(50% - 5px)" height="100%">
              <Card mb={"10px"}>
                <Heading fontSize={"22px"} borderBottom={"1px solid white"} pb={"5px"} mb={"20px"}>5. Preview du procès-verbal</Heading>
                <PV
                  buttonBack={false}
                  width={"100%"}
                  manDatas={
                    {
                      redactor: currentUser ? currentUser : null,
                      type: selectedArrestType ? arrest_types.find(type => type.id == selectedArrestType).name : null,
                      author: selectedCitizen && selectedCitizen != 'create' ? {
                        firstname: selectedCitizen ? citizens.find(citizen => citizen.id == selectedCitizen).firstname : null,
                        name: selectedCitizen ? citizens.find(citizen => citizen.id == selectedCitizen).name : null,
                        birthdate: selectedCitizen ? citizens.find(citizen => citizen.id == selectedCitizen).birthdate : null,
                        phone: selectedCitizen ? citizens.find(citizen => citizen.id == selectedCitizen).phone : null,
                      } : selectedCitizen == 'create' ? newCitizenData : null,
                      seized_objects: seizedObjects ? seizedObjects : null,
                      description: description ? description : null,
                      crimes: selectedCrimes ? selectedCrimes.map(crime => crimes.find(c => c.id == crime).name) : null,
                      participants: participants ? participants.map(participant => allUsers.find(user => user.id == participant)) : null,
                    }
                  } />
              </Card>
            </Box>
          </Flex>

          {/* 6. Enregistrement */}
          <Button
            colorScheme="green"
            fontSize={"20px"}
            mt={"10px"}
            width={"100%"}
            height={"80px"}
            onClick={() => handleSave()}
            alignSelf="flex-end">
            Enregistrer le procès-verbal
          </Button>
        </Flex>
      </ScaleFade >
    </Flex >
  );
}

export default NewArrest;
