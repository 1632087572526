import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  VStack,
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Input
} from "@chakra-ui/react";
import { MdDescription } from "react-icons/md";

function TableArrestsToValidate(props) {
  const { arrest, lastItem, onValidate, onReject } = props;
  const [isRejecting, setIsRejecting] = useState(false);
  const [reason, setReason] = useState('');
  const history = useHistory();
  const toast = useToast();
  const onClose = () => setIsRejecting(false);
  const cancelRef = React.useRef();

  const handleValidation = () => {
    onValidate(arrest.id);
  };

  const handleRejection = () => {
    setIsRejecting(true);
  };

  const confirmRejection = () => {
    onReject(arrest.id, reason);
    setReason('');
    onClose();
  };

  const handleReadReport = (arrestId) => {
    history.push(`/admin/pv/${arrestId}`);
  };

  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        ps='0px'
        borderBottomColor='#56577A'
        border={lastItem ? "none" : null}>
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <VStack>
            <Text fontSize='sm' color={"white"} fontWeight='normal' minWidth='100%'>
              {arrest.redactor_firstname} {arrest.redactor_name}
            </Text>
          </VStack>
        </Flex>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        N°4500-{arrest.id}
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        {arrest.date_creation ? moment(arrest.date_creation).format("D MMM YYYY à HH:mm") : "-"}
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Flex justify='center' align='center'>
          <Button colorScheme="green" size="sm" onClick={handleValidation} mr={2}>
            Valider
          </Button>
          <Button colorScheme="red" size="sm" onClick={handleRejection} mr={2}>
            Rejeter
          </Button>
          <Button colorScheme="blue" onClick={() => handleReadReport(arrest.id)} leftIcon={<MdDescription color="white" size={24} />}>Lire le procès-verbal</Button>
        </Flex>
      </Td>

      {/* Pop-up pour le refus */}
      <AlertDialog
        isOpen={isRejecting}
        leastDestructiveRef={cancelRef}
        onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Refus
            </AlertDialogHeader>
            <AlertDialogBody>
              Veuillez indiquer la raison du refus (Facultatif)
              <Input mt={4} value={reason} onChange={(e) => setReason(e.target.value)} placeholder="Raison du refus" />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={confirmRejection} ml={3}>
                Confirmer le refus
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Tr>
  );
}

export default TableArrestsToValidate;
