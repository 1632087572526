import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Loading from "misc/Loading";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import config from "config";
import { useFetchData } from "apiService";
import logo from "assets/img/logo.png";
import { Center, VStack, Text, Box, Image, H1 } from "@chakra-ui/react";
import MobileDetect from 'mobile-detect';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { data: myCustomerName } = useFetchData("myCustomerName");
  const { data: myCustomerLogo } = useFetchData("myCustomerLogo");
  const { data: myRole } = useFetchData("myRole");
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (myCustomerName) {
      const tenantName = myCustomerName[0];
      config.setTenantName(tenantName ? tenantName?.name : "");
    }
  }, [myCustomerName]);

  useEffect(() => {
    if (myCustomerLogo) {
      const logo = myCustomerLogo[0];
      config.setLogo(logo ? logo?.logo_url : "");
    }
  }, [myCustomerLogo]);

  useEffect(() => {
    if (myRole) {
      config.setRole(myRole || "");
    }
  }, [myRole]);

  useEffect(() => {

    const checkToken = async () => {
      try {
        const token = Cookies.get("token");
        if (token === undefined) {
          setIsAuthenticated(false);
          setIsLoading(false);
          return;
        }
        // TODO: REPLACE USING APISERVICE
        const response = await axios.get(`${config.apiUrl}/checkToken`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    // Check token
    checkToken();

    // Check if it is a mobile device
    checkDevice();

  }, []);

  const checkDevice = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    setIsMobile(!!md.mobile());
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isMobile) {
    return (
      <Center height="100vh">
      <VStack spacing={8}>
      <h1 fontSize="4xl" textAlign="center"> 
          Oops...
        </h1>
        <Text fontSize="2xl" textAlign="center">
          XGuard n'est malheureusement pas supporté sur téléphone pour le moment.<br /><br />
          Réessaie une prochaine fois !
        </Text>
        <br />
        <Box boxSize="25%">
          <Image src={logo} alt="logo" width="100%" />
        </Box>
      </VStack>
    </Center>
    );
  }  

  return (
    <HashRouter>
      <Switch>
        <Route path="/auth" component={AuthLayout} />

        {isAuthenticated ? (
          <>
            {/* Autres routes protégées */}
            <Route path="/admin" component={AdminLayout} />

            {/* Redirection conditionnelle à la racine */}
            <Route exact path="/" render={() => <Redirect to="/admin/dashboard" />} />
          </>
        ) : (
          <>
            {/* Redirection pour les utilisateurs non authentifiés */}
            <Route exact path="/" render={() => <Redirect to="/auth/signin" />} />
            <Redirect from="*" to="/auth/signin" />
          </>
        )}
      </Switch>
    </HashRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
