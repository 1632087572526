import React from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import utils from "utils";

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    const { datas, services = false } = this.props;

    // Transformer les données pour ApexCharts
    if (!services) {
      const categories = datas.map(item => moment(item.Date).format('D MMMM YYYY'));
      const seriesData = datas.map(item => item.Total_Arrests);

      // Configuration des options du graphique
      const lineChartOptions = {
        chart: {
          type: 'area',
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: categories,
          type: 'datetime',
          labels: {
            show: false, // Cacher les labels sur l'axe X
            format: 'DD/MM'
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return parseInt(value); // Convertir les valeurs en entiers
            },
            style: {
              colors: '#fff' // Couleur des étiquettes de l'axe Y en blanc
            }
          }
        },
        dataLabels: {
          enabled: false
        }
      };

      // Configuration des données du graphique
      const lineChartData = [{
        name: "Nombre de PVs",
        data: seriesData
      }];

      // Mettre à jour l'état avec les nouvelles données et options
      this.setState({
        chartData: lineChartData,
        chartOptions: lineChartOptions,
      });
    } else {
      // POUR LES SERVICES
      const categories = datas.map(item => item.day);
      const seriesData = datas.map(item => item.seconds);

      // Configuration des options du graphique
      const lineChartOptions = {
        chart: {
          type: 'area',
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: categories,
          type: 'string',
          labels: {
            show: false,
          }
        },
        yaxis: {
          labels: {
            show: false,
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
        tooltip: {
          enabled: true,
          theme: 'dark', // Utiliser un thème sombre pour les tooltips, ou configurer les couleurs spécifiques
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif'
          },
          x: {
            show: true
          },
          y: {
            formatter: function (val) {
              return utils.convertDuration(val);
            }
          }
        }
      };

      // Configuration des données du graphique
      const lineChartData = [{
        name: "Temps en service",
        data: seriesData
      }];

      // Mettre à jour l'état avec les nouvelles données et options
      this.setState({
        chartData: lineChartData,
        chartOptions: lineChartOptions,
      });
    }
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type='area'
        width='100%'
        height='100%'
      />
    );
  }
}

export default LineChart;
