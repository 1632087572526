import React, { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Select,
  Switch,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  HStack,
  Spacer
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { DeleteIcon } from "@chakra-ui/icons";
import moment from 'moment';
import config from "config";
import { usePostData } from "apiService";

// Wanted style
import { css, keyframes } from '@emotion/react'

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const wantedStyle = css`
  color: coral;
  animation: ${blink} 1s linear infinite;
`;

function TableCitizens(props) {
  const { id, name, firstname, birthdate, address, phone, work, nationality, hair, ethnicity, sex_id, date_creation, image_url, nbr_arrest, lastItem, wantedDB } = props;
  const colorStatus = useColorModeValue("white", "gray.400");
  const toast = useToast();
  const [isDeleted, setIsDeleted] = useState(false);
  const { postData } = usePostData();

  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const openConfirmModal = () => setIsConfirmOpen(true);
  const closeConfirmModal = () => setIsConfirmOpen(false);

  const [isWantedModalOpen, setIsWantedModalOpen] = useState(false);
  const openWantedModal = () => setIsWantedModalOpen(true);
  const closeWantedModal = () => setIsWantedModalOpen(false);

  const [doWantedModalOpen, setDoWantedModalOpen] = useState(false);
  const openDoWantedModal = () => setDoWantedModalOpen(true);
  const closeDoWantedModal = () => setDoWantedModalOpen(false);
  const [wantedReason, setWantedReason] = useState('');
  const [wanted, setWanted] = useState(null);

  useEffect(() => {
    setWanted(wantedDB);
  }, [wantedDB]);

  const [formData, setFormData] = useState({
    firstname: firstname,
    name: name,
    birthdate: birthdate,
    address: address,
    phone: phone,
    work: work,
    nationality: nationality,
    hair: hair,
    ethnicity: ethnicity,
    sex_id: sex_id,
    date_creation: date_creation,
    nbr_arrest: nbr_arrest
  });

  // Fonction pour activer la recherche d'un citoyen
  const activateWanted = async (reason) => {
    if (!reason) return toast({
      title: 'Erreur',
      description: "Veuillez saisir une raison pour activer la recherche.",
      status: 'error',
      duration: 9000,
      isClosable: true,
      position: 'top-right'
    });
    const response = await postData(`/activeWantedCitizen/${id}`, { reason: reason });
    handleResponse(response, 'La recherche a été activée.');
    setWanted(reason);
  };

  // Fonction pour désactiver la recherche d'un citoyen
  const deactivateWanted = async () => {
    const response = await postData(`/deactiveWantedCitizen/${id}`);
    handleResponse(response, 'La recherche a été désactivée.');
    setWanted(null);
  };

  // Gestionnaire de réponse commune
  const handleResponse = (response, successMessage) => {
    if (response) {
      toast({
        title: 'Opération réussie',
        description: successMessage,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      });
      setDoWantedModalOpen(false);
      setIsWantedModalOpen(false);
      // Actualiser l'état 'wanted' ici si nécessaire
    }
  };

  const handleDelete = async () => {
    // Appeler l'API pour supprimer le citoyen ici
    const response = await postData(`deleteCitizen`, { id: id });

    if (response) {
      toast({
        title: 'Citoyen supprimé.',
        description: "Le citoyen a été supprimé avec succès.",
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      });
      setIsConfirmOpen(false);
      setIsDeleted(true);

    } else {
      toast({
        title: 'Oh mince...',
        description: "Une erreur s'est produite lors de la suppression du citoyen.",
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      });
    }
  };

  useEffect(() => {
    setFormData({
      firstname: firstname,
      name: name,
      birthdate: birthdate,
      address: address,
      phone: phone,
      work: work,
      nationality: nationality,
      hair: hair,
      ethnicity: ethnicity,
      sex_id: sex_id,
      date_creation: date_creation,
      nbr_arrest: nbr_arrest
    });
  }, [firstname, name, birthdate, address, phone, work, nationality, hair, ethnicity, sex_id, date_creation, nbr_arrest]);

  return (
    <>
      <Tr>
        <Td
          minWidth={{ sm: "250px" }}
          ps='0px'
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'
          css={wanted ? wantedStyle : undefined}>
          <HStack spacing='1rem'>
            <Avatar name={formData.firstname} src={image_url} borderRadius={8} opacity={isDeleted ? 0.5 : 1} />
            <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
              <Flex direction='column'>
                <Text
                  fontSize='sm'
                  color={wanted ? 'red' : 'white'}
                  fontWeight={wanted ? 'bold' : 'normal'}
                  opacity={isDeleted ? 0.5 : 1}
                  minWidth='100%'>
                  {formData.firstname} {formData.name}
                </Text>
                <Text fontSize='sm' fontWeight='normal' color={isDeleted || wanted ? 'coral' : 'gray.400'}>
                  {isDeleted ? "Supprimé" : formData.work}
                </Text>
              </Flex>
            </Flex>
          </HStack>
        </Td>
        <Td
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'
          minW='150px'>
          <Flex direction='column'>
            <Text fontSize='sm' color='#fff' fontWeight='normal' opacity={isDeleted ? 0.5 : 1} css={wanted ? wantedStyle : undefined}>
              {formData.phone}
            </Text>
            <Text fontSize='sm' color='gray.400' fontWeight='normal'>

            </Text>
          </Flex>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
          <Text fontSize='sm' color='#fff' fontWeight='normal' opacity={isDeleted ? 0.5 : 1} css={wanted ? wantedStyle : undefined}>
            {moment(formData.birthdate).format('DD/MM/yyyy')}
          </Text>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
          <Text fontSize='sm' color={formData.nbr_arrest > 0 ? 'white' : 'gray'} fontWeight='normal' opacity={isDeleted ? 0.5 : 1} css={wanted ? wantedStyle : undefined}>
            {formData.nbr_arrest}
          </Text>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
          <Flex justifyContent="space-between" alignItems="center" spacing="4">
            <Spacer />

            {/* WANTED TEXT */}
            {wanted && (
              <Badge
                onClick={openWantedModal}
                colorScheme='red'
                variant='solid'
                fontSize='0.8rem'
                fontWeight='bold'
                borderRadius='5px'
                css={wantedStyle}
                color='white!important'
                p='0.5rem'
                letterSpacing='0.1rem'
                cursor="pointer"
                mr='1rem'
              >
                ⚠️ RECHERCHÉ ⚠️
              </Badge>
            )}

            {/* CONSULT BUTTON */}
            <Button
              as={Link}
              to={!isDeleted ? `/admin/citoyens/${id}` : '#'}
              mr='1rem'
              colorScheme='purple'
              disabled={isDeleted}
              fontSize='sm'
              color='white'
              fontWeight='bold'
              cursor='pointer'
            >
              Consulter
            </Button>

            {/* Activate wanted */}
            {(config.role("responsable_worker") || config.role("tribunal", true)) && (
              <Button colorScheme='orange' onClick={!isDeleted ? (openDoWantedModal) : null} isDisabled={isDeleted} mr='1rem'>
                {wanted ? 'Désactiver la recherche' : 'Activer la recherche'}
              </Button>
            )}

            {/* DELETE BUTTON */}
            {(config.role("staff") && !wanted) && (
              <Button colorScheme='red' onClick={!isDeleted ? openConfirmModal : null} isDisabled={isDeleted} mr='1rem'>
                <DeleteIcon />
              </Button>
            )}
          </Flex>
        </Td>
      </Tr>

      {/* Modal for wanted citizen */}
      <Modal isOpen={isWantedModalOpen} onClose={closeWantedModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{formData.firstname} {formData.name} est recherché</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <b>Raison: </b>{wanted}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={closeWantedModal}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for activating wanted citizen */}
      <Modal isOpen={doWantedModalOpen} onClose={closeDoWantedModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{wanted ? 'Désactiver' : 'Activer'} la recherche</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {wanted ? (
              <Text>Êtes-vous sûr de vouloir désactiver la recherche de <b>{formData.firstname} {formData.name}</b> ?</Text>
            ) : (
              <Input placeholder="Raison de la recherche" value={wantedReason} onChange={e => setWantedReason(e.target.value)} />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={wanted ? deactivateWanted : () => activateWanted(wantedReason)}>
              {wanted ? 'Désactiver la recherche' : 'Activer la recherche'}
            </Button>
            <Button variant='ghost' onClick={closeDoWantedModal}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for deleting citizen */}
      <Modal isOpen={isConfirmOpen} onClose={closeConfirmModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmer la suppression</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Êtes-vous sûr de vouloir supprimer <b>{formData.firstname} {formData.name}</b> du registre des citoyens ? ATTENTION : Cette action est irréversible.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={handleDelete}>
              Supprimer
            </Button>
            <Button variant='ghost' onClick={closeConfirmModal}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TableCitizens;