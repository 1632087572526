import React, { useEffect, useState } from "react";
import {
  Flex,
  VStack,
  Text,
  useToast,
  Table,
  Tr,
  Thead,
  Th,
  Tbody,
  Td,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import config from "config";
import { usePostData, useFetchData } from "apiService";
import TableCustomers from "components/Tables/TableCustomers";
import Cookies from "js-cookie";
import { Separator } from "components/Separator/Separator";
import { AiFillPlusCircle } from "react-icons/ai";
import { nanoid } from "nanoid";
import moment from "moment";

function SuperAdminMenu() {
  const { data: allCustomers } = useFetchData('customers');
  const { data: allActiveTokens } = useFetchData('activeTokens');
  const [activeUsers, setActiveUsers] = useState([]);
  const { data: allBugs } = useFetchData('bugs');
  const [bugs, setBugs] = useState(allBugs);
  const { data: currentUsers } = useFetchData('users/0');
  const { data: customersStats } = useFetchData('customersStats');
  const { data: globalStats } = useFetchData('dbStats');
  const [dbSize, setDbSize] = useState(0);
  const [selectedCustomerID, setSelectedCustomerID] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  const [newCustomerData, setNewCustomerData] = useState({
    name: "",
    email: "",
    accessRequestToken: "",
  });
  const toast = useToast();
  const { postData } = usePostData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (allActiveTokens && allActiveTokens.length > 0) {
      const activeUsers = allActiveTokens.filter((token) => moment(token.last_activity).isAfter(moment().subtract(2, 'hour')));

      // Remove duplicate users
      const uniqueUsers = activeUsers.reduce((acc, current) => {
        const x = acc.find(item => item.user_username === current.user_username);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      setActiveUsers(uniqueUsers.sort((a, b) => moment(b.last_activity).diff(moment(a.last_activity))));
    }
  }, [allActiveTokens]);

  useEffect(() => {
    if (allBugs) {
      setBugs(allBugs);
    }
  }, [allBugs]);

  useEffect(() => {
    if (globalStats && globalStats.length > 0) {
      setDbSize(globalStats.find((stat) => stat.database == 'total').size_mb);
    }
  }, [globalStats]);

  useEffect(() => {
    if (allCustomers) {
      setCustomers(allCustomers);
    }
  }, [allCustomers]);

  useEffect(() => {
    if (currentUsers && currentUsers.length > 0) {
      setSelectedCustomerID(currentUsers[0].customer_id);
    }
  }, [currentUsers]);

  const deleteBug = async (bug_id) => {
    const response = await postData('deleteBug', { id: bug_id });
    if (response) {
      setBugs(bugs.filter((bug) => bug.id !== bug_id));
      toast({
        title: "Bug supprimé",
        description: "Le bug a été supprimé avec succès.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la suppression du bug.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }
  }


  const onSelectCustomer = async (customer_id) => {
    const response = await postData('setSelectedCustomer', { customer_id });

    if (response) {
      setSelectedCustomerID(customer_id);
      Cookies.remove('token');
      toast({
        title: "Client sélectionné",
        description: "Le client a été sélectionné avec succès. Déconnexion et reconnexion pour appliquer les changements.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la sélection du client.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleCreateCustomer = async () => {


    const response = await postData('customers', newCustomerData);
    setIsCreatingCustomer(false); // Close modal after creating the customer

    // Clear the form fields
    setNewCustomerData({
      name: "",
      email: "",
      accessRequestToken: "",
    });

    if (response) {
      toast({
        title: "Nouveau client créé",
        description: "Le nouveau client a été créé avec succès. REFRESH LA PAGE !!!",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la création du client.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
        <VStack align="left">
          <Card>
            <Text fontSize='xl' fontWeight='bold' color={"red"}>
              Configuration de {config.appName} v{config.version} - SUPER ADMIN MENU
            </Text>
            <CardBody mt={5}>
              <VStack align="left" width={"100%"}>
                <Text fontSize='md' color={"white"}><b>Nombre total de clients actifs:</b> {customers.filter((customer) => customer.is_disabled == 0).length}</Text>
              </VStack>
            </CardBody>
          </Card>
          <Separator />

          {/* Stockage */}
          <Card>
            <CardHeader color={"orange"}>
              <Text fontSize='xl' fontWeight='bold'>Stockage de la base de données</Text>
            </CardHeader>
            <CardBody>
              <VStack align="left" width={"100%"}>
                <Text fontSize='md' color={"white"}><b>Stockage utilisé:</b> {dbSize} MB</Text>
                <div style={{
                  maxHeight: '250px',
                  overflowY: 'scroll',
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#582CFF transparent'
                }}>
                  <Table color={"white"}>
                    <Thead>
                      <Tr>
                        <Th color={"white"}>Database</Th>
                        <Th color={"white"}>Table</Th>
                        <Th color={"white"}>Size (MB)</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {globalStats && globalStats.map((stat) => {
                        if (!stat.table) return;
                        return (
                          <Tr>
                            <Td>{stat.database}</Td>
                            <Td>{stat.table}</Td>
                            <Td>{stat.size_mb}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </div>
              </VStack>
            </CardBody>
          </Card>

          {/* BUG REPORTS */}
          <Card>
            <CardHeader color={"orange"}>
              <Text fontSize='xl' fontWeight='bold'>Rapports de bugs</Text>
            </CardHeader>
            <CardBody>
              <VStack align="left" width={"100%"}>
                <Text fontSize='md' color={"white"}>Il y a <Text as="span" color={"orange"}>{bugs.length}</Text> signalement(s)</Text>
                {bugs && bugs.length > 0 ? (
                  <div style={{
                    maxHeight: '250px',
                    overflowY: 'scroll',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#582CFF transparent'
                  }}>
                    <Table color={"white"}>
                      <Thead>
                        <Tr>
                          <Th color={"white"}>User id</Th>
                          <Th color={"white"}>Titre</Th>
                          <Th color={"white"}>Description</Th>
                          <Th color={"white"} width={"200px"}>Date</Th>
                          <Th color={"white"}>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {bugs.map((bug) => {
                          return (
                            <Tr>
                              <Td>{bug.user_id}</Td>
                              <Td>{bug.title}</Td>
                              <Td>{bug.description}</Td>
                              <Td>{moment(bug.date_creation).format('DD/MM/YYYY à HH:mm')}</Td>
                              <Td>
                                <HStack spacing={4} align="stretch" flex="1" justify="center">
                                  <Button
                                    colorScheme="blue"
                                    size="sm"
                                    onClick={() => window.open(bug.screenshot_url, "_blank")}
                                    visibility={bug.screenshot_url ? "visible" : "hidden"}
                                    mr="10px">
                                    Voir screenshot
                                  </Button>
                                  <Button colorScheme="red" size="sm" onClick={() => deleteBug(bug.id)}>Supprimer</Button>
                                </HStack>
                              </Td>

                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>
                ) : (
                  <Text fontSize='md' color={"white"}>Aucun rapport de bug n'a été soumis.</Text>
                )}
              </VStack>
            </CardBody>
          </Card>

          {/* ACTIVE TOKENS */}
          <Card>
            <CardHeader color={"orange"}>
              <Text fontSize='xl' fontWeight='bold'>Tokens actifs et dernières activitées</Text>
            </CardHeader>
            <CardBody>
              <VStack align="left" width={"100%"}>
                <VStack align="left" width={"100%"}>
                  <Text fontSize='md' color={"white"}>Il y a <Text as="span" color={"orange"}>{activeUsers.length}</Text> utilisateurs actifs sur les 2 dernières heures</Text>
                  {activeUsers && activeUsers.length > 0 ? (
                    <div style={{
                      maxHeight: '250px',
                      overflowY: 'scroll',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#582CFF transparent'
                    }}>
                      <Table color={"white"}>
                        <Thead>
                          <Tr>
                            <Th color={"white"}>Nom</Th>
                            <Th color={"white"}>Prénom</Th>
                            <Th color={"white"}>Identifiant</Th>
                            <Th color={"white"}>Nom client</Th>
                            <Th color={"white"}>Date d'expiration</Th>
                            <Th color={"white"}>Dernière activitée</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {activeUsers.map((activeUser) => {
                            return (
                              <Tr>
                                <Td>{activeUser.user_name}</Td>
                                <Td>{activeUser.user_firstname}</Td>
                                <Td>{activeUser.user_username}</Td>
                                <Td>{activeUser.customer_name}</Td>
                                <Td>{moment(activeUser.expires_at).format('D MMMM YYYY à HH:mm')}</Td>
                                <Td>{moment(activeUser.last_activity).format('D MMMM YYYY à HH:mm')}</Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </div>
                  ) : (
                    <Text fontSize='md' color={"white"}>Aucun utilisateur n'est actif actuellement.</Text>
                  )}
                </VStack>
                <VStack align="left" width={"100%"}>
                  <Text fontSize='md' color={"white"}>Il y a <Text as="span" color={"orange"}>{allActiveTokens.length}</Text> token(s) actif(s)</Text>
                  {allActiveTokens && allActiveTokens.length > 0 ? (
                    <div style={{
                      maxHeight: '250px',
                      overflowY: 'scroll',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#582CFF transparent'
                    }}>
                      <Table color={"white"}>
                        <Thead>
                          <Tr>
                            <Th color={"white"}>Nom</Th>
                            <Th color={"white"}>Prénom</Th>
                            <Th color={"white"}>Identifiant</Th>
                            <Th color={"white"}>Nom client</Th>
                            <Th color={"white"}>Date d'expiration</Th>
                            <Th color={"white"}>Dernière activitée</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {allActiveTokens.map((token) => {
                            return (
                              <Tr>
                                <Td>{token.user_name}</Td>
                                <Td>{token.user_firstname}</Td>
                                <Td>{token.user_username}</Td>
                                <Td>{token.customer_name}</Td>
                                <Td>{moment(token.expires_at).format('D MMMM YYYY à HH:mm')}</Td>
                                <Td>{moment(token.last_activity).format('D MMMM YYYY à HH:mm')}</Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </div>
                  ) : (
                    <Text fontSize='md' color={"white"}>Aucun token n'est actif actuellement.</Text>
                  )}
                </VStack>
              </VStack>
            </CardBody>
          </Card>


          <Separator />
          <Card>
            <CardHeader color={"orange"}>
              <Text fontSize='xl' fontWeight='bold'>Liste des clients</Text>
            </CardHeader>
            <CardBody>
              <VStack align="left" width={"100%"}>
                <HStack spacing={4} align="stretch" flex="1" justify="center">
                  <Button
                    colorScheme="blue"
                    onClick={() => setIsCreatingCustomer(true)}
                    fontSize={"lg"}
                    leftIcon={AiFillPlusCircle}
                    rightIcon={AiFillPlusCircle}
                    mb={4}
                  >
                    {/* TODO: RAJOUTER UNE SUPPRESSION DE CLIENT*/}
                    Ajouter un nouveau client
                  </Button>
                </HStack>
                {customers.map((row, index, arr) => {
                  return (
                    <TableCustomers
                      key={row.id} // Add key prop for each element in the list
                      id={row.id}
                      customer={row}
                      stats={customersStats.find((stats) => stats.customer_id === row.id)}
                      selectedCustomerID={selectedCustomerID}
                      onSelectCustomer={onSelectCustomer}
                    />
                  );
                })}
              </VStack>
            </CardBody>
          </Card>
        </VStack>
      </Flex>

      {/* Modal for creating a new customer */}
      <Modal isOpen={isCreatingCustomer} onClose={() => setIsCreatingCustomer(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Créer un nouveau client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input placeholder="Nom du client" value={newCustomerData.name} onChange={(e) => setNewCustomerData({ ...newCustomerData, name: e.target.value })} />
              <Input placeholder="Email du client" value={newCustomerData.email} onChange={(e) => setNewCustomerData({ ...newCustomerData, email: e.target.value })} />
              <Input placeholder="Access Request Token" value={newCustomerData.accessRequestToken} readOnly />
              <Button onClick={() => setNewCustomerData({ ...newCustomerData, accessRequestToken: nanoid() })}>Générer Token</Button>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCreateCustomer}>
              Créer
            </Button>
            <Button onClick={() => setIsCreatingCustomer(false)}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default SuperAdminMenu;
