// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";
import Billing from "views/Dashboard/Billing.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import Admin from "views/Dashboard/Admin.js";
import Membres from "views/Dashboard/Membres.js";
import Citoyens from "views/Dashboard/Citoyens.js";
import CitoyenDetail from "views/Dashboard/CitoyenDetail.js";
import Arrest from "views/Dashboard/Arrest";
import NewArrest from "views/Dashboard/NewArrest";
import Logs from "views/Dashboard/Logs";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import { FaUser, FaUsers, FaFileAlt, FaRegListAlt, FaFileInvoiceDollar, FaTools, FaSignInAlt, FaUserPlus, FaCheckCircle, FaUserCog, FaFileInvoice, FaFileImport } from 'react-icons/fa';
import { AiOutlineHdd } from 'react-icons/ai';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import validation from "views/Dashboard/validation";
import Configuration from "views/Dashboard/configuration";
import SuperAdminMenu from "views/Dashboard/SuperAdminMenu";
import config from "config";

var dashRoutes = [
  // Pages invisible
  {
    path: "/signin",
    name: "Sign In",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: SignIn,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/signup",
    name: "Sign Up",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color='inherit' />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/profile/:id",
    name: "Profil",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color='inherit' />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/pv/:idArrest",
    name: "Arrestation",
    icon: <PersonIcon color='inherit' />,
    component: Arrest,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/citoyens/:idCitoyen",
    name: "Détail du Citoyen",
    icon: <PersonIcon color='inherit' />,
    component: CitoyenDetail,
    layout: "/admin",
    invisible: true,
  },

  // Pages visibles
  {
    path: "/dashboard",
    name: "Tableau de bord",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/admin",
    color: "purple",
  },
  {
    path: "/new-arrest",
    name: "Nouvel acte",
    icon: <FaFileImport color='inherit' />,
    component: NewArrest,
    layout: "/admin", // TODO : Changer les layouts ? Pourquoi "admin" mdr
    role: "tribunal",
    color: "green",
  },
  {
    path: "/validation",
    name: "Validations PV",
    icon: <FaCheckCircle color='inherit' />,
    component: validation,
    layout: "/admin",
    invisible: false,
    role: "responsable_worker",
  },
  {
    path: "/citoyens",
    name: "Registre des citoyens",
    icon: <AiOutlineHdd color='inherit' />,
    component: Citoyens,
    layout: "/admin",
  },
  {
    path: "/membres",
    name: "Membres",
    rtlName: "لوحة القيادة",
    icon: <FaUsers color='inherit' />,
    component: Membres,
    layout: "/admin",
    role: "worker",
  },
  {
    path: "/logs",
    name: "Logs des services",
    rtlName: "لوحة القيادة",
    icon: <FaRegListAlt color='inherit' />,
    secondaryNavbar: true,
    component: Logs,
    layout: "/admin",
    role: "responsable_worker",
  },
  {
    path: "/profile/0",
    name: "Mon profil",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color='inherit' />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/administration",
    name: "Administration",
    rtlName: "لوحة القيادة",
    icon: <MdOutlineAdminPanelSettings color='inherit' />,
    secondaryNavbar: true,
    component: Admin,
    layout: "/admin",
    role: "staff",
    color: "red",
  },
  {
    path: "/configuration",
    name: "Configuration",
    rtlName: "لوحة القيادة",
    icon: <SupportIcon color='inherit' />,
    secondaryNavbar: true,
    component: Configuration,
    layout: "/admin",
    role: "admin",
    color: "red",
  },
  {
    path: "/super-administration",
    name: "Super Administration",
    rtlName: "لوحة القيادة",
    icon: <SupportIcon color='inherit' />,
    secondaryNavbar: true,
    component: SuperAdminMenu,
    layout: "/admin",
    role: "superadmin",
    color: "red",
  },
  /*
  {
    path: "/tables",
    name: "Tables",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/billing",
    name: "Billing",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Billing,
    layout: "/admin",
  },
  */
];
export default dashRoutes;
