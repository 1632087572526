import React from "react";
import moment from "moment";
import {
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  Tooltip,
  useColorModeValue,
  Box,
  Button,
  VStack
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { FaExclamationCircle, FaEdit } from 'react-icons/fa';

function TableMyArrests(props) {
  const { arrest, lastItem } = props;
  const history = useHistory();

  const handleEdit = () => {
    history.push(`/admin/pv/${arrest.id}`);
  };

  const renderStatus = () => {
    switch (arrest.is_validated) {
      case 2: // Cas où c'est rejeté
        return {
          action: (
            <Button
              leftIcon={<FaEdit />}
              colorScheme='orange'
              variant='solid'
              size='sm'
              onClick={handleEdit}
            >
              Corriger
            </Button>
          )
        };
      case 0: // Cas où c'est en attente de validation
        return {
          icon: FaExclamationCircle,
          text: "En cours de validation",
          color: 'orange.300'
        };
      default:
        return null;
    }
  };

  const status = renderStatus();

  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        ps='0px'
        borderBottomColor='#56577A'
        border={lastItem ? "none" : null}>
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <VStack>
            <Text fontSize='sm' color={"white"} fontWeight='normal' minWidth='100%'>
              {arrest.citizen_firstname} {arrest.citizen_name} {arrest.is_validated === 2 ? <Text as='span' color='red.500' ml='5px'>(Rejetée, à corriger)</Text> : null}
            </Text>
          </VStack>
        </Flex>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        N°4500-{arrest.id}
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        {arrest.date_creation ? moment(arrest.date_creation).format("D MMM YYYY à HH:mm") : "-"}
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Tooltip label={status?.text} placement="top">
          <Flex align="center">
            {status?.icon && <Icon as={status.icon} color={status.color} me="4px" />}
            {status?.action}
          </Flex>
        </Tooltip>
      </Td>
    </Tr>
  );
}

export default TableMyArrests;
