// Chakra Imports
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Textarea,
  ModalFooter,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";
import Cookies from "js-cookie";
import { FaPlay, FaStop } from 'react-icons/fa';
import config from "config";

// Api service
import { useFetchData, usePostData } from "apiService";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const { data: currentUsers } = useFetchData('users/0');
  const [currentUser, setCurrentUser] = useState(null);
  const [onDuty, setOnDuty] = useState(false);
  const toast = useToast();
  const { postData } = usePostData();

  const [isOpenBug, setIsOpenBug] = useState(false);
  const [bugTitle, setBugTitle] = useState('');
  const [bugDescription, setBugDescription] = useState('');
  const [screenshotUrl, setScreenshotUrl] = useState('');
  const onClose = () => setIsOpenBug(false);

  // Chakra Color Mode
  let mainText = "gray.400";
  let navbarIcon = "white";

  useEffect(() => {
    setCurrentUser(currentUsers[0]);
    if (currentUsers[0]) {
      setOnDuty(currentUsers[0].on_duty == 1 ? true : false);
    }
  }, [currentUsers]);

  const submitBugReport = async () => {
    // Ici, vous pouvez gérer l'envoi du rapport de bug
    console.log({ bugTitle, bugDescription, screenshotUrl });
    if (bugTitle === '' || bugDescription === '') {
      toast({
        title: "Veuillez remplir tous les champs",
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    const response = await postData('reportBug', { title: bugTitle, description: bugDescription, screenshot: screenshotUrl });
    if (response) {
      toast({
        title: "Votre rapport a été envoyé avec succès. Merci <3",
        status: "success",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    }
    setBugTitle('');
    setBugDescription('');
    setScreenshotUrl('');
    onClose();
  };

  const toggleService = async () => {
    setOnDuty(!onDuty);
    if (!onDuty) {
      // Prendre son service
      const response = await postData('takeDuty');
      if (response) {
        toast({
          title: "Vous êtes désormais en service.",
          status: "success",
          duration: 6000,
          isClosable: true,
          position: "top-right",
        });
      }
    } else {

      // Arrêter son service
      const response = await postData('leaveDuty');

      if (response) {
        toast({
          title: "Vous avez arrêté votre service.",
          status: "warning",
          duration: 6000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }

  const handleLogout = () => {
    Cookies.remove('token');
  };

  const settingsRef = React.useRef();
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'>

      {/* Report bug */}
      <Button me={{ sm: "2px", md: "16px" }} backgroundColor={"transparent"} variant='solid' colorScheme="orange" onClick={() => setIsOpenBug(true)}>
        Signaler
      </Button>

      {/* Prise de service*/}
      {config.role("worker") && (
        <Button
          me={{ sm: "2px", md: "16px" }}
          color='white'
          _hover={{ color: "black", bg: "whitesmoke" }}
          bg={onDuty ? "#080C2A" : "#080C2A"}
          border={onDuty ? "1px solid #FF0000" : "1px solid #00FF00"}
          variant='solid'
          onClick={toggleService}
          leftIcon={onDuty ? <FaStop color="red" /> : <FaPlay color="green" />}
        >
          {onDuty ? "Arrêter mon service" : "Prendre mon service"}
        </Button>
      )}
      <NavLink to='/auth/signin'>
        <Button
          ms='0px'
          px='0px'
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant='transparent-with-icon'>
          <Text display={{ sm: "none", md: "flex" }} onClick={handleLogout}>Se déconnecter</Text>
        </Button>
      </NavLink>
      <SidebarResponsive
        iconColor='gray.500'
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
      <SettingsIcon
        cursor='pointer'
        ms={{ base: "16px", xl: "0px" }}
        me='16px'
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w='18px'
        h='18px'
      />
      <Modal isOpen={isOpenBug} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Signalement</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <Text mb={4}>
                {config.appName} est une application en constante évolution.
                <br /><br />
                Si vous rencontrez un problème ou si vous avez une suggestion d'amélioration, veuillez nous en informer en remplissant le formulaire ci-dessous.
                <br /><br />
                Nous vous serions très reconnaissants ! ❤️
              </Text>
              <FormLabel>Titre</FormLabel>
              <Input
                placeholder="De quoi s'agit-il ?"
                value={bugTitle}
                onChange={(e) => setBugTitle(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Décrivez votre demande ici..."
                value={bugDescription}
                onChange={(e) => setBugDescription(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>URL de la capture d'écran (optionnel)</FormLabel>
              <Input
                placeholder="URL"
                value={screenshotUrl}
                onChange={(e) => setScreenshotUrl(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={submitBugReport}>
              Envoyer
            </Button>
            <Button onClick={onClose}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/*
      <Menu>
        <MenuButton align='center'>
          <BellIcon color={navbarIcon} mt='-4px' w='18px' h='18px' />
        </MenuButton>

        <MenuList
          border='transparent'
          backdropFilter='blur(63px)'
          bg='linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.69) 76.65%)'
          borderRadius='20px'>
          <Flex flexDirection='column'>
            <MenuItem
              borderRadius='8px'
              _hover={{
                bg: "transparent",
              }}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb='10px'>
              <ItemContent
                time='13 minutes ago'
                info='from Alicia'
                boldInfo='New Message'
                aName='Alicia'
                aSrc={avatar1}
              />
            </MenuItem>
            <MenuItem
              borderRadius='8px'
              _hover={{
                bg: "transparent",
              }}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              _hover={{ bg: "transparent" }}
              mb='10px'>
              <ItemContent
                time='2 days ago'
                info='by Josh Henry'
                boldInfo='New Album'
                aName='Josh Henry'
                aSrc={avatar2}
              />
            </MenuItem>
            <MenuItem
              borderRadius='8px'
              _hover={{
                bg: "transparent",
              }}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}>
              <ItemContent
                time='3 days ago'
                info='Payment succesfully completed!'
                boldInfo=''
                aName='Kara'
                aSrc={avatar3}
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
            */}
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
