import React from "react";

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Icon,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Loading from "../../misc/Loading";
import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import config from "config";

// Table Components
import TableUsers from "components/Tables/TableUsers";

// Data
import { tablesProjectData } from "variables/general";

// Icons
import { AiFillCheckCircle, AiOutlineAccountBook, AiOutlinePlus, AiOutlineProfile } from "react-icons/ai";

import { useFetchData, usePostData } from "apiService";
import { PersonIcon } from "components/Icons/Icons";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

function Membres() {

  const { data: allUsersAPI } = useFetchData('allUsers');
  const [allUsers, setAllUsers] = useState(allUsersAPI)
  const [users, setUsers] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const { data: allGrades } = useFetchData('grades');
  const { data: allRoles } = useFetchData('allRoles');
  const [isAddingMember, setIsAddingMember] = useState(false);
  const [newMemberData, setNewMemberData] = useState({
    name: "",
    firstname: "",
    username: "",
    password: "",
    phone: "",
    discord: "",
    nigend: "",
    grade: "",
    role: "",
    avatar_url: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const { postData } = usePostData();

  // Pagination
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(users.length / itemsPerPage);
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  // Research
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    // Filtrage des citoyens en fonction de la recherche (Be carefull with the showArchived)
    const filtered = showArchived ? allUsers.filter(
      (user) =>
        (user.firstname.toLowerCase() + " " + user.name.toLowerCase()).includes(searchQuery.toLowerCase())
    ) : allUsers.filter(
      (user) =>
        (user.firstname.toLowerCase() + " " + user.name.toLowerCase()).includes(searchQuery.toLowerCase())
    ).filter(user => user.is_activated === 1);

    setUsers(filtered);
    setCurrentPage(1);
  }, [searchQuery, allUsers]);

  // Effects
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (allUsersAPI) {
      setAllUsers(allUsersAPI);
    }
  }, [allUsersAPI]);

  useEffect(() => {
    if (allUsers && allGrades && allRoles) {
      if (showArchived) {
        setUsers(allUsers.filter(user => user.is_validated === 1));
      } else {
        setUsers(allUsers.filter(user => user.is_activated === 1).filter(user => user.is_validated === 1));
      }
      setIsLoading(false);
    }
  }, [allUsers, allGrades, allRoles, showArchived]);

  const handleNewMemberSubmit = async () => {

    // Check if all required fields are filled
    if (!newMemberData.name || !newMemberData.firstname || !newMemberData.username || !newMemberData.password || !newMemberData.selectedGrade || !newMemberData.selectedRole) {
      toast({
        title: "Erreur",
        description: "Veuillez remplir tous les champs obligatoires (*)",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    // Check if the grade is valid
    if (!allGrades.find(grade => grade.name === newMemberData.selectedGrade)) {
      toast({
        title: "Erreur",
        description: "Le grade sélectionné n'est pas valide",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    // Check if the role is valid
    if (!allRoles.find(role => role.name === newMemberData.selectedRole)) {
      toast({
        title: "Erreur",
        description: "Le rôle sélectionné n'est pas valide",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    // Send the new member data to the server
    const response = await postData('addUser', {
      name: newMemberData.name,
      firstname: newMemberData.firstname,
      username: newMemberData.username,
      password: newMemberData.password,
      phone: newMemberData.phone,
      discord: newMemberData.discord,
      nigend: newMemberData.nigend,
      grade: allGrades.find(grade => grade.name === newMemberData.selectedGrade).id,
      role: allRoles.find(role => role.name === newMemberData.selectedRole).key,
      avatar_url: newMemberData.avatar_url,
    });

    if (response) {
      toast({
        title: "Membre ajouté",
        description: newMemberData.name + " " + newMemberData.firstname + " a été ajouté",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      // Update the users list
      let newUser = response[0][0] || {};
      setUsers([...users, newUser]);
      setAllUsers([...allUsers, newUser]);

      // Reset the new member data
      setNewMemberData({
        name: "",
        firstname: "",
        username: "",
        password: "",
        phone: "",
        discord: "",
        nigend: "",
        grade: "",
        role: "",
        avatar_url: "",
      });

      // Close the modal
      setIsAddingMember(false);
    } else {
      toast({
        title: "Erreur",
        description: "Impossible d'ajouter le membre",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }

  };

  const handleInputChange = (e) => {
    setNewMemberData({
      ...newMemberData,
      [e.target.name]: e.target.value,
    });
  };

  if (isLoading) return <Loading />;

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      {/* Authors Table */}
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <Flex direction='row' justify='space-between' w={"100%"}>
          <Flex direction='column' align='flex-start' w='50%'>
            <Flex alignItems='center'>
              <Text fontSize='lg' color='#fff' fontWeight='bold' mb='.5rem'>
                Membres
              </Text>
            </Flex>
            <Flex align='center'>
              <Icon
                as={PersonIcon}
                color='green.500'
                w='15px'
                h='15px'
                me='5px'
              />
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                <Text fontWeight='bold' as='span' color='gray.400'>
                  {users && users.length}
                </Text>{" "}
                membre(s) enregistré(s)
              </Text>
            </Flex>
          </Flex>
          <Flex justify='flex-end'>
            {config.role("staff") && (
              <>
                <Button
                  colorScheme="blue"
                  onClick={() => setShowArchived(!showArchived)}
                  leftIcon={showArchived ? <MdVisibility /> : <MdVisibilityOff />}
                >
                  Utilisateurs désactivés
                </Button>
                <Button
                  ml={2}
                  colorScheme='green'
                  onClick={() => setIsAddingMember(true)}
                >
                  <Icon as={AiOutlinePlus} w='20px' h='20px' mr={2} />
                  Ajouter un nouveau membre
                </Button>
              </>
            )}
          </Flex>
        </Flex>
        <Input
          placeholder="Rechercher par nom ou prénom..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          variant="filled"
          color="black"
          bg="lightGray"
          _hover={{ bg: "lightGray" }}
          _focus={{ bg: "white" }}
          mt="1rem"
          mb="1rem"
          _placeholder={{ color: "black" }}
          pr="3rem"
        />
        {users && users.length > 0 ? (
          <CardBody>
            <Table variant='simple' color='#fff'>
              <Thead>
                <Tr my='.8rem' ps='0px' color='gray.400'>
                  <Th
                    ps='0px'
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    Membre
                  </Th>
                  <Th
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    Fonction
                  </Th>
                  <Th
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    En service
                  </Th>
                  <Th
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    Nigend
                  </Th>
                  <Th
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    N° de téléphone
                  </Th>
                  <Th
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    Discord
                  </Th>
                  <Th
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    Enregistré depuis le
                  </Th>
                  <Th borderBottomColor='#56577A'></Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.slice(startIndex, endIndex).map((row, index, arr) => {
                  return (
                    <TableUsers
                      id={row.id}
                      name={row.name}
                      firstname={row.firstname}
                      username={row.username}
                      nigend={row.nigend}
                      phone={row.phone}
                      discord={row.discord}
                      avatar_url={row.avatar_url}
                      on_duty={row.on_duty}
                      is_activated={row.is_activated}
                      date_creation={row.date_creation}
                      grade={row.grade_name}
                      role={row.role_name}
                      sector={row.sector_name}
                      allRoles={allRoles}
                      allGrades={allGrades}
                      lastItem={index === arr.length - 1 ? true : false}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
        ) : (
          <Text fontSize='sm' color='gray.400' fontWeight='normal' mb='25px' mt='25px'>
            Vous n'avez pas encore de membres enregistrés
          </Text>
        )}
      </Card>
      {/* Pagination */}
      {totalPages > 0 && (
        <Flex justify="flex-start" mt="20px" mb="20px">
          <Button
            variant="ghost"
            color="white"
            _hover={{ bg: "brand.100" }}
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            <BiChevronLeft fontSize={32} />
          </Button>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Button
              key={index}
              variant={currentPage === index + 1 ? "solid" : "ghost"}
              bg={currentPage === index + 1 ? "brand.200" : "transparent"}
              _hover={{ bg: "brand.100" }}
              color="white"
              onClick={() => goToPage(index + 1)}
              mx="2px"
            >
              {index + 1}
            </Button>
          ))}
          <Button
            variant="ghost"
            color="white"
            _hover={{ bg: "brand.100" }}
            onClick={nextPage}
            disabled={endIndex >= users.length}
          >
            <BiChevronRight fontSize={32} />
          </Button>
        </Flex>
      )}

      {/* New Member Form */}
      <Modal isOpen={isAddingMember} onClose={() => setIsAddingMember(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un nouveau membre</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            {/* Nom */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Nom <Text as='span' color='red.500'>*</Text>
            </Text>
            <Input name="name" value={newMemberData.name} onChange={handleInputChange} mb={"10px"} />

            {/* Prénom */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Prénom <Text as='span' color='red.500'>*</Text>
            </Text>
            <Input name="firstname" value={newMemberData.firstname} onChange={handleInputChange} mb={"10px"} />

            {/* Nom d'utilisateur */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Nom d'utilisateur <Text as='span' color='red.500'>*</Text>
            </Text>
            <Input name="username" value={newMemberData.username} onChange={handleInputChange} mb={"10px"} />

            {/* Mot de passe */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Mot de passe <Text as='span' color='red.500'>*</Text>
            </Text>
            <Input name="password" value={newMemberData.password} onChange={handleInputChange} mb={"10px"} />

            {/* Numéro de téléphone */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Numéro de téléphone
            </Text>
            <Input name="phone" value={newMemberData.phone} onChange={handleInputChange} mb={"10px"} />

            {/* Nigend */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Nigend
            </Text>
            <Input name="nigend" value={newMemberData.nigend} onChange={handleInputChange} mb={"10px"} />

            {/* Discord */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Discord
            </Text>
            <Input name="discord" value={newMemberData.discord} onChange={handleInputChange} mb={"10px"} />

            {/* Grade */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Grade <Text as='span' color='red.500'>*</Text>
            </Text>
            <Select
              name="selectedGrade"
              onChange={handleInputChange}
              value={newMemberData.selectedGrade}
              placeholder="Assigner un grade"
              size='sm'
              mb="10px"
            >
              {allGrades && allGrades.map(grade => (
                <option key={grade.id} value={grade.name}>{grade.name}</option>
              ))}
            </Select>

            {/* Rôle */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Rôle <Text as='span' color='red.500'>*</Text>
            </Text>
            <Select
              name="selectedRole"
              onChange={handleInputChange}
              value={newMemberData.selectedRole}
              placeholder="Assigner un rôle"
              size='sm'
              mb="10px"
            >
              {config.myRole == "staff" ? (
                <>
                  {allRoles && allRoles.filter(role => role.key !== 'admin' && role.key !== 'staff').map(role => (
                    <option key={role.id} value={role.name}>{role.name}</option>
                  ))}
                </>
              ) : (
                <>
                  {allRoles && allRoles.map(role => (
                    <option key={role.id} value={role.name}>{role.name}</option>
                  ))}
                </>
              )}
            </Select>

            {/* Avatar */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Avatar
            </Text>
            <Input name="avatar_url" value={newMemberData.avatar_url} onChange={handleInputChange} mb={"10px"} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleNewMemberSubmit}>
              Ajouter
            </Button>
            <Button onClick={() => setIsAddingMember(false)}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Membres;
