/*

      =========================================================
      * XGuard
      =========================================================
      
      * Product Page: https://www.nelx.net/xguard
      * Copyright 2024 NELX (https://www.nelx.net/)
      
      =========================================================
*/

import { Box, useStyleConfig } from "@chakra-ui/react";
function CardHeader(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("CardHeader", { variant });
  // Pass the computed styles into the `__css` prop
  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
}

export default CardHeader;
