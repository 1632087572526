// Chakra imports
import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	DarkMode,
	Flex,
	Grid,
	Icon,
	Image,
	Link,
	Switch,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import avatar11 from 'assets/img/avatars/avatar11.png';
// Images
import avatar2 from 'assets/img/avatars/avatar2.png';
import avatar3 from 'assets/img/avatars/avatar3.png';
import avatar4 from 'assets/img/avatars/avatar4.png';
import avatar6 from 'assets/img/avatars/avatar6.png';
import bgProfile from 'assets/img/bgProfile.png';
import ProjectImage1 from 'assets/img/ProjectImage1.png';
import ProjectImage2 from 'assets/img/ProjectImage2.png';
import ProjectImage3 from 'assets/img/ProjectImage3.png';
// Custom components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import LineChart from 'components/Charts/LineChart';
import IconBox from 'components/Icons/IconBox';
import { CarIcon, FulgerIcon, FulgerWhiteIcon } from 'components/Icons/Icons';
import { Separator } from 'components/Separator/Separator';
import React, { useState, useEffect } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { FaCube, FaFacebook, FaInstagram, FaPencilAlt, FaPenFancy, FaTwitter } from 'react-icons/fa';
// Icons
import { IoDocumentsSharp } from 'react-icons/io5';
import { FaClock, FaFileAlt, FaEdit } from 'react-icons/fa';

import { useParams } from 'react-router-dom';
import { useFetchData } from 'apiService';
import moment from 'moment';
import config from 'config';
import utils from 'utils';


function Profile() {

	// Get profile from id
	const { id } = useParams();
	const { data: users } = useFetchData(`userProfile/${id ? id : 0}`);
	const [user, setUser] = useState(null);

	useEffect(() => {
		if (users && users.length > 0) {
			setUser(users[0]);
		}
	}, [users]);

	return (
		<Flex direction='column' mt={{ sm: '25px', md: '0px' }}>
			<Box
				mb={{ sm: '24px', md: '50px', xl: '20px' }}
				borderRadius='15px'
				px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'>
				{/* Header */}
				<Card
					direction={{ sm: 'column', md: 'row' }}
					mx='auto'
					maxH='330px'
					w={{ sm: '90%', xl: '100%' }}
					justifyContent={{ sm: 'center', md: 'space-between' }}
					align='center'
					p='24px'
					borderRadius='20px'
					mt='100px'>
					<Flex align='center' direction={{ sm: 'column', md: 'row' }}>
						<Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}>
							<Avatar size="md" src={user ? user.avatar_url : null} w='80px' h='80px' borderRadius='15px' mr='20px'>
								{user && user.on_duty === 1 ? (
									<Tooltip label="En service" hasArrow>
										<AvatarBadge
											cursor='pointer'
											borderRadius='4px'
											border='transparent'
											bg='linear-gradient(126.97deg, #32CD32 28.26%, #008000 91.2%)'
											boxSize='13px'
											backdropFilter='blur(120px)'
										>
										</AvatarBadge>
									</Tooltip>
								) : (
									<Tooltip label="Hors service" hasArrow>
										<AvatarBadge
											cursor='pointer'
											borderRadius='4px'
											border='transparent'
											bg='linear-gradient(126.97deg, #FF0000 28.26%, #8B0000 91.2%)'
											boxSize='13px'
											backdropFilter='blur(120px)'
										>
										</AvatarBadge>
									</Tooltip>
								)}
							</Avatar>
							<Flex direction='column' maxWidth='100%' my={{ sm: '14px' }}>
								<Text
									fontSize={{ sm: 'lg', lg: 'xl' }}
									color='#fff'
									fontWeight='bold'
									ms={{ sm: '8px', md: '0px' }}>
									{user ? user.firstname + ' ' + user.name : 'l\'agent'}
								</Text>
								<Text fontSize={{ sm: 'sm', md: 'md' }} color='gray.400'>
									{user ? user.grade : 'N/A'}
								</Text>
							</Flex>
						</Flex>
						{/* BOUTONS A DROITE DU PROFIL; PEUT ETRE INTERESSANT
						<Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }}>
							<Button
								borderRadius='12px'
								bg='brand.200'
								_hover={{ opacity: '0.8' }}
								_active={{ opacity: '0.9' }}
								me={{ base: 'none', lg: '20px' }}
								leftIcon={<Icon color='white' as={FaCube} me='6px' />}>
								<Text fontSize='xs' color='#fff' fontWeight='bold'>
									OVERVIEW
								</Text>
							</Button>
							<Button
								borderRadius='12px'
								bg='transparent'
								_hover={{
									bg: 'brand.200'
								}}
								_active={{
									bg: 'brand.200'
								}}
								me={{ base: 'none', lg: '20px' }}
								leftIcon={<Icon color='white' as={IoDocumentsSharp} me='6px' />}>
								<Text fontSize='xs' color='#fff' fontWeight='bold'>
									TEAMS
								</Text>
							</Button>
							<Button
								borderRadius='12px'
								bg='transparent'
								_hover={{
									bg: 'brand.200'
								}}
								_active={{
									bg: 'brand.200'
								}}
								leftIcon={<Icon color='white' as={FaPenFancy} me='6px' />}>
								<Text fontSize='xs' color='#fff' fontWeight='bold'>
									PROJECTS
								</Text>
							</Button>
						</Flex>
					*/}
					</Flex>
				</Card>
			</Box>
			<Grid
				templateColumns={{
					sm: '1fr',
					xl: 'repeat(2, 1fr)',
					'2xl': '1fr 1fr'
				}}
				gap='22px'
				mb='24px'>
				{/* Welcome Card */}
				<Card
					bgImage={bgProfile}
					bgSize='cover'
					maxW={{ sm: '325px', md: '725px', lg: '980px' }}
					h={{ sm: '270px', lg: '350px', xl: '410px' }}
					gridArea={{ xl: '1 / 1 / 2 / 2', '2xl': 'auto' }}>
					<Flex direction='column' h='100%'>
						<Text color='#fff' fontSize='30px' fontWeight='bold' mb='3px'>
							{config.tenantName}
						</Text>
						<Text color='#fff' fontSize='sm' mb='auto'>
							Vous êtes sur le profil de {user ? user.firstname + ' ' + user.name : 'l\'agent'}
						</Text>
					</Flex>
				</Card>
				{/* Informations du profil */}
				<Card
					p='16px'
					maxH={{ md: '410px' }}
					maxW={{ sm: '325px', md: '725px', lg: '980px' }}
					gridArea={{ xl: '1 / 2 / 2 / 3', '2xl': 'auto' }}>
					<CardHeader p='12px 5px' mb='12px'>
						<Flex direction='column'>
							<Text fontSize='lg' color='#fff' fontWeight='bold' mb='10px'>
								Informations de {user ? user.firstname + ' ' + user.name : 'l\'agent'}
							</Text>
							<Text fontSize='sm' color={'gray.400'} fontWeight='400'>
								{user ? (
									<>
										{user.firstname} {user.name} est enregistré en tant que <strong>{user.grade}</strong> dans le secteur <strong>"{user.sector}"</strong> depuis le <strong>{moment(user.date_creation).format("D MMMM YYYY")}</strong>, soit <strong>{moment().diff(moment(user.date_creation), 'days')}</strong> jours. Pendant cette période, {user.firstname} a passé <strong>{utils.convertDuration(user.total_seconds_in_service)}</strong> en service, réalisant <strong>{user.total_arrests}</strong> procédures et participant à <strong>{user.participated_arrests}</strong> interventions.
									</>
								) : 'Chargement des informations...'}
							</Text>
						</Flex>
					</CardHeader>
					<CardBody px='5px'>
						<Flex direction='column'>
							<Separator mb='30px' />
							<Flex align='center' mb='18px'>
								<Text fontSize='sm' color={'gray.400'} me='10px'>
									Rôle:{' '}
								</Text>
								<Text fontSize='sm' color='#fff' fontWeight='400'>
									{user ? user.role || '-' : '-'}
								</Text>
							</Flex>
							<Flex align='center' mb='18px'>
								<Text fontSize='sm' color={'gray.400'} me='10px'>
									Grade et secteur:{' '}
								</Text>
								<Text fontSize='sm' color='#fff' fontWeight='400'>
									{user ? user.grade + ' - ' + user.sector : '-'}
								</Text>
							</Flex>
							<Flex align='center' mb='18px'>
								<Text fontSize='sm' color={'gray.400'} me='10px'>
									Téléphone:{' '}
								</Text>
								<Text fontSize='sm' color='#fff' fontWeight='400'>
									{user ? user.phone || '-' : '-'}
								</Text>
							</Flex>
							<Flex align='center' mb='18px'>
								<Text fontSize='sm' color={'gray.400'} me='10px'>
									Discord:{' '}
								</Text>
								<Text fontSize='sm' color='#fff' fontWeight='400'>
									{user ? user.discord || '-' : '-'}
								</Text>
							</Flex>
							<Flex align='center' mb='18px'>
								<Text fontSize='sm' color={'gray.400'} me='10px'>
									RIO / NIGEND:{' '}
								</Text>
								<Text fontSize='sm' color='#fff' fontWeight='400'>
									{user ? user.nigend || '-' : '-'}
								</Text>
							</Flex>
						</Flex>
					</CardBody>
				</Card>
			</Grid>
			<Card
				p='16px'
				maxH={{ lg: '410px' }}
				maxW={{ sm: '325px', md: '725px', lg: '980px', xl: '100%' }}
				gridArea={{ xl: '2 / 1 / 3 / 3', '2xl': 'auto' }}>
				<CardHeader p='12px 5px' mb='12px'>
					<Flex direction='column'>
						<Text fontSize='lg' color='#fff' fontWeight='bold' mb='6px'>
							Informations avancées
						</Text>
					</Flex>
				</CardHeader>
				<CardBody w='100%'>
					<Flex w='100%' direction={{ sm: 'column', md: 'row' }}>
						{/*
							<Flex
								direction='column'
								align='center'
								me={{ md: '16px', lg: '50px' }}
								mb={{ sm: '12px', md: '0px' }}>
								<CircularProgress
									size={200}
									value={68}
									thickness={6}
									color='green.400'
									variant='vision'>
									<CircularProgressLabel>
										<Flex direction='column' justify='center' align='center'>
											<Text color='#fff' fontSize='36px' fontWeight='bold' mb='6px'>
												68%
											</Text>
											<Text color='gray.400' fontSize='sm'>
												Current load
											</Text>
										</Flex>
									</CircularProgressLabel>
								</CircularProgress>
								<Flex direction='column' mt='18px' align='center'>
									<Text color='#fff' fontSize='lg' fontWeight='bold' mb='2px'>
										0h 58 min
									</Text>
									<Text color='gray.500' fontSize='sm'>
										Time to full charge
									</Text>
								</Flex>
							</Flex>
							*/}
						<Grid
							templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}
							gap='24px'
							w='100%'
							alignSelf='flex-start'>
							<Flex
								align='center'
								p='18px'
								justify='space-between'
								bg='linear-gradient(126.97deg, rgba(6, 11, 38, 0.74) 28.26%, rgba(26, 31, 55, 0.5) 91.2%)'
								borderRadius='20px'>
								<Flex direction='column' me='auto'>
									<Text fontSize='xs' color='gray.400' mb='3px'>
										Acte de procédure
									</Text>
									<Text color='#fff' fontSize='22px' fontWeight='bold'>
										{user ? user.total_arrests : 'N/A'}
									</Text>
								</Flex>
								<IconBox bg='brand.200' w='56px' h='56px' direction='column'>
									<FaFileAlt w='28px' h='28px' color='white' />
								</IconBox>
							</Flex>
							<Flex
								align='center'
								p='18px'
								pe='0px'
								justify='space-between'
								bg='linear-gradient(126.97deg, rgba(6, 11, 38, 0.74) 28.26%, rgba(26, 31, 55, 0.5) 91.2%)'
								borderRadius='20px'>
								<Flex direction='column' me='auto'>
									<Text fontSize='xs' color='gray.400' mb='3px'>
										Participations à une procédure
									</Text>
									<Text color='#fff' fontSize='22px' fontWeight='bold'>
										{user ? user.participated_arrests : 'N/A'}
									</Text>
								</Flex>
								<Box maxH='75px'>
									{/* LINE CHART */}
								</Box>
							</Flex>
							<Flex
								align='center'
								p='18px'
								justify='space-between'
								bg='linear-gradient(126.97deg, rgba(6, 11, 38, 0.74) 28.26%, rgba(26, 31, 55, 0.5) 91.2%)'
								borderRadius='20px'>
								<Flex direction='column' me='auto'>
									<Text fontSize='xs' color='gray.400' mb='3px'>
										Temps passé en service
									</Text>
									<Text color='#fff' fontSize='22px' fontWeight='bold'>
										{user ? utils.convertDuration(user.total_seconds_in_service) : 'N/A'}
									</Text>
								</Flex>
								<IconBox bg='brand.200' w='56px' h='56px'>
									<FaClock w='24px' h='24px' color='#fff' />
								</IconBox>
							</Flex>
							<Flex
								align='center'
								p='18px'
								pe='0px'
								justify='space-between'
								bg='linear-gradient(126.97deg, rgba(6, 11, 38, 0.74) 28.26%, rgba(26, 31, 55, 0.5) 91.2%)'
								borderRadius='20px'>
								<Flex direction='column' me='auto'>
									<Text fontSize='xs' color='gray.400' mb='3px'>
										Services effectués
									</Text>
									<Text color='#fff' fontSize='22px' fontWeight='bold'>
										{user ? user.number_of_services : 'N/A'}
									</Text>
								</Flex>
								<Box maxH='75px'>
									{/* LINE CHART */}
								</Box>
							</Flex>
						</Grid>
					</Flex>
				</CardBody>
			</Card>
			{/* POUR PLUS TARD, ça PEUT ÊTRE INTERESSANT
			<Grid templateColumns={{ sm: '1fr', xl: '1fr 3fr' }} gap='20px'>
				<DarkMode>
					<Card p='16px' gridArea={{ xl: '1 / 1 / 2 / 2' }}>
						<CardHeader p='12px 5px' mb='12px'>
							<Text fontSize='lg' color='#fff' fontWeight='bold'>
								Platform Settings
							</Text>
						</CardHeader>
						<CardBody px='5px'>
							<Flex direction='column'>
								<Text fontSize='10px' color={'gray.400'} mb='20px'>
									ACCOUNT
								</Text>
								<Flex align='center' mb='20px'>
									<Switch variant='brand' colorScheme='brand' me='10px' defaultChecked />
									<Text noOfLines={1} fontSize='sm' color={'gray.400'} fontWeight='400'>
										Email me when someone follows me
									</Text>
								</Flex>
								<Flex align='center' mb='20px'>
									<Switch colorScheme='brand' me='10px' />
									<Text noOfLines={1} fontSize='sm' color={'gray.400'} fontWeight='400'>
										Email me when someone answers on my post
									</Text>
								</Flex>
								<Flex align='center' mb='20px'>
									<Switch colorScheme='brand' me='10px' defaultChecked />
									<Text noOfLines={1} fontSize='sm' color={'gray.400'} fontWeight='400'>
										Email me when someone mentions me
									</Text>
								</Flex>
								<Text fontSize='10px' color={'gray.400'} m='6px 0px 20px 0px'>
									APPLICATION
								</Text>
								<Flex align='center' mb='20px'>
									<Switch colorScheme='brand' me='10px' />
									<Text noOfLines={1} fontSize='sm' color={'gray.400'} fontWeight='400'>
										New launches and projects
									</Text>
								</Flex>
								<Flex align='center' mb='20px'>
									<Switch colorScheme='brand' me='10px' defaultChecked />
									<Text noOfLines={1} fontSize='sm' color={'gray.400'} fontWeight='400'>
										Monthly product changes
									</Text>
								</Flex>
								<Flex align='center' mb='20px'>
									<Switch colorScheme='brand' me='10px' />
									<Text noOfLines={1} fontSize='sm' color={'gray.400'} fontWeight='400'>
										Subscribe to newsletter
									</Text>
								</Flex>
								<Flex align='center' mb='20px'>
									<Switch colorScheme='brand' me='10px' defaultChecked />
									<Text noOfLines={1} fontSize='sm' color={'gray.400'} fontWeight='400'>
										Receive mails weekly
									</Text>
								</Flex>
							</Flex>
						</CardBody>
					</Card>
				</DarkMode>

				<Card gridArea={{ xl: '1 /2 /2/ 5' }} p='16px'>
					<CardHeader p='12px 5px' mb='12px'>
						<Flex direction='column'>
							<Text fontSize='lg' color='#fff' fontWeight='bold'>
								Projects
							</Text>
							<Text fontSize='sm' color={'gray.400'} fontWeight='400'>
								Architects design houses
							</Text>
						</Flex>
					</CardHeader>
					<CardBody px='5px'>
						<Grid
							templateColumns={{
								sm: '1fr',
								md: '1fr 1fr',
								xl: 'repeat(3, 1fr)'
							}}
							templateRows={{
								sm: '1fr 1fr 1fr auto',
								md: '1fr 1fr',
								xl: '1fr'
							}}
							gap='24px'>
							<Flex direction='column'>
								<Box mb='20px' position='relative' borderRadius='20px'>
									<Image src={ProjectImage1} borderRadius='20px' />
								</Box>
								<Flex direction='column'>
									<Text fontSize='10px' color={'gray.400'} mb='10px'>
										Project #1
									</Text>
									<Text fontSize='xl' color='#fff' fontWeight='bold' mb='10px'>
										Modern
									</Text>
									<Text fontSize='sm' color={'gray.400'} fontWeight='400' mb='20px'>
										As Uber works through a huge amount of internal management turmoil.
									</Text>
									<Flex justifyContent='space-between' mt='auto'>
										<Button
											variant='outlineWhite'
											minW='110px'
											h='36px'
											fontSize='10px'
											px='1.5rem'>
											VIEW ALL
										</Button>
										<AvatarGroup size='xs'>
											<Avatar borderColor='#121241' name='Ryan Florence' src={avatar6} />
											<Avatar borderColor='#121241' name='Segun Adebayo' src={avatar2} />
											<Avatar borderColor='#121241' name='Kent Dodds' src={avatar3} />
											<Avatar borderColor='#121241' name='Prosper Otemuyiwa' src={avatar4} />
										</AvatarGroup>
									</Flex>
								</Flex>
							</Flex>
							<Flex direction='column'>
								<Box mb='20px' position='relative' borderRadius='20px'>
									<Image src={ProjectImage2} borderRadius='20px' />
								</Box>
								<Flex direction='column'>
									<Text fontSize='10px' color={'gray.400'} mb='10px'>
										Project #2
									</Text>
									<Text fontSize='xl' color='#fff' fontWeight='bold' mb='10px'>
										Scandinavian
									</Text>
									<Text fontSize='sm' color={'gray.400'} mb='20px'>
										Music is something that every person has his or her own specific opinion about.
									</Text>
									<Flex justifyContent='space-between' mt='auto'>
										<Button
											variant='outlineWhite'
											minW='110px'
											h='36px'
											fontSize='10px'
											px='1.5rem'>
											VIEW ALL
										</Button>
										<AvatarGroup size='xs'>
											<Avatar borderColor='#121241' name='Ryan Florence' src={avatar6} />
											<Avatar borderColor='#121241' name='Segun Adebayo' src={avatar2} />
											<Avatar borderColor='#121241' name='Kent Dodds' src={avatar3} />
											<Avatar borderColor='#121241' name='Prosper Otemuyiwa' src={avatar4} />
										</AvatarGroup>
									</Flex>
								</Flex>
							</Flex>
							<Flex direction='column' h='100%'>
								<Box mb='20px' position='relative' borderRadius='20px'>
									<Image src={ProjectImage3} borderRadius='20px' />
								</Box>
								<Flex direction='column'>
									<Text fontSize='10px' color={'gray.400'} mb='10px'>
										Project #3
									</Text>
									<Text fontSize='xl' color='#fff' fontWeight='bold' mb='10px'>
										Minimalist
									</Text>
									<Text fontSize='sm' color={'gray.400'} fontWeight='400' mb='20px'>
										Different people have different taste, especially various types of music.
									</Text>
									<Flex justifyContent='space-between' mt='auto'>
										<Button
											variant='outlineWhite'
											minW='110px'
											h='36px'
											fontSize='10px'
											px='1.5rem'>
											VIEW ALL
										</Button>
										<AvatarGroup size='xs'>
											<Avatar borderColor='#121241' name='Ryan Florence' src={avatar6} />
											<Avatar borderColor='#121241' name='Segun Adebayo' src={avatar2} />
											<Avatar borderColor='#121241' name='Kent Dodds' src={avatar3} />
											<Avatar borderColor='#121241' name='Prosper Otemuyiwa' src={avatar4} />
										</AvatarGroup>
									</Flex>
								</Flex>
							</Flex>
						</Grid>
					</CardBody>
				</Card>
			</Grid>
						*/}
		</Flex>
	);
}

export default Profile;
