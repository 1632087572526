import { useFetchData } from 'apiService';

/*
const roleMiddleware = (requiredRole) => {
    // Tous les rôles par hiérarchie
    const rolesHierarchy = [
        "tribunal",
        "worker",
        "responsable_worker",
        "staff",
        "admin",
        "superadmin"
    ];

    return (req, res, next) => {
        // Si l'utilisateur est un superadmin, autoriser
        if (req.user.role === "superadmin") {
            return next();
        }

        // Obtenir l'index du rôle requis et du rôle de l'utilisateur dans la hiérarchie
        const requiredRoleIndex = rolesHierarchy.indexOf(requiredRole);
        const userRoleIndex = rolesHierarchy.indexOf(req.user.role);

        // Vérifier si le rôle de l'utilisateur est suffisant
        if (userRoleIndex >= requiredRoleIndex) {
            return next();
        }

        // Si le rôle n'est pas suffisant, refuser l'accès
        return res.status(403).send("Accès interdit - Permissions insuffisantes");
    };
}
*/

const config = {

    // Propriétés de configuration
    appName: "XGuard",
    tenantName: "",
    logo: "",
    apiUrl: "https://nelx.net:3001/api",
    version: "1.4.2",
    displayPopup: true,
    myrole: "",
    roles: ["tribunal", "worker", "responsable_worker", "staff", "admin", "superadmin"],
    my_licence_url: "https://nelx.net/xguard-pricing", // Lien pour éditer son abonnement
    PV: {
        logo_top_left: "https://nelx.net/xguard-imgs/ministere-de-linterieur.png",
    },

    // Méthodes de configuration
    setTenantName: (name) => {
        config.tenantName = name;
    },
    setLogo: (logo) => {
        config.logo = logo;
    },
    setRole: (role) => {
        config.myrole = role;
    },
    role: (role, only = false) => {
        if (config.myrole === "superadmin") {
            return true;
        }

        if (only) {
            return config.myrole === role;
        }

        const requiredRoleIndex = config.roles.indexOf(role);
        const userRoleIndex = config.roles.indexOf(config.myrole);

        return userRoleIndex >= requiredRoleIndex;
    },
};

export default config;






























