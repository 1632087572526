import React, { useEffect, useState } from "react";
import {
  Flex,
  Table,
  Tbody,
  Icon,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  Button,

} from "@chakra-ui/react";
import Loading from "../../misc/Loading";
import TableCitizens from "components/Tables/TableCitizens";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import { useFetchData } from "apiService";
import config from "config";

function Citoyens() {
  const { data: citizens } = useFetchData('citizens');
  const [allCitizens, setAllCitizens] = useState([]);
  const [filteredCitizens, setFilteredCitizens] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // disable rapidement à remettre
  const [searchQuery, setSearchQuery] = useState("");

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(filteredCitizens.length / itemsPerPage);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (citizens.length > 0) {
      // Sort citizens by name
      citizens.sort((a, b) => (a.firstname + a.name > b.firstname + a.name) ? 1 : -1);
      setAllCitizens(citizens);
      setFilteredCitizens(citizens);
      setIsLoading(false);
    }
  }, [citizens]);

  useEffect(() => {
    // Filtrage des citoyens en fonction de la recherche
    const filtered = allCitizens.filter(
      (citizen) =>
        (citizen.firstname.toLowerCase() + " " + citizen.name.toLowerCase()).includes(searchQuery.toLowerCase())
    );
    setFilteredCitizens(filtered);
    setCurrentPage(1);
  }, [searchQuery, allCitizens]);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <Text fontSize='lg' color='#fff' fontWeight='bold' mb='.5rem'>
          Registre des citoyens de <Text as='span' color='brand.50'>{config.tenantName}</Text>
        </Text>
        <Flex align='center'>
          <Text fontSize='sm' color='gray.400' fontWeight='normal'>
            <Text fontWeight='bold' as='span'>
              {allCitizens ? allCitizens.length : 0}
            </Text>{' '}
            citoyens sont enregistrés
          </Text>
        </Flex>
        <CardHeader p='6px 0px 22px 0px'>
          <Input
            placeholder="Rechercher par nom ou prénom..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            variant="filled"
            color="black"
            bg="lightGray"
            _hover={{ bg: "lightGray" }}
            _focus={{ bg: "white" }}
            mt="1rem"
            mb="1rem"
            _placeholder={{ color: "black" }}
            pr="3rem"
          />
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff">
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Nom et prénom
                </Th>
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  N° de téléphone
                </Th>
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Date de naissance
                </Th>
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Procès-verbaux
                </Th>
                <Th ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                />
              </Tr>
            </Thead>
            <Tbody>
              {filteredCitizens.length > 0 ? (
                filteredCitizens
                  .slice() // Crée une copie du tableau pour éviter de modifier l'original
                  .sort((a, b) => {
                    // Trier pour que les entrées avec 'wanted' non vide soient en premier
                    if (a.wanted && !b.wanted) {
                      return -1; // a avant b
                    } else if (!a.wanted && b.wanted) {
                      return 1; // b avant a
                    } else {
                      return 0; // ordre inchangé si les deux ont 'wanted' ou les deux n'ont pas 'wanted'
                    }
                  })
                  .slice(startIndex, endIndex) // Pour paginer les résultats après le tri
                  .map((row, index) => (
                    <TableCitizens
                      key={index}
                      id={row.id}
                      name={row.name}
                      firstname={row.firstname}
                      birthdate={row.birthdate}
                      address={row.address}
                      phone={row.phone}
                      work={row.work}
                      nationality={row.nationality}
                      hair={row.hair}
                      ethnicity={row.ethnicity}
                      sex_id={row.sex_id}
                      date_creation={row.date_creation}
                      image_url={row.image_url}
                      nbr_arrest={row.nbr_arrest}
                      wantedDB={row.wanted}
                      lastItem={row.id === filteredCitizens[filteredCitizens.length - 1].id || index === itemsPerPage - 1}
                    />
                  ))
              ) : (
                <Text color="gray.400" m="10px">Aucun citoyen n'a été trouvé.</Text>
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      {/* Pagination */}
      {totalPages > 0 && (
        <Flex justify="flex-start" mt="20px" mb="20px">
          <Button
            variant="ghost"
            color="white"
            _hover={{ bg: "brand.100" }}
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            <BiChevronLeft fontSize={32} />
          </Button>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Button
              key={index}
              variant={currentPage === index + 1 ? "solid" : "ghost"}
              bg={currentPage === index + 1 ? "brand.200" : "transparent"}
              _hover={{ bg: "brand.100" }}
              color="white"
              onClick={() => goToPage(index + 1)}
              mx="2px"
            >
              {index + 1}
            </Button>
          ))}
          <Button
            variant="ghost"
            color="white"
            _hover={{ bg: "brand.100" }}
            onClick={nextPage}
            disabled={endIndex >= filteredCitizens.length}
          >
            <BiChevronRight fontSize={32} />
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

export default Citoyens;
