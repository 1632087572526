import {
	Avatar,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	Flex,
	Grid,
	Icon,
	Progress,
	SimpleGrid,
	Spacer,
	Stack,
	Stat,
	StatHelpText,
	StatLabel,
	StatNumber,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
// Styles for the circular progressbar
import medusa from 'assets/img/cardimgfree.png';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import BarChart from 'components/Charts/BarChart';
import LineChart from 'components/Charts/LineChart';
import IconBox from 'components/Icons/IconBox';
// Icons
import { CartIcon, DocumentIcon, GlobeIcon, RocketIcon, StatsIcon, WalletIcon } from 'components/Icons/Icons.js';
import DashboardTableRow from 'components/Tables/DashboardTableRow';
import TimelineRow from 'components/Tables/TimelineRow';
import React, { useState, useEffect } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BiHappy } from 'react-icons/bi';
import { BsArrowRight } from 'react-icons/bs';
import { IoCheckmarkDoneCircleSharp, IoEllipsisHorizontal } from 'react-icons/io5';
import { FaCheckCircle, FaExclamationCircle, FaUserShield, FaGavel, FaUserTie, FaBalanceScale, FaBook } from 'react-icons/fa';
import Loading from 'misc/Loading';
import { Link } from 'react-router-dom';
import moment from 'moment';
import config from 'config';
import TableMyArrests from 'components/Tables/TableMyArrests';
// Data
import {
	barChartDataDashboard,
	barChartOptionsDashboard,
	lineChartDataDashboard,
	lineChartOptionsDashboard
} from 'variables/charts';
import { dashboardTableData, timelineData } from 'variables/general';
import Stats_latestArrests from 'components/Tables/stats_latestArrests';

// Api service
import { useFetchData, usePostData } from "apiService";
import TableUsersOnDuty from 'components/Tables/TableUsersOnDuty';
import Cookies from 'js-cookie';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';

import { useToast } from '@chakra-ui/react';

export default function Dashboard() {


	const [isLoading, setIsLoading] = useState(true);
	const { data: statsLines } = useFetchData('stats');
	const { data: currentUsers } = useFetchData('users/0');
	const { data: usersOnDuty } = useFetchData('usersOnDuty');
	const { data: myArrestsInValidation } = useFetchData('myArrestsInValidation');
	const [currentUser, setCurrentUser] = useState(null);
	const [stats, setStats] = useState(null);
	const [usersDuty, setUsersDuty] = useState([]);
	const toast = useToast();

	// Stats
	const { data: lastArrests } = useFetchData('latestArrests');
	const { data: nbrArrestsLastMonthPerDay } = useFetchData('nbrArrestsLastMonth');
	const [totalArrestsLast30days, setTotalArrestsLast30days] = useState(0);

	// Popup for new version
	const [isNewVersion, setIsNewVersion] = useState(false);

	useEffect(() => {
		// Scroll to the top
		window.scrollTo(0, 0);

		// Check if the user had already seen the popup for news
		if (!config.version) return;
		if (config.displayPopup) {
			const currentVersion = config.version;
			const storedVersion = Cookies.get('appVersion');

			if (storedVersion !== currentVersion) {
				setIsNewVersion(true);
			}
		}
	}, []);

	const closeModal = (updateCookie) => {
		setIsNewVersion(false);
		if (updateCookie) {
			Cookies.set('appVersion', config.version, { expires: 365 });
		}
	};

	useEffect(() => {
		if (usersOnDuty && usersOnDuty.length > 0) {
			setUsersDuty(usersOnDuty);
		}
	}, [usersOnDuty]);

	useEffect(() => {
		if (nbrArrestsLastMonthPerDay) {
			let total = 0;
			nbrArrestsLastMonthPerDay.forEach((day) => {
				total += day.Total_Arrests;
			});
			setTotalArrestsLast30days(total);
		}
	}, [nbrArrestsLastMonthPerDay]);

	useEffect(() => {
		setStats(statsLines[0]);
	}, [statsLines]);

	useEffect(() => {
		setCurrentUser(currentUsers[0]);
	}, [currentUsers]);

	useEffect(() => {
		if (stats && currentUser) {
			setIsLoading(false);
		}
	}, [stats, currentUser]);

	if (isLoading) return <Loading />;

	const stats_showOnDuty = async () => {
		toast({
			title: '💥​​ Bientôt disponible !',
			description: 'Cette fonctionnalité n\'est pas encore disponible 😉​',
			status: 'info',
			duration: 5000,
			isClosable: true,
			position: 'top'
		});
	};

	const stats_showArrests = async () => {
		toast({
			title: '💥​​ Bientôt disponible !',
			description: 'Cette fonctionnalité n\'est pas encore disponible 😉​',
			status: 'info',
			duration: 5000,
			isClosable: true,
			position: 'top'
		});
	};

	const stats_showCitizens = async () => {
		toast({
			title: '💥​​ Bientôt disponible !',
			description: 'Cette fonctionnalité n\'est pas encore disponible 😉​',
			status: 'info',
			duration: 5000,
			isClosable: true,
			position: 'top'
		});
	};

	const stats_showUsers = async () => {
		toast({
			title: '💥​​ Bientôt disponible !',
			description: 'Cette fonctionnalité n\'est pas encore disponible 😉​',
			status: 'info',
			duration: 5000,
			isClosable: true,
			position: 'top'
		});
	};

	return (
		<>
			{/* POP-UP FOR NEW VERSION INFORMATIONS */}
			{isNewVersion && (
				<Modal isOpen={isNewVersion} onClose={() => closeModal(false)} width='90%' size='xl'>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>🔥Nouvelle mise à jour {config.version} 🔥</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							Une nouvelle mise à jour a été déployée pour le bonheur de tous 😊<br />
							<br />
							🚀 Nouveautés : <br />
							- Affichage de cette pop-up pour informer des nouvelles versions et de leurs nouveautés.<br />
							- Ajout de logs des services hebdomadaires pour le personnel dans la section <b>Logs des services</b><br />
							- Ajout d'un système de classement par dossier pour les procès-verbaux des citoyens.<br />
							- Mise en place d'un système de casier judiciaire pour les citoyens, accessible aux magistrats.<br />

							<br />
							🔥 Améliorations : <br />
							- Modification de termes spécifiques aux métiers.<br />
							- L'identifiant des procès-verbaux commence désormais par <b>4500</b> au lieu de <b>45000</b><br />
							- Les magistrats ont désormais la capacité de rédiger des procès-verbaux.<br />
							- Vous pouvez désormais bénéficier d'une réduction sur votre abonnement grâce au parrainage, disponible dans la section <b>Configuration</b><br />

							<br />
							🐞 Corrections : <br />
							- Correction de bugs mineurs <br />
							- Améliorations de la stabilité de l'application <br />

							<br />
							🔗 Si vous rencontrez des problèmes ou avez des idées d'améliorations, n'hésitez pas à nous les communiquer en cliquant sur le bouton <b>Signaler</b> en haut de l'écran ! 😉
						</ModalBody>
						<ModalFooter>
							<Button colorScheme="blue" mr={3} onClick={() => closeModal(true)}>
								J'ai compris
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}

			{/* DASHBOARD */}
			<Flex flexDirection='column' pt={{ base: '120px', md: '75px' }}>
				<SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>
					{/* MiniStatistics Card */}
					<Card>
						<CardBody>
							<Flex flexDirection='row' align='center' justify='center' w='100%'>
								<Stat me='auto'>
									<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px' whiteSpace="nowrap">
										Policiers en service
									</StatLabel>
									<Flex>
										<StatNumber fontSize='lg' color='#fff'>
											{stats ? stats.nbr_on_duty : '0'}
										</StatNumber>
									</Flex>
								</Stat>
								<IconBox as='box' h={'45px'} w={'45px'} bg='brand.200' cursor='pointer' onClick={stats_showOnDuty}>
									<FaUserShield h={'24px'} w={'24px'} color='#fff' />
								</IconBox>
							</Flex>
						</CardBody>
					</Card>
					{/* MiniStatistics Card */}
					<Card minH='83px'>
						<CardBody>
							<Flex flexDirection='row' align='center' justify='center' w='100%'>
								<Stat me='auto'>
									<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px' whiteSpace="nowrap">
										Procès-verbaux rédigés
									</StatLabel>
									<Flex>
										<StatNumber fontSize='lg' color='#fff'>
											{stats ? stats.nbr_arrest : '0'}
										</StatNumber>
									</Flex>
								</Stat>
								<IconBox as='box' h={'45px'} w={'45px'} bg='brand.200' cursor='pointer' onClick={stats_showArrests}>
									<FaGavel h={'24px'} w={'24px'} color='#fff' />
								</IconBox>
							</Flex>
						</CardBody>
					</Card>
					{/* MiniStatistics Card */}
					<Card>
						<CardBody>
							<Flex flexDirection='row' align='center' justify='center' w='100%'>
								<Stat>
									<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px' whiteSpace="nowrap">
										Individus recensés
									</StatLabel>
									<Flex>
										<StatNumber fontSize='lg' color='#fff'>
											{stats ? stats.nbr_citizens : '0'}
										</StatNumber>
									</Flex>
								</Stat>
								<Spacer />
								<IconBox as='box' h={'45px'} w={'45px'} bg='brand.200' cursor='pointer' onClick={stats_showCitizens}>
									<FaBook h={'24px'} w={'24px'} color='#fff' />
								</IconBox>
							</Flex>
						</CardBody>
					</Card>
					{/* MiniStatistics Card */}
					<Card>
						<CardBody>
							<Flex flexDirection='row' align='center' justify='center' w='100%'>
								<Stat me='auto'>
									<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px' whiteSpace="nowrap">
										Effectif
									</StatLabel>
									<Flex>
										<StatNumber fontSize='lg' color='#fff' fontWeight='bold'>
											{stats ? stats.nbr_users : '0'}
										</StatNumber>
									</Flex>
								</Stat>
								<IconBox as='box' h={'45px'} w={'45px'} bg='brand.200' cursor='pointer' onClick={stats_showUsers}>
									<FaUserTie h={'24px'} w={'24px'} color='#fff' />
								</IconBox>
							</Flex>
						</CardBody>
					</Card>
				</SimpleGrid>
				<Card
					mt='24px'
					p='0px'
					gridArea={{ md: '1 / 1 / 2 / 3', '2xl': 'auto' }}
					bgImage={null}
					bgSize='cover'
					bgPosition='50%'>
					<CardBody w='100%' h='100%'>
						<Flex flexDirection={{ sm: 'column', lg: 'row' }} w='100%' h='100%'>
							<Flex align='center' justify='center' h='100%' ml={5} mt={5}>
								<Avatar
									src={currentUser.avatar_url}
									w='100px'
									h='100px'
									borderRadius='12px'
									border='none'
								/>
							</Flex>
							<Flex flexDirection='column' h='100%' p='22px' minW='60%' lineHeight='1.6'>
								<Text fontSize='sm' color='gray.400' fontWeight='bold'>
									Bienvenue sur l'intranet de la police de <Text as="span">{config.tenantName}</Text>,
								</Text>
								<Text fontSize='28px' color='#fff' fontWeight='bold'>
									{currentUser ? currentUser.firstname : "[???]"} {currentUser ? currentUser.name : "[???]"} <Text as="span" fontSize="sm" color="gray.400"></Text>
								</Text>
								<Text fontSize='18px' color='gray.400' fontWeight='bold'>
									{currentUser ? currentUser.grade : 'Agent'}
								</Text>
								<Spacer />
							</Flex>
							{/* Section pour le bouton */}
							{config.role("tribunal") && (
								<Flex w='40%' align='stretch' justify='flex-end'>
									<Button
										as={Link}
										to='/admin/new-arrest'
										// BACKGROUND : #1E0F58 -> #4855F9
										bg='linear-gradient(126.97deg, #1E0F58 28.26%, #4855F9 91.2%)'
										color='#fff'
										_active={{ bg: 'transparent' }}
										_focus={{ bg: 'transparent' }}
										_hover={{ transform: 'scale(1.05)', boxShadow: '0 0 15px rgba(255, 255, 255, 0.4)' }}
										h='100%'
										borderRadius='0 12px 12px 0'
										fontSize='lg'
										fontWeight='bold'
										w='100%'
										style={{
											transition: 'all 0.3s ease',
											display: 'flex',
											alignItems: 'center',
											animation: 'textWiggle 0.5s ease'
										}}
										onMouseEnter={e => {
											e.currentTarget.style.transform = 'scale(1)';
											e.currentTarget.style.animation = 'textWiggle 0.5s ease';
										}}
										onMouseLeave={e => {
											e.currentTarget.style.transform = 'scale(1)';
											e.currentTarget.style.animation = 'none';
										}}
									>
										<span style={{
											display: 'flex',
											alignItems: 'center',
											animation: `textWiggle 4s ease-in-out infinite alternate`
										}}>
											Nouvel acte
											<Icon as={BsArrowRight} ml='8px' />
										</span>
									</Button>

									<style>
										{`
										@keyframes textWiggle {
										0%, 100% { transform: translateX(0); }
										50% { transform: translateX(5px); }
										}
									`}
									</style>

								</Flex>
							)}
						</Flex>
					</CardBody>
				</Card>
				<Grid templateColumns={{ sm: '1fr', md: '1fr 1fr', '2xl': '2fr 2fr' }} my='26px' gap='18px'>
					{/* Nombre d'arrestations*/}
					<Card p='28px 0px 0px 0px'>
						<CardHeader mb='20px' ps='22px'>
							<Flex direction='column' alignSelf='flex-start'>
								<Text fontSize='lg' color='#fff' fontWeight='bold' mb='6px'>
									Nombre de procès-verbaux rédigés durant les 30 derniers jours
								</Text>
								{totalArrestsLast30days > 0 ? (
									<Text fontSize='md' fontWeight='medium' color='gray.400'>
										<Text as='span' color='green.400' fontWeight='bold'>
											+{totalArrestsLast30days} nouveaux PV
										</Text>{' '}
										depuis le {moment(nbrArrestsLastMonthPerDay[0].Date).format('LL')} jusqu'à aujourd'hui
									</Text>
								) : (
									<Text fontSize='md' fontWeight='medium' color='gray.400'>
										<Text as='span' color='red.400' fontWeight='bold'>
											Aucun
										</Text>{' '}
										procès-verbal n'a été rédigé durant les 30 derniers jours
									</Text>
								)}
							</Flex>
						</CardHeader>
						{nbrArrestsLastMonthPerDay && nbrArrestsLastMonthPerDay.length > 0 ? (
							<Box w='100%' minH={{ sm: '300px' }}>
								<LineChart
									datas={nbrArrestsLastMonthPerDay}
								/>
							</Box>
						) : (<Loading />)}
					</Card>
					{/* En service */}
					{usersDuty && usersDuty.length > 0 ? (
						<Card p='16px'>
							<CardHeader p='12px 0px 28px 0px'>
								<Flex direction='column'>
									<Text fontSize='lg' color='#fff' fontWeight='bold' pb='8px'>
										Agents actuellement en service
									</Text>
									<Flex align='center'>
										<Icon as={IoCheckmarkDoneCircleSharp} color='teal.300' w={4} h={4} pe='3px' />
										<Text fontSize='sm' color='gray.400' fontWeight='normal'>
											<Text fontWeight='bold' as='span'>
												{stats ? stats.nbr_on_duty : '0'}
											</Text>{' '}
											agent(s) sont en service
										</Text>
									</Flex>
								</Flex>
							</CardHeader>

							<div style={{
								maxHeight: '400px',
								overflowY: 'auto',
								scrollbarWidth: 'thin',
								scrollbarColor: '#582CFF transparent'
							}}>
								<Table variant='simple' color='#fff'>
									<Thead>
										<Tr my='.8rem' ps='0px'>
											<Th
												ps='0px'
												color='gray.400'
												fontFamily='Plus Jakarta Display'
												borderBottomColor='#56577A'>
												Agent
											</Th>
											<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
												Depuis le
											</Th>
											<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
												Arrestations<br />
												<span style={{ fontSize: 'smaller' }}>
													depuis le début du service
												</span>
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{usersDuty.map((row, index, arr) => {
											return (
												<TableUsersOnDuty
													user={row}
													lastItem={index === arr.length - 1 ? true : false}
												/>
											);
										})}
									</Tbody>
								</Table>
							</div>
						</Card>
					) : (
						// Aucun agent en service
						<Card p='16px'>
							<CardHeader p='12px 0px 28px 0px'>
								<Flex direction='column'>
									<Text fontSize='lg' color='#fff' fontWeight='bold' pb='8px'>
										Agents actuellement en service
									</Text>
									<Flex align='center'>
										<Text fontSize='sm' color='gray.400' fontWeight='normal'>
											Tous les agents sont actuellement en repos
										</Text>
									</Flex>
								</Flex>
							</CardHeader>
						</Card>
					)}
				</Grid>
				{config.role("tribunal") && (
					<Grid templateColumns={{ sm: '1fr', md: '1fr 1fr', lg: '2fr 1fr' }} gap='24px'>
						{myArrestsInValidation && myArrestsInValidation.length > 0 ? (
							<Card p='16px'>
								<CardHeader p='12px 0px 28px 0px'>
									<Flex direction='column'>
										<Text fontSize='lg' color='#fff' fontWeight='bold' pb='8px'>
											Mes actes en cours de validation
										</Text>
										<Flex align='center'>
											<Icon as={FaExclamationCircle} color='orange.300' w={4} h={4} pe='3px' />
											<Text fontSize='sm' color='gray.400' fontWeight='normal'>
												<Text fontWeight='bold' as='span'>
													{myArrestsInValidation.length}
												</Text>{' '}
												acte(s) sont encore en cours de validation
											</Text>
										</Flex>
									</Flex>
								</CardHeader>

								<div style={{
									maxHeight: '400px',
									overflowY: 'auto',
									scrollbarWidth: 'thin',
									scrollbarColor: '#582CFF transparent'
								}}>
									<Table variant='simple' color='#fff'>
										<Thead>
											<Tr my='.8rem' ps='0px'>
												<Th
													ps='0px'
													color='gray.400'
													fontFamily='Plus Jakarta Display'
													borderBottomColor='#56577A'>
													Citoyen
												</Th>
												<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
													N° PV
												</Th>
												<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
													Date
												</Th>
												<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
													Status
												</Th>
											</Tr>
										</Thead>
										<Tbody>
											{myArrestsInValidation.map((row, index, arr) => {
												return (
													<TableMyArrests
														arrest={row}
														lastItem={index === arr.length - 1 ? true : false}
													/>
												);
											})}
										</Tbody>
									</Table>
								</div>
							</Card>
						) : (
							// Aucun agent en service
							<Card p='16px'>
								<CardHeader p='12px 0px 28px 0px'>
									<Flex direction='column'>
										<Text fontSize='lg' color='#fff' fontWeight='bold' pb='8px'>
											Mes actes de procédure
										</Text>
										<Flex align='center'>
											<Icon as={FaCheckCircle} color='green.300' w={4} h={4} pe='3px' />
											<Text fontSize='sm' color='gray.400' fontWeight='normal'>
												Félicitation! tous vos actes de procédure ont été validés
											</Text>
										</Flex>
									</Flex>
								</CardHeader>
							</Card>
						)}
						{/*
				<Card p='16px'>
					<CardBody>
						<Flex direction='column' w='100%'>
							<Box
								bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
								borderRadius='20px'
								display={{ sm: 'flex', md: 'block' }}
								justify={{ sm: 'center', md: 'flex-start' }}
								align={{ sm: 'center', md: 'flex-start' }}
								minH={{ sm: '180px', md: '220px' }}
								p={{ sm: '0px', md: '22px' }}>
								<BarChart
									barChartOptions={barChartOptionsDashboard}
									barChartData={barChartDataDashboard}
								/>
							</Box>
							<Flex direction='column' mt='24px' mb='36px' alignSelf='flex-start'>
								<Text fontSize='lg' color='#fff' fontWeight='bold' mb='6px'>
									Heures de service par agent
								</Text>
								<Text fontSize='md' fontWeight='medium' color='gray.400'>
									<Text as='span' color='green.400' fontWeight='bold'>
										+230 nouvelles arrestations
									</Text>{' '}
									depuis le début de l'année
								</Text>
							</Flex>
							<SimpleGrid gap={{ sm: '12px' }} columns={4}>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<WalletIcon h={'15px'} w={'15px'} color='#fff' />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Services
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}
										color='#fff'
										fontWeight='bold'
										mb='6px'
										my='6px'>
										32,984
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={20} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<RocketIcon h={'15px'} w={'15px'} color='#fff' />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Clicks
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}
										color='#fff'
										fontWeight='bold'
										mb='6px'
										my='6px'>
										2.42m
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={90} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<CartIcon h={'15px'} w={'15px'} color='#fff' />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Sales
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}
										color='#fff'
										fontWeight='bold'
										mb='6px'
										my='6px'>
										2,400$
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={30} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<StatsIcon h={'15px'} w={'15px'} color='#fff' />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Items
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}
										color='#fff'
										fontWeight='bold'
										mb='6px'
										my='6px'>
										320
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={50} />
								</Flex>
							</SimpleGrid>
						</Flex>
					</CardBody>
				</Card>
				*/}
						{/* Dernières arrestations */}
						<Card>
							<CardHeader mb='32px'>
								<Flex direction='column'>
									<Text fontSize='lg' color='#fff' fontWeight='bold' mb='6px'>
										Dernières rédactions
									</Text>
									<Flex align='center'>
										<Text fontSize='sm' color='gray.400' fontWeight='normal'>
											<Text fontWeight='bold' as='span' color='gray.400'>
												10
											</Text>{' '}
											dernières rédactions
										</Text>
									</Flex>
								</Flex>
							</CardHeader>
							<CardBody style={{
								maxHeight: '400px',
								overflowY: 'scroll',
								scrollbarWidth: 'thin',
								scrollbarColor: '#582CFF transparent'
							}}>
								<Flex direction='column' lineHeight='21px'>
									{lastArrests.length <= 0 && (
										<Text color='gray.400' mb='20px'>
											Aucune rédaction n'a été enregistrée pour le moment.
										</Text>
									)}
									{lastArrests.map((row, index, arr) => {
										return (
											<Stats_latestArrests
												arrest={row}
											/>
										);
									})}
								</Flex>
							</CardBody>
						</Card>
					</Grid>
				)}
				{/*
			<Grid
				templateColumns={{ sm: '1fr', lg: '1.7fr 1.3fr' }}
				maxW={{ sm: '100%', md: '100%' }}
				gap='24px'
				mb='24px'>
				<Card gridArea={{ md: '2 / 1 / 3 / 2', '2xl': 'auto' }}>
					<CardHeader mb='24px'>
						<Flex direction='column'>
							<Text color='#fff' fontSize='lg' fontWeight='bold' mb='4px'>
								Satisfaction Rate
							</Text>
							<Text color='gray.400' fontSize='sm'>
								From all projects
							</Text>
						</Flex>
					</CardHeader>
					<Flex direction='column' justify='center' align='center'>
						<Box zIndex='-1'>
							<CircularProgress
								size={200}
								value={80}
								thickness={6}
								color='#582CFF'
								variant='vision'
								rounded>
								<CircularProgressLabel>
									<IconBox mb='20px' mx='auto' bg='brand.200' borderRadius='50%' w='48px' h='48px'>
										<Icon as={BiHappy} color='#fff' w='30px' h='30px' />
									</IconBox>
								</CircularProgressLabel>
							</CircularProgress>
						</Box>
						<Stack
							direction='row'
							spacing={{ sm: '42px', md: '68px' }}
							justify='center'
							maxW={{ sm: '270px', md: '300px', lg: '100%' }}
							mx={{ sm: 'auto', md: '0px' }}
							p='18px 22px'
							bg='linear-gradient(126.97deg, rgb(6, 11, 40) 28.26%, rgba(10, 14, 35) 91.2%)'
							borderRadius='20px'
							position='absolute'
							bottom='5%'>
							<Text fontSize='xs' color='gray.400'>
								0%
							</Text>
							<Flex direction='column' align='center' minW='80px'>
								<Text color='#fff' fontSize='28px' fontWeight='bold'>
									95%
								</Text>
								<Text fontSize='xs' color='gray.400'>
									Based on likes
								</Text>
							</Flex>
							<Text fontSize='xs' color='gray.400'>
								100%
							</Text>
						</Stack>
					</Flex>
				</Card>
				<Card gridArea={{ md: '2 / 2 / 3 / 3', '2xl': 'auto' }}>
					<Flex direction='column'>
						<Flex justify='space-between' align='center' mb='40px'>
							<Text color='#fff' fontSize='lg' fontWeight='bold'>
								Secteur [SECTEUR]
							</Text>
							<Button borderRadius='12px' w='38px' h='38px' bg='#22234B' _hover='none' _active='none'>
								<Icon as={IoEllipsisHorizontal} color='#7551FF' />
							</Button>
						</Flex>
						<Flex direction={{ sm: 'column', md: 'row' }}>
							<Flex direction='column' me={{ md: '6px', lg: '52px' }} mb={{ sm: '16px', md: '0px' }}>
								<Flex
									direction='column'
									p='22px'
									pe={{ sm: '22e', md: '8px', lg: '22px' }}
									minW={{ sm: '220px', md: '140px', lg: '220px' }}
									bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
									borderRadius='20px'
									mb='20px'>
									<Text color='gray.400' fontSize='sm' mb='4px'>
										Utilisateurs
									</Text>
									<Text color='#fff' fontSize='lg' fontWeight='bold'>
										145 agents
									</Text>
								</Flex>
								<Flex
									direction='column'
									p='22px'
									pe={{ sm: '22px', md: '8px', lg: '22px' }}
									minW={{ sm: '170px', md: '140px', lg: '170px' }}
									bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
									borderRadius='20px'>
									<Text color='gray.400' fontSize='sm' mb='4px'>
										Bonus
									</Text>
									<Text color='#fff' fontSize='lg' fontWeight='bold'>
										1,465
									</Text>
								</Flex>
							</Flex>
							<Box mx={{ sm: 'auto', md: '0px' }}>
								<CircularProgress
									size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
									value={70}
									thickness={6}
									color='#05CD99'
									variant='vision'>
									<CircularProgressLabel>
										<Flex direction='column' justify='center' align='center'>
											<Text color='gray.400' fontSize='sm'>
												Safety
											</Text>
											<Text
												color='#fff'
												fontSize={{ md: '36px', lg: '50px' }}
												fontWeight='bold'
												mb='4px'>
												9.3
											</Text>
											<Text color='gray.400' fontSize='sm'>
												Total Score
											</Text>
										</Flex>
									</CircularProgressLabel>
								</CircularProgress>
							</Box>
						</Flex>
					</Flex>
				</Card>
			</Grid>
						*/}
			</Flex>
		</>
	);
}
