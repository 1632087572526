import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Select,
  Switch,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  Link
} from "@chakra-ui/react";
import moment from 'moment';
import { usePostData } from "apiService";
import { Link as RouterLink } from "react-router-dom";
import config from 'config';

function TableUsers(props) {
  const { id, name, firstname, username, nigend, phone, discord, avatar_url, on_duty, is_activated, date_creation, grade, role, sector, lastItem, allRoles, allGrades } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const [selectedRole, setSelectedRole] = useState(role || '');
  const [selectedGrade, setSelectedGrade] = useState(grade || '');
  const colorStatus = useColorModeValue("white", "gray.400");
  const [isOpen, setIsOpen] = useState(false);
  const [resetPasswordPopup, setResetPasswordPopup] = useState(false); // New state for reset password popup
  const [newPassword, setNewPassword] = useState(''); // State to store the new password
  const toast = useToast();
  const { postData } = usePostData();
  const [formData, setFormData] = useState({
    firstname: firstname,
    name: name,
    username: username,
    nigend: nigend,
    discord: discord,
    phone: phone,
    selectedGrade: grade || '',
    selectedRole: role || '',
    isActivated: is_activated,
    avatar_url: avatar_url
  });

  useEffect(() => {
    setFormData({
      firstname: firstname,
      name: name,
      username: username,
      nigend: nigend,
      discord: discord,
      phone: phone,
      selectedGrade: grade || '',
      selectedRole: role || '',
      isActivated: is_activated,
      avatar_url: avatar_url
    });
  }, [firstname, name, username, nigend, discord, phone, grade, role, is_activated, avatar_url]);

  const handleOpen = () => {
    if (config.myRole && (allRoles.find(roleInList => roleInList.name === role).key == 'admin' || allRoles.find(roleInList => roleInList.name == role).key == 'staff')) {
      toast({
        title: "Action non autorisée",
        description: "Vous n'êtes pas autorisé à modifier un administrateur ou un membre du staff",
        status: "error",
        duration: 5000,
        position: "top-right",
      });
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleResetPass = async () => {
    setNewPassword('');
    setResetPasswordPopup(true);
    setIsOpen(false);
  };

  const handleSave = async () => {
    // get id of the role and grade
    const role = allRoles.find(role => role.name === formData.selectedRole).key;
    const grade = allGrades.find(grade => grade.name === formData.selectedGrade).id;

    const response = await postData(`updateUser`, {
      id: id,
      firstname: formData.firstname,
      name: formData.name,
      username: formData.username,
      nigend: formData.nigend,
      discord: formData.discord,
      phone: formData.phone,
      grade: grade,
      role: role,
      is_activated: formData.isActivated,
      avatar_url: formData.avatar_url
    });

    if (response) {
      toast({
        title: "Utilisateur modifié",
        description: "Les modifications ont bien été enregistrées",
        status: "success",
        duration: 5000,
        position: "top-right",
      });
    }
    setIsOpen(false);
  };

  const handleResetPasswordConfirm = async () => {
    const newPasswordFromBackend = await postData(`resetPassword`, { id: id });
    if (newPasswordFromBackend) {
      setNewPassword(newPasswordFromBackend.password);
      toast({
        title: "Mot de passe réinitialisé",
        description: "Le nouveau mot de passe a bien été généré",
        status: "success",
        duration: 5000,
        position: "top-right",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSwitchChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      isActivated: !prevData.isActivated,
    }));
  };

  return (
    <>
      <Tr>
        <Td
          minWidth={{ sm: "250px" }}
          ps='0px'
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'>
          <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
            <Avatar
              src={avatar_url}
              w='50px'
              borderRadius='12px'
              me='18px'
              border='none'
              name={formData.firstname}
              opacity={formData.isActivated ? 1 : 0.5}
            />
            <Flex direction='column'>
              <Link
                as={RouterLink}
                fontSize="sm"
                fontWeight="bold"
                cursor="pointer"
                to={`/admin/profile/${id}`}
                _hover={{ color: "purple.100" }}
                color="white"
                opacity={formData.isActivated ? 1 : 0.5}
              >
                {formData.firstname} {formData.name}
              </Link>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                {formData.isActivated ? formData.selectedRole : <Text as='span' color='red.400'>Compte désactivé</Text>}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'
          minW='150px'>
          <Flex direction='column'>
            <Text fontSize='sm' color='#fff' fontWeight='normal' opacity={formData.isActivated ? 1 : 0.5}>
              {formData.selectedGrade}
            </Text>
            <Text fontSize='sm' color='gray.400' fontWeight='normal' opacity={formData.isActivated ? 1 : 0.5}>
              {sector}
            </Text>
          </Flex>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
          <Badge
            bg={on_duty == 1 ? "green.400" : "transparent"}
            color={on_duty == 1 ? "white" : colorStatus}
            fontSize='sm'
            p='3px 10px'
            borderRadius='8px'
            opacity={formData.isActivated ? 1 : 0.5}
            border={on_duty == 1 ? "none" : "1px solid #fff"}
            fontWeight='normal'>
            {on_duty == 1 ? "En service" : "Hors service"}
          </Badge>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A' opacity={formData.isActivated ? 1 : 0.5}>
          <Text fontSize='sm' color='#fff' fontWeight='normal'>
            {formData.nigend || "-"}
          </Text>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A' opacity={formData.isActivated ? 1 : 0.5}>
          <Text fontSize='sm' color='#fff' fontWeight='normal'>
            {formData.phone || "-"}
          </Text>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A' opacity={formData.isActivated ? 1 : 0.5}>
          <Text fontSize='sm' color='#fff' fontWeight='normal'>
            {formData.discord || "-"}
          </Text>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A' opacity={formData.isActivated ? 1 : 0.5}>
          <Text fontSize='sm' color='#fff' fontWeight='normal'>
            {moment(date_creation).format('DD MMMM yyyy')}
          </Text>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
        {config.role("staff") && (
          <Button onClick={handleOpen} p='0px' bg='transparent' variant='no-hover'>
            <Text
              fontSize='sm'
              color='gray.400'
              fontWeight='bold'
              cursor='pointer'>
              Modifier
            </Text>
          </Button>
        )}
        </Td>
      </Tr>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier l'utilisateur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Form fields for user data modification */}
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Prénom
            </Text>
            <Input name="firstname" value={formData.firstname} onChange={handleChange} mb="10px" />
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Nom
            </Text>
            <Input name="name" value={formData.name} onChange={handleChange} mb="10px" />
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Identifiant
            </Text>
            <Input name="username" value={formData.username} onChange={handleChange} mb="10px" />
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Nigend
            </Text>
            <Input name="nigend" value={formData.nigend} onChange={handleChange} mb="10px" />
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Discord
            </Text>
            <Input name="discord" value={formData.discord} onChange={handleChange} mb="10px" />
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Téléphone
            </Text>
            <Input name="phone" value={formData.phone} onChange={handleChange} mb="10px" />
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Grade
            </Text>
            <Select
              name="selectedGrade"
              value={formData.selectedGrade}
              onChange={handleChange}
              placeholder="Assigner un grade"
              size='sm'
              mb="10px"
            >
              {allGrades && allGrades.map(grade => (
                <option key={grade.id} value={grade.name}>{grade.name}</option>
              ))}
            </Select>
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Rôle
            </Text>
            <Select
              name="selectedRole"
              value={formData.selectedRole}
              onChange={handleChange}
              placeholder="Assigner un rôle"
              size='sm'
              mb="10px"
            >
              {config.myRole == "staff" ? (
                <>
                  {allRoles && allRoles.filter(role => role.key !== 'admin' && role.key !== 'staff').map(role => (
                    <option key={role.id} value={role.name}>{role.name}</option>
                  ))}
                </>
              ) : (
                <>
                  {allRoles && allRoles.map(role => (
                    <option key={role.id} value={role.name}>{role.name}</option>
                  ))}
                </>
              )}
            </Select>
            <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              Avatar
            </Text>
            <Input name="avatar_url" value={formData.avatar_url} onChange={handleChange} mb="10px" />
            <Flex align='center' mt='20px'>
              <Switch
                variant='brand'
                colorScheme='brand'
                me='10px'
                isChecked={formData.isActivated}
                onChange={handleSwitchChange}
              />
              <Text noOfLines={1} fontSize='sm' fontWeight='600'>
                {formData.isActivated ? "Compte activé" : "Compte désactivé"}
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            {/* Buttons for actions */}
            <Button colorScheme="red" mr={3} onClick={handleResetPass}>Réinitialiser le mot de passe</Button>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Enregistrer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Reset password popup */}
      <Modal isOpen={resetPasswordPopup} onClose={() => setResetPasswordPopup(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Réinitialiser le mot de passe</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {newPassword ? (
              <Text fontWeight="bold" mb="4">Le nouveau mot de passe pour {firstname} {name} ({username}) est : <Text as="span" fontWeight="normal">{newPassword}</Text></Text>
            ) : (
              <Text>Etes-vous sûr de vouloir réinitialiser le mot de passe de {firstname} {name} ?</Text>
            )}
          </ModalBody>
          <ModalFooter>
            {newPassword ? (
              <Button colorScheme="blue" mr={3} onClick={() => setResetPasswordPopup(false)}>Fermer</Button>
            ) : (
              <>
                <Button colorScheme="blue" mr={3} onClick={handleResetPasswordConfirm}>Oui</Button>
                <Button variant="ghost" onClick={() => setResetPasswordPopup(false)}>Annuler</Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TableUsers;