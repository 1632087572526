import { Input, Button, Box, Grid, Text, Image, Center, useToast, Textarea, HStack } from "@chakra-ui/react";
import Card from "components/Card/Card";
import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/img/logo.png";
import config from "../../config";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { MdArrowBack, MdDelete } from "react-icons/md";
import Icon from "@chakra-ui/icon";
import Loading from "misc/Loading";

import { useFetchData, usePostData } from "apiService";
import { red } from "@mui/material/colors";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from "@chakra-ui/react";
import { Img } from "@chakra-ui/image";
import pv_not_found from "../../assets/img/pv/pv-not-found.png";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

function PV(props) {
    const { buttonBack = true, idPV = 0, manDatas = null, width = "50%", confMode = false, text_introduction = "", text_left_sidebar = "" } = props;

    const { data: pvAPI } = useFetchData(`arrests/${idPV}`);
    const [pv, setPv] = useState(null);
    const { data: authorAPI } = useFetchData(pv ? `citizens/${pv.citizen_id}` : null);
    const [author, setAuthor] = useState(null);
    const { data: participantsAPI } = useFetchData(`arrests/${idPV}/participants`);
    const [participants, setParticipants] = useState([]);
    const { data: redactorAPI } = useFetchData(pv ? `users/${pv.user_id}` : null);
    const [redactor, setRedactor] = useState(null);
    const { data: crimesAPI } = useFetchData(pv ? `arrests/${idPV}/crimes` : null);
    const [crimes, setCrimes] = useState([]);
    const { data: sectorAPI } = useFetchData(redactor ? `users/${redactor.id}/sector` : null);
    const [sector, setSector] = useState(null);

    // Edit mode (for rejected PVs)
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();
    const deletePV = async () => {
        onClose();
        const response = await postData(`deleteArrest`, { id: idPV });
        history.goBack();
        toast({
            title: "PV Supprimé !",
            description: "Le PV a été supprimé avec succès.",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top-right",
        });
    };
    const { postData } = usePostData();
    const [editMode, setEditMode] = useState(false);
    const [redaction, setRedaction] = useState("");
    const handleSaveChanges = async () => {
        const response = await postData(`correctArrest`, { id: idPV, redaction: redaction });
        if (response) {
            history.goBack();
            toast({
                title: "PV Modifié !",
                description: "Le PV modifié a été renvoyé pour validation.",
                status: "success",
                duration: 9000,
                isClosable: true,
                position: "top-right",
            });
        }
    };

    const history = useHistory();
    const [isLoading, setLoading] = useState(!confMode);
    const toast = useToast();

    useEffect(() => {
        if (manDatas) {

            // Loading manually datas (for previews)
            setLoading(false);
            setRedactor(manDatas.redactor);
            if (manDatas.participants.length > 0) {
                setParticipants(manDatas.participants.map((participant) => participant.firstname + " " + participant.name));
            } else {
                setParticipants(["personne"]);
            }

            if (manDatas.crimes.length > 0) {
                setCrimes(manDatas.crimes);
            } else {
                setCrimes(["aucune"]);
            }
        }
    }, [manDatas]);

    useEffect(() => {
        if (pvAPI.length > 0) {
            setPv(pvAPI[0]);
        }
    }, [pvAPI]);

    useEffect(() => {

        // Check if it's a rejected PV
        if (pv) {
            if (pv.is_validated === 2) {
                setRedaction(pv.redaction);
                setEditMode(true);
            }
        }

    }, [pv]);

    useEffect(() => {
        setAuthor(authorAPI[0]);
    }, [authorAPI]);

    useEffect(() => {
        setRedactor(redactorAPI[0]);
    }, [redactorAPI]);

    useEffect(() => {
        if (crimesAPI.length > 0) {
            setCrimes(crimesAPI.map((crime) => crime.name));
        } else {
            setCrimes(["aucune"]);
        }
    }, [crimesAPI]);

    useEffect(() => {
        if (participantsAPI.length > 0) {
            const participants = [];
            participantsAPI.forEach((participant) => {
                participants.push(participant.user_firstname + " " + participant.user_name);
            });
            setParticipants(participants);
        } else {
            setParticipants(["personne"]);
        }

    }, [participantsAPI]);

    useEffect(() => {
        setSector(sectorAPI[0]);
    }, [sectorAPI]);

    useEffect(() => {
        if (pv && author && redactor && crimes.length > 0 && participants.length > 0 && sector) {
            setLoading(false);
        }
    }, [pv, author, redactor, crimes, participants, sector]);

    if (isLoading) {
        if (!pv) {
            return <>
                <Img src={pv_not_found} alt="PV introuvable" width={"100px"} textAlign={"center"} mx={"auto"} display={"block"} mt={10} />
                <Text color={"white"} textAlign={"center"}>Cette arrestation n'existe pas ou a été supprimée.</Text>
            </>
        } else {
            return <Loading />;
        }
    }

    return (
        <>
            {buttonBack ?
                <Button
                    bg={"transparent"}
                    color={"white"}
                    leftIcon={<Icon as={MdArrowBack} />}
                    mb={4}
                    onClick={() => history.goBack()}
                    _active={{ backgroundColor: "transparent" }}
                    _hover={{
                        borderColor: "white",
                        transform: "scale(1.1)",
                        transition: "all 0.2s",
                    }}
                >
                    Retour
                </Button>
                : null
            }
            <Center color={"black"}>
                <Card p="20px" bg="white" width={width} borderRadius={"20px"} boxShadow="0 0 20px 0 black" transition="all 0.3s"
                >
                    <Box bg="#F5F5DC" p="10px" mb="20px" mt={"-20px"} ml={"-20px"} mr={"-20px"} borderTopEndRadius={"20px"} borderTopStartRadius={"20px"} border={"1px solid black"}>
                        <Text fontSize="12px">
                            PV n°4500-{idPV ? idPV : "[???]"} {editMode ? <Text as="span" fontWeight={"bold"} color="red.400">[MODE EDITION]</Text> : null} {pv ? pv.is_archived ? <Text as="span" fontWeight={"bold"} color="red.400">[ARCHIVÉ]</Text> : null : null}
                        </Text>
                        {/* Raison du refus */}
                        {editMode && pv ? (
                            <Text fontSize="12px" color="red.400">
                                <Text as="span" fontWeight={"bold"}>Raison du refus : </Text>{pv.validation_reason ? pv.validation_reason : "-"}
                            </Text>
                        ) : null}
                    </Box>
                    <Grid templateColumns="2fr 5fr" gap={0}>

                        {/* GAUCHE */}
                        <Box borderRight={"2px solid black"} p={"10px"}>
                            <Center><Image src={config.PV.logo_top_left} alt="Logo" width={"100%"} /></Center>
                            <Text fontSize="12px" textDecoration={"underline"} textAlign={"center"}>RÉPUBLIQUE FRANÇAISE</Text>
                            {confMode ?
                                <Text fontSize="12px" fontWeight={"bold"} color={"red.400"} textAlign={"center"} whiteSpace="pre-line">{text_left_sidebar}</Text>
                                : <Text fontSize="12px" fontWeight={"bold"} textAlign={"center"} whiteSpace="pre-line"> {sector ? sector.text_left_sidebar : "[???]"} </Text>
                            }
                            <br />
                            <Text fontSize="12px" fontWeight={"bold"} textAlign={"center"}>PROCES-VERBAL</Text>
                            <Text fontSize="12px" textAlign={"center"}>N°4500-{idPV ? idPV : "[???]"}</Text>
                            <br />
                            <Text fontSize="12px" fontWeight={"bold"} textAlign={"center"}>AFFAIRE</Text>
                            <Text fontSize="12px" textAlign={"left"}>{crimes ? crimes.join(", ") : "[???]"}</Text>
                            <br />
                            <Text fontSize="12px" fontWeight={"bold"} textAlign={"center"}>IDENTITÉ</Text>
                            <Text fontSize="12px" textAlign={"left"}><Text as="span" fontWeight="bold">Nom:</Text> {author ? author.name : manDatas && manDatas.author ? manDatas.author.name : "[???]"}</Text>
                            <Text fontSize="12px" textAlign={"left"}><Text as="span" fontWeight="bold">Prénom:</Text> {author ? author.firstname : manDatas && manDatas.author ? manDatas.author.firstname : "[???]"}</Text>
                            <Text fontSize="12px" textAlign={"left"}><Text as="span" fontWeight="bold">Naissance:</Text> {author ? moment(author.birthdate).format("DD/MM/YYYY") : manDatas && manDatas.author ? moment(manDatas.author.birthdate).format("DD/MM/YYYY") : "[???]"}</Text>
                            <Text fontSize="12px" textAlign={"left"}><Text as="span" fontWeight="bold">Téléphone:</Text> {author ? author.phone : manDatas && manDatas.author ? manDatas.author.phone : "[???]"} </Text>
                            <br />
                            <Text fontSize="12px" fontWeight={"bold"} textAlign={"center"}>OBJET</Text>
                            <Text fontSize="12px" textAlign={"left"}>{pv ? pv.seized_objects : manDatas ? manDatas.seized_objects : "[???]"}</Text>
                            <br />
                            <Center><Image src={logo} alt="Logo" width={"125px"} pr={"20px"} opacity={0.05} /></Center>
                            <Text fontSize="12px">Signé électroniquement par {redactor ? redactor.firstname + " " + redactor.name + " " + (redactor.nigend ? redactor.nigend : "") : "[???]"}{'\n'}</Text>
                        </Box>

                        {/* DROITE */}
                        <Box p="10px" flex="1" display="flex" flexDirection="column">
                            {/* TITRE */}
                            <Text fontSize="32px" fontWeight={"bold"} textAlign={"center"}>PROCES-VERBAL</Text>
                            <Text fontSize="26px" textAlign={"center"}>{pv ? pv.type : manDatas ? manDatas.type : "[???]"}</Text>

                            {/* DATE */}
                            <Box p={"10px"}>
                                {/* L'an deux-mille vingt-quatre */}
                                <Text fontSize="12px">L'an {pv ? numberToWords(new Date(pv.date_creation).getFullYear()) : manDatas ? new Date().getFullYear() : "[???]"}</Text>
                                <Text fontSize="12px">{pv ? formatDateInFrench(new Date(pv.date_creation)) : manDatas ? formatDateInFrench(new Date()) : "[???]"} </Text>
                            </Box>

                            {/* REDACTEUR */}
                            <Text pl={"10px"} fontSize="12px">Nous : {redactor ? redactor.firstname + " " + redactor.name : "[???]"}</Text>
                            <Text pl={"45px"} fontSize="12px">{redactor ? redactor.grade : "-"}</Text>
                            {confMode ?
                                <Text p={"10px"} color={"red.400"} fontSize="12px" whiteSpace="pre-line">{text_introduction}</Text>
                                : <Text p={"10px"} fontSize="12px" whiteSpace="pre-line">{sector ? sector.text_introduction : "[???]"}</Text>
                            }
                            <Text pl={"10px"} fontSize="12px">- Assisté de {participants ? participants.join(", ") : "[???]"}</Text>

                            {/* REDACTION */}
                            <Textarea
                                readOnly={!editMode}
                                onChange={(e) => setRedaction(e.target.value)}
                                border="none"
                                fontSize="12px"
                                p="10px"
                                minHeight={"0"}
                                flex="1"
                                resize={"none"}
                                overflowY="auto"
                                color={editMode ? "red" : "black"}
                                value={!editMode ? (pv ? pv.redaction : manDatas ? manDatas.description : "[???]") : redaction}
                            />

                            {/* BUTTON SAVE CHANGES */}
                            {editMode && (
                                <HStack mt={4} justifyContent="right">
                                    <Button
                                        leftIcon={<MdDelete />}
                                        onClick={() => setIsOpen(true)}
                                        colorScheme="red"
                                    >
                                        Supprimer le PV
                                    </Button>
                                    <Button
                                        onClick={handleSaveChanges}
                                        colorScheme="blue"
                                    >
                                        Sauvegarder les modifications
                                    </Button>
                                    <AlertDialog
                                        isOpen={isOpen}
                                        leastDestructiveRef={cancelRef}
                                        onClose={onClose}
                                    >
                                        <AlertDialogOverlay>
                                            <AlertDialogContent>
                                                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                                    Supprimer le PV
                                                </AlertDialogHeader>

                                                <AlertDialogBody>
                                                    Êtes-vous sûr de vouloir supprimer ce PV ? Cette action est irréversible.
                                                </AlertDialogBody>

                                                <AlertDialogFooter>
                                                    <Button ref={cancelRef} onClick={onClose}>
                                                        Annuler
                                                    </Button>
                                                    <Button colorScheme="red" onClick={deletePV} ml={3}>
                                                        Supprimer
                                                    </Button>
                                                </AlertDialogFooter>
                                            </AlertDialogContent>
                                        </AlertDialogOverlay>
                                    </AlertDialog>
                                </HStack>
                            )}
                        </Box>
                    </Grid>
                </Card>
            </Center>
        </>
    );
}

function numberToWords(number) {
    try {
        const units = ["zéro", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf"];
        const tens = ["dix", "onze", "douze", "treize", "quatorze", "quinze", "seize", "dix-sept", "dix-huit", "dix-neuf"];
        const tensPrefixes = ["", "", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante", "quatre-vingt", "quatre-vingt"];

        if (number < 10) return units[number];
        if (number < 20) return tens[number - 10];
        if (number < 100) {
            const unit = number % 10;
            const ten = Math.floor(number / 10);
            let word = tensPrefixes[ten];
            if (unit === 1 && ten !== 8 && ten !== 9) {
                word += "-et-un";
            } else if (unit > 0) {
                word += (ten === 7 || ten === 9 ? "-dix-" : "-") + units[unit];
            }
            return word;
        }
        if (number < 1000) {
            const hundreds = Math.floor(number / 100);
            const rest = number % 100;
            const restInWords = numberToWords(rest);
            const hundredWord = hundreds > 1 ? units[hundreds] + " cents" : "cent";
            return rest === 0 && hundreds > 1 ? hundredWord + "s" : hundredWord + (rest === 0 ? "" : " " + restInWords);
        }
        if (number < 2000) {
            return "mille " + numberToWords(number % 1000);
        }
        const thousands = Math.floor(number / 1000);
        const rest = number % 1000;
        const restInWords = numberToWords(rest);
        return units[thousands] + " mille" + (rest === 0 ? "" : " " + restInWords);
    } catch (e) {
        return number;
    }
}
function formatDateInFrench(date) {
    try {
        const weekDay = format(date, 'iiii', { locale: fr });
        const day = numberToWords(date.getDate());
        const month = format(date, 'MMMM', { locale: fr });
        const hour = numberToWords(date.getHours());
        const minute = numberToWords(date.getMinutes());

        return `Le ${weekDay} ${day} ${month} à ${hour} heures et ${minute} minutes`;
    } catch (e) {
        return date;
    }
}

export default PV;
