import React from "react";
import { Flex, Link, Text } from "@chakra-ui/react";
import config from "config";

export default function Footer(props) {
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems="center" // Centre verticalement les enfants dans le conteneur Flex
      justifyContent="left" // Centre horizontalement les enfants dans le conteneur Flex
      px='30px'
      pb='20px'
      width="100%" // S'assure que le Flex occupe toute la largeur disponible
      height="100%" // S'assure que le Flex occupe toute la hauteur disponible
    >
      <Text
        fontSize='sm'
        color='white'
        textAlign="center" // Centre le texte dans son conteneur Text
        mb={{ base: "20px", xl: "0px" }}
      >
        &copy; {1900 + new Date().getYear()}{"-" + config.version},{" "}
        <Text as='span'>
          Développé avec ❤️ par 
        </Text>{" "}
        <Link href='https://www.nelx.net'
                      color={"orange"}
                      fontWeight={"bold"}
                      _hover={{ color: "darkorange" }}
                    > NELX
                    </Link>
      </Text>
    </Flex>
  );
}