import {
  Avatar,
  AvatarGroup,
  Flex,
  Icon,
  Progress,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import moment from "moment";

function TableUsersOnDuty(props) {
  const { user, lastItem } = props;
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        ps='0px'
        borderBottomColor='#56577A'
        border={lastItem ? "none" : null}>
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Avatar size='sm' src={user.avatar_url} mr={2} />
          <Text fontSize='sm' color='#fff' fontWeight='normal' minWidth='100%'>
            {user.firstname} {user.name} <Text as='span' color='gray.500'>- {user.grade_name}</Text>
          </Text>
        </Flex>
      </Td>

      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        {user.last_service_start ? moment(user.last_service_start).format("D MMM YYYY à HH:mm") : "-"}
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Text fontSize='sm' color='#fff' fontWeight='bold' pb='.5rem'>
          {user.total_arrests_since_last_service_start}
        </Text>
      </Td>
    </Tr>
  );
}

export default TableUsersOnDuty;
