import React, { useState, useEffect } from "react";

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Icon,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  VStack,
  Heading,
  List,
  ListItem,
  HStack,
  Spacer,
  Box,
  Input,
  Button,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// Icons
import { useToast } from "@chakra-ui/react";

// Api service
import { useFetchData } from "apiService";
import ListLogsService from "components/Lists/ListLogsService";
import moment, { now } from "moment";
import config from "config";
import utils from "utils";
import LineChart from "components/Charts/LineChart";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

function Logs() {
  const toast = useToast();
  const { data: hebdoServices } = useFetchData(`servicesHebdo`);
  const [searchQuery, setSearchQuery] = useState("");
  const [forceRenderCounter, setForceRenderCounter] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const prevPage = () => {
    setCurrentPage((old) => Math.max(old - 1, 1));
  };

  const nextPage = () => {
    setCurrentPage((old) =>
      Math.min(old + 1, Math.ceil(hebdoServices.length / itemsPerPage))
    );
  };

  useEffect(() => {
    setForceRenderCounter((prev) => prev + 1);
  }, [currentPage, searchQuery]);


  const convertToMapDays = (hebdoService) => {
    return [
      { day: "Il y a 6 jours", seconds: hebdoService.day_7 },
      { day: "Il y a 5 jours", seconds: hebdoService.day_6 },
      { day: "Il y a 4 jours", seconds: hebdoService.day_5 },
      { day: "Il y a 3 jours", seconds: hebdoService.day_4 },
      { day: "Il y a 2 jours", seconds: hebdoService.day_3 },
      { day: "Il y a 1 jours", seconds: hebdoService.day_2 },
      { day: "Aujourd'hui", seconds: hebdoService.day_1 }
    ];
  };

  const filteredServices = hebdoServices.filter(hebdoService => {
    return searchQuery === "" || hebdoService.name.toLowerCase().includes(searchQuery.toLowerCase()) || hebdoService.firstname.toLowerCase().includes(searchQuery.toLowerCase());
  }).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }} color={"white"}>
      {/* Services LOGS */}
      <ListLogsService />

      {/* Logs hebdomadaire des services */}
      {config.role("responsable_worker") && (
        <Card position="relative">
          <VStack spacing={4} align="stretch" w="full">
            <Heading as="h1" mb='.5rem' color="white">Heures en service depuis le <Text as="span" color={"pink"}>{moment().subtract(7, 'days').format('D MMMM YYYY')}</Text></Heading>
            <Text color="gray.400">Liste des effectifs en service pour les 7 derniers jours</Text>
            <Input
              placeholder="Rechercher par nom ou prénom..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="filled"
              color="black"
              bg="lightGray"
              _hover={{ bg: "lightGray" }}
              _focus={{ bg: "white" }}
              mt="1rem"
              mb="1rem"
              _placeholder={{ color: "black" }}
              pr="3rem"
            />
            {hebdoServices.length > 0 ? (
              <List spacing={3}>
                {filteredServices.filter((hebdoService) => {
                  if (searchQuery === "") {
                    return hebdoService;
                  } else if (
                    hebdoService.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    hebdoService.firstname
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ) {
                    return hebdoService;
                  }
                }).map((hebdoService, index) => (
                  <ListItem key={index} borderRadius="lg" p={3} boxShadow="sm" _hover={{ bg: "rgba(0, 0, 0, 0.2)", cursor: "pointer" }}>
                    <HStack spacing={4} align="center">
                      <Box flex="1">
                        <Text fontSize="lg" fontWeight="bold">
                          {hebdoService.name} {hebdoService.firstname}
                        </Text>
                        <Text fontSize="sm">
                          {hebdoService.grade_name}
                        </Text>
                      </Box>
                      <Box width="50%">
                        <LineChart
                          key={`line-chart-${index}-${forceRenderCounter}`}
                          datas={convertToMapDays(hebdoService)}
                          services={true}
                        />
                      </Box>
                      <Box flexShrink={0}>
                        <Text fontSize="md" fontWeight="semibold">
                          {utils.convertDuration(hebdoService.total_seconds)}
                        </Text>
                      </Box>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Text>Aucune donnée à afficher</Text>
            )}
            {hebdoServices.length > itemsPerPage && (
              <Flex justify="flex-start" mt="20px" mb="20px">
                <Button
                  variant="ghost"
                  color="white"
                  _hover={{ bg: "brand.100" }}
                  onClick={prevPage}
                  disabled={currentPage === 1}
                >
                  <BiChevronLeft fontSize={32} />
                </Button>
                {Array.from({ length: Math.ceil(hebdoServices.length / itemsPerPage) }).map((_, index) => (
                  <Button
                    key={index}
                    variant={currentPage === index + 1 ? "solid" : "ghost"}
                    bg={currentPage === index + 1 ? "brand.200" : "transparent"}
                    _hover={{ bg: "brand.100" }}
                    color="white"
                    onClick={() => setCurrentPage(index + 1)}
                    mx="2px"
                  >
                    {index + 1}
                  </Button>
                ))}
                <Button
                  variant="ghost"
                  color="white"
                  _hover={{ bg: "brand.100" }}
                  onClick={nextPage}
                  disabled={currentPage === Math.ceil(hebdoServices.length / itemsPerPage)}
                >
                  <BiChevronRight fontSize={32} />
                </Button>
              </Flex>
            )}
          </VStack>
        </Card>
      )}

    </Flex>
  );
}

export default Logs;
