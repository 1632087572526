import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Input
} from "@chakra-ui/react";
import moment from 'moment';
import { usePostData } from "apiService";
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import utils from "utils";

function TableServices(props) {
  const { service, lastItem } = props;
  const toast = useToast();
  const { postData } = usePostData();

  return (
    <>
      <Tr>
        <Td
          minWidth={{ sm: "250px" }}
          ps='0px'
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'>
          <Flex align='center' minWidth='100%' flexWrap='nowrap'>
            <Flex direction='column'>
              <Text
                fontSize='sm'
                color='#fff'
                fontWeight='normal'
                minWidth='100%'>
                {service.firstname} {service.name}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          ps='0px'
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'>
          <Flex align='center' minWidth='100%' flexWrap='nowrap'>
            <Flex direction='column'>
              <Text
                fontSize='sm'
                color='#fff'
                fontWeight='normal'
                minWidth='100%'>
                {moment(service.date_start).format('D MMMM YYYY à HH:mm')}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          ps='0px'
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'>
          <Flex align='center' minWidth='100%' flexWrap='nowrap'>
            <Flex direction='column'>
              <Text
                fontSize='sm'
                color='#fff'
                fontWeight='normal'
                minWidth='100%'>
                {service.date_end ? moment(service.date_end).format('D MMMM YYYY à HH:mm') : <Text color='green.400'>En cours</Text>}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          ps='0px'
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'>
          <Flex align='center' minWidth='100%' flexWrap='nowrap'>
            <Flex direction='column'>
              <Text
                fontSize='sm'
                color='#fff'
                fontWeight='normal'
                minWidth='100%'>
                {utils.convertDuration(service.duree)}
              </Text>
            </Flex>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}

export default TableServices;
