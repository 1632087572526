import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Text,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  useToast,
  Table,
  Thead,
  Th,
  Tbody,
  Icon,
} from "@chakra-ui/react";
import { useFetchData, usePostData } from "apiService";
import TableCrimes from "components/Tables/TableCrimes";
import { AiOutlineProfile, AiOutlinePlus } from "react-icons/ai";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Loading from "misc/Loading";

function ListCrimes() {
  const { data: allCrimes } = useFetchData('crimes');
  const [crimes, setCrimes] = useState([]);
  const [newCrimeName, setNewCrimeName] = useState("");
  const [isAddingCrime, setIsAddingCrime] = useState(false);
  const { postData } = usePostData();
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState("");

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(crimes.length / itemsPerPage);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    const filteredCrimes = allCrimes.filter(crime => crime.name.toLowerCase().includes(searchQuery.toLowerCase()));
    setCrimes(filteredCrimes);
    setCurrentPage(1);
  }, [searchQuery, allCrimes]);

  useEffect(() => {
    if (allCrimes.length > 0) {
      setCrimes(allCrimes);
      setLoading(false);
    }
  }, [allCrimes]);

  const handleAddCrime = async () => {
    if (newCrimeName.trim() === "") {
      toast({
        title: "Erreur",
        description: "Le nom du crime ne peut pas être vide.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      const response = await postData('crimes', { name: newCrimeName });

      if (response) {
        setNewCrimeName("");
        setIsAddingCrime(false);
        setCrimes([...crimes, { id: response.id, name: newCrimeName }]);

        toast({
          title: "Succès",
          description: "Le nouveau crime a été ajouté avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Erreur",
          description: "Une erreur s'est produite lors de l'ajout du crime.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error adding crime:", error);
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de l'ajout du crime.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleDeleteCrime = async (id) => {
    try {
      const response = await postData('deleteCrime', { id });

      if (response) {
        // Refresh crime data
        setCrimes(crimes.filter(crime => crime.id !== id));

        toast({
          title: "Crime supprimé",
          description: "Le crime a été supprimé",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
    catch (error) {
      toast({
        title: "Oops",
        description: "Impossible de supprimer le crime",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  if(loading) return (<Loading />);

  return (
    <>
      <Card my='22px' overflowX={{ sm: 'scroll', xl: 'hidden' }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex direction='row' justify='space-between' w={"100%"}>
            <Flex direction='column' align='flex-start' w='50%'>
              <Text fontSize='lg' color='#fff' fontWeight='bold' mb='.5rem'>
                Infractions
              </Text>
              <Flex align='center'>
                <Icon
                  as={AiOutlineProfile}
                  color='green.500'
                  w='15px'
                  h='15px'
                  me='5px'
                />
                <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                  <Text fontWeight='bold' as='span' color='gray.400'>
                    {crimes.length}
                  </Text>{" "}
                  infractions disponibles
                </Text>
              </Flex>
            </Flex>
            <Button
              mr={2}
              colorScheme='green'
              onClick={() => setIsAddingCrime(true)}
            >
              <Icon as={AiOutlinePlus} w='20px' h='20px' mr={2} />
              Ajouter une nouvelle infraction
            </Button>
          </Flex>
        </CardHeader>
        <Input
            placeholder="Rechercher..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            variant="filled"
            color="black"
            bg="lightGray"
            _hover={{ bg: "lightGray" }}
            _focus={{ bg: "white" }}
            mb="1rem"
            _placeholder={{ color: "black" }}
            pr="3rem"
          />
        <CardBody>
          {crimes.length < 1 ? (
            <Text color='gray.400' mb='20px'>
              Vous n'avez aucun crime ou délit pour le moment.
            </Text>
          ) : (
            <>
              <Table variant='simple' color='#fff'>
                <Thead>
                  <Tr my='.8rem' ps='0px'>
                    <Th
                      color='gray.400'
                      fontFamily='Plus Jakarta Display'
                      borderBottomColor='#56577A'>
                      Nom
                    </Th>
                    <Th borderBottomColor='#56577A'></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {crimes.slice(startIndex, endIndex).map((row) => (
                    <TableCrimes
                      key={row.id}
                      id={row.id}
                      name={row.name}
                      handleDeleteCrime={handleDeleteCrime}
                      lastItem={row.id === crimes[crimes.length - 1].id}
                    />
                  ))}
                </Tbody>
              </Table>
            </>
          )}
        </CardBody>
        {/* Pagination */}
        {totalPages > 0 && (
        <Flex justify="flex-start" mt="20px" mb="20px">
            <Button
              variant="ghost"
              color="white"
              _hover={{ bg: "brand.100" }}
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              <BiChevronLeft fontSize={32} />
            </Button>
            {Array.from({ length: totalPages }).map((_, index) => (
              <Button
                key={index}
                variant={currentPage === index + 1 ? "solid" : "ghost"}
                bg={currentPage === index + 1 ? "brand.200" : "transparent"}
                _hover={{ bg: "brand.100" }}
                color="white"
                onClick={() => goToPage(index + 1)}
                mx="2px"
              >
                {index + 1}
              </Button>
            ))}
            <Button
              variant="ghost"
              color="white"
              _hover={{ bg: "brand.100" }}
              onClick={nextPage}
              disabled={endIndex >= crimes.length}
            >
              <BiChevronRight fontSize={32} />
            </Button>
          </Flex>
        )}
      </Card>

      {/* Modal for adding new crime */}
      <Modal isOpen={isAddingCrime} onClose={() => setIsAddingCrime(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un nouveau crime</ModalHeader>
          <ModalBody>
            <Input
              placeholder="Nom du crime"
              value={newCrimeName}
              onChange={(e) => setNewCrimeName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddCrime}>
              Ajouter
            </Button>
            <Button onClick={() => setIsAddingCrime(false)}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ListCrimes;