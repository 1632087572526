import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Link,
  Text,
  Input,
  useToast,
} from "@chakra-ui/react";
import { Separator } from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import heart from "assets/img/heart.png";
import moment from "moment";
import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";

import { useFetchData, usePostData } from "apiService";

export default function Configurator(props) {
  const { secondary, isOpen, onClose, fixed, ...rest } = props;
  const [switched, setSwitched] = useState(props.isChecked);
  const { data: currentUsers } = useFetchData('users/0');
  const [currentUser, setCurrentUser] = useState(null);
  const [editedUser, setEditedUser] = useState({});
  const [haveModification, setHaveModification] = useState(false);

  // État et méthodes pour la pop-up de modification de mot de passe
  const { isOpen: isPasswordModalOpen, onOpen: onPasswordModalOpen, onClose: onPasswordModalClose } = useDisclosure();
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const toast = useToast();
  const { postData } = usePostData();

  useEffect(() => {
    setCurrentUser(currentUsers[0]);
    if (currentUsers[0]) {
      setEditedUser({
        firstname: currentUsers[0].firstname,
        name: currentUsers[0].name,
        avatar_url: currentUsers[0].avatar_url,
        nigend: currentUsers[0].nigend,
        phone: currentUsers[0].phone,
        discord: currentUsers[0].discord
      });
    }
  }, [currentUsers]);

  // Gérer les changements dans les champs d'entrée
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser({ ...editedUser, [name]: value });
    setHaveModification(true);
  };

  // Sauvegarder les modifications
  const saveChanges = async () => {
    if (!haveModification) return;
    try {
      const response = await postData('editMyAccount', editedUser);

      if (response) {
        setCurrentUser({ ...currentUser, ...editedUser });
        setHaveModification(false);
        toast({
          title: 'Modifications enregistrées',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de sauvegarder les modifications',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const changePassword = () => {

    // Mots de passe ne correspondent pas
    if (newPassword !== confirmNewPassword) {
      toast({
        title: 'Erreur',
        description: 'Les mots de passe ne correspondent pas',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Mots de passe trop courts
    if (newPassword.length < 3) {
      toast({
        title: 'Erreur',
        description: 'Le mot de passe doit contenir au moins 3 caractères',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Envoi de la requête de changement de mot de passe
    try {
      const response = postData('changePassword', { newPassword });

      if (response) {
        toast({
          title: 'Mot de passe modifié',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }

    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de modifier le mot de passe',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    // Fermer la pop-up
    onPasswordModalClose();
  };

  // Chakra Color Mode
  let fixedDisplay = "flex";
  if (props.secondary) {
    fixedDisplay = "none";
  }
  let colorButton = "white";
  const secondaryButtonColor = "white";
  const settingsRef = React.useRef();
  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}>
        <DrawerContent
          bg='linear-gradient(111.84deg, rgba(6, 11, 38, 0.94) 59.3%, rgba(26, 31, 55, 0) 100%)'
          backdropFilter='blur(42px)'>
          <DrawerHeader pt='24px' px='24px'>
            <DrawerCloseButton color='white' />
            <Flex justifyContent={"center"} py='.8rem' minWidth='100%' flexWrap='nowrap'>
              <Avatar
                src={editedUser.avatar_url}
                w='100px'
                h='100px'
                borderRadius='12px'
                border='none'
                name={editedUser.firstname}
              />
            </Flex>
            <Text textAlign={"center"} color='white' fontSize='xl' fontWeight='bold' mt='16px'>
              {editedUser ? editedUser.firstname + " " + editedUser.name : '???'}
            </Text>
            <Text textAlign={"center"} color='gray.400' fontSize='xl' fontWeight='bold' mb='16px'>
              {currentUser ? currentUser.grade : '???'}
            </Text>
            <Text textAlign={"center"} color='gray.400' fontSize='sm' mb='4px'>{currentUser ? "Depuis le " + moment(currentUser.date_creation).format('DD MMMM yyyy') : '???'}</Text>
            <Separator />
          </DrawerHeader>
          <DrawerBody w='340px' ps='24px' pe='40px'>
            <Flex flexDirection='column'>

              <Text color='white' fontSize='md' fontWeight='600' mb='4px'>Image de profil</Text>
              <Input
                name="avatar_url"
                value={editedUser ? editedUser.avatar_url : '???'}
                onChange={handleInputChange}
                color='white' fontSize={'16px'} padding={'10px'} mb='16px'
              />

              <Text color='white' fontSize='md' fontWeight='600' mb='4px'>Prénom</Text>
              <Input
                name="firstname"
                value={editedUser ? editedUser.firstname : '???'}
                onChange={handleInputChange}
                color='white' fontSize={'16px'} padding={'10px'} mb='16px'
              />

              <Text color='white' fontSize='md' fontWeight='600' mb='4px'>Nom</Text>
              <Input
                name="name"
                value={editedUser ? editedUser.name : '???'}
                onChange={handleInputChange}
                color='white' fontSize={'16px'} padding={'10px'} mb='16px'
              />

              <Text color='white' fontSize='md' fontWeight='600' mb='4px'>RIO / NIGEND</Text>
              <Input
                name="nigend"
                value={editedUser ? editedUser.nigend : '???'}
                onChange={handleInputChange}
                color='white' fontSize={'16px'} padding={'10px'} mb='16px'
              />

              <Text color='white' fontSize='md' fontWeight='600' mb='4px'>N° de téléphone</Text>
              <Input
                name="phone"
                value={editedUser ? editedUser.phone : '???'}
                onChange={handleInputChange}
                color='white' fontSize={'16px'} padding={'10px'} mb='16px'
              />

              <Text color='white' fontSize='md' fontWeight='600' mb='4px'>Discord</Text>
              <Input
                name="discord"
                value={editedUser ? editedUser.discord : '???'}
                onChange={handleInputChange}
                color='white' fontSize={'16px'} padding={'10px'} mb='16px'
              />

              {/* Bouton pour sauvegarder les modifications */}
              <Button
                colorScheme={haveModification ? 'blue' : 'transparent'}
                color={haveModification ? 'white' : 'gray.400'}
                variant='solid'
                size='md'
                fontWeight='bold'
                mt='20px'
                w='100%'
                enabled={haveModification}
                onClick={saveChanges}
              >
                Enregistrer les modifications
              </Button>

              {/* Bouton pour modifier le mot de passe */}
              <Button
                colorScheme='orange'
                variant='solid'
                size='md'
                fontWeight='bold'
                mt='20px'
                w='100%'
                onClick={onPasswordModalOpen}
              >
                Modifier mon mot de passe
              </Button>

              <Box mt={"10px"}>
                <Box w='100%'>

                  <Flex justifyContent='center' alignContent='center'>
                    {/* Heart logo */}
                    <div>
                      <img src={heart} width="50px" alt="Logo"></img>
                    </div>
                  </Flex>
                  <Text color='white' mb='6px' textAlign='center'>
                    Merci d'utiliser XGuard !
                  </Text>
                  <Text color='white' mb='6px' textAlign='center'>
                    Par
                    <Link href='https://www.nelx.net'
                      color={"orange"}
                      fontWeight={"bold"}
                      _hover={{ color: "darkorange" }}
                    > NELX
                    </Link>
                  </Text>
                </Box>
              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* Modal pour le changement de mot de passe */}
      <Modal isOpen={isPasswordModalOpen} onClose={onPasswordModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Changer mon mot de passe</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Input type="password" placeholder='Nouveau mot de passe' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} mb={3} />
            <Input type="password" placeholder='Confirmez le nouveau mot de passe' value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={changePassword}>
              Modifier mon mot de passe
            </Button>
            <Button onClick={onPasswordModalClose}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};