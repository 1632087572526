import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Input
} from "@chakra-ui/react";
import moment from 'moment';
import { usePostData } from "apiService";
import { EditIcon, DeleteIcon, ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";

function TableGrades(props) {
  const { id, name, lastItem, firstItem, handleDeleteGrade, sector, handleMoveUp, handleMoveDown } = props;
  const toast = useToast();
  const { postData } = usePostData();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [newGradeName, setnewGradeName] = useState(""); // Ajout du nouvel état pour stocker le nouveau nom du grade
  const onCloseEdit = () => setIsEditOpen(false);

  useEffect(() => {
    // Mettre à jour le nom du grade dans le champ d'entrée lorsque la boîte de dialogue est ouverte
    if (isEditOpen) {
      setnewGradeName(name);
    }
  }, [isEditOpen, name]);

  useEffect(() => {
    setnewGradeName(name);
  }, [name]);

  const handleEdit = () => {
    setIsEditOpen(true);
  }

  const handleDelete = () => {
    setIsDeleteOpen(true);
  }

  const onCloseDelete = () => setIsDeleteOpen(false);

  const confirmDelete = async () => {
    try {
      // Supprimer le grade
      await postData(`deleteGrade`, { id: id });

      // Appeler la méthode handleDeleteGrade du parent
      handleDeleteGrade(id);

      // Fermer la boîte de dialogue
      setIsDeleteOpen(false);
    } catch (err) {
      toast({
        title: "Erreur",
        description: "Impossible de supprimer le grade",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  const confirmEdit = async () => {
    try {
      // Mettre à jour le grade avec le nouveau nom
      const response = await postData(`updateGrade`, { id: id, name: newGradeName });

      if (response) {
        setIsEditOpen(false);
        toast({
          title: "Grade modifié",
          description: "Le grade a été modifié",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (err) {
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour le grade",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Tr>
        <Td
          minWidth={{ sm: "250px" }}
          ps='0px'
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'>
          <Flex align='center' minWidth='100%' flexWrap='nowrap'>
            <Flex direction='column'>
              <Text
                fontSize='sm'
                color='#fff'
                fontWeight='normal'
                minWidth='100%'>
                {newGradeName}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
          <Flex justifyContent="flex-end">
            {/* Bouton Déplacer vers le Haut */}
            <Button
              mr={2}
              onClick={() => handleMoveUp(id)}
              visibility={firstItem ? 'hidden' : 'visible'}
              bg='gray'
              color='white'
              _hover={{ bg: "blue.300" }}
              _active={{ bg: "blue.300" }}
              _focus={{ boxShadow: "none" }}
              size="sm"
              fontSize='sm'
              fontWeight='normal'>
              <ArrowUpIcon />
            </Button>

            {/* Bouton Déplacer vers le Bas */}
            <Button
              mr={2}
              onClick={() => handleMoveDown(id)}
              visibility={lastItem ? 'hidden' : 'visible'}
              bg='gray'
              color='white'
              _hover={{ bg: "blue.300" }}
              _active={{ bg: "blue.300" }}
              _focus={{ boxShadow: "none" }}
              size='sm'
              fontSize='sm'
              fontWeight='normal'>
              <ArrowDownIcon />
            </Button>

            {/* Boutons Modifier et Supprimer */}
            <Button
              mr={2}
              onClick={() => handleDelete(id)}
              visibility={id === 1 ? 'hidden' : 'visible'}
              bg='red.400'
              color='white'
              _hover={{ bg: "red.300" }}
              _active={{ bg: "red.300" }}
              _focus={{ boxShadow: "none" }}
              size='sm'
              px='1.5rem'
              fontSize='sm'
              fontWeight='normal'
              ms='1rem'>
              <DeleteIcon />
            </Button>
            <Button
              onClick={handleEdit}
              visibility={id === 1 ? 'hidden' : 'visible'}
              bg='brand.200'
              color='white'
              _hover={{ bg: "brand.100" }}
              _active={{ bg: "brand.100" }}
              _focus={{ boxShadow: "none" }}
              size='sm'
              px='1.5rem'
              fontSize='sm'
              fontWeight='normal'>
              <EditIcon />
            </Button>
          </Flex>
        </Td>
      </Tr>

      {/* Popup de modification */}
      <AlertDialog
        isOpen={isEditOpen}
        leastDestructiveRef={null}
        onClose={onCloseEdit}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Modifier</AlertDialogHeader>
            <AlertDialogBody>
              <Input
                value={newGradeName}
                onChange={(e) => setnewGradeName(e.target.value)}
                placeholder="Nouveau nom du grade"
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onCloseEdit}>Annuler</Button>
              <Button colorScheme="blue" ml={3} onClick={confirmEdit}>
                Modifier
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Popup de suppression */}
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={null}
        onClose={onCloseDelete}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Confirmation de suppression</AlertDialogHeader>
            <AlertDialogBody>
              Êtes-vous sûr de vouloir supprimer le grade <Text as={"span"} fontWeight="bold">{name}</Text> ?<br /><br />⚠️ Cette action est irréversible et tous les utilisateurs associés à ce grade auront leur grade réinitialisé à <Text as={"span"} fontWeight="bold">Visiteur</Text>.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onCloseDelete}>Annuler</Button>
              <Button colorScheme="red" ml={3} onClick={confirmDelete}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default TableGrades;
