import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  VStack,
  HStack,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Input,
  Textarea,
  Spacer,
  Select,
  Collapse,
  Divider,
  Grid,
  Flex,
} from '@chakra-ui/react';
import { MdCheckCircle, MdDescription, MdArchive, MdEdit, MdCancel } from 'react-icons/md';
import Card from "components/Card/Card";
import Loading from 'misc/Loading';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import CardCitoyen from 'components/Card/CardCitoyen';
import InfoBubble from "misc/InfoBubble";
import { MdVisibility, MdVisibilityOff, MdRestore, MdFolder, MdFolderOpen, MdDelete, MdExpandMore, MdExpandLess } from 'react-icons/md';
import config from 'config';

import { useFetchData, usePostData } from "apiService";
import { useToast } from "@chakra-ui/react";
import { MdCreateNewFolder } from 'react-icons/md';

function CitoyenDetail() {
  const { idCitoyen } = useParams();
  const { data: citoyens } = useFetchData(`citizens/${idCitoyen}`);
  const [citoyen, setCitoyen] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { data: arrestationsDB } = useFetchData(`citizenArrests/${idCitoyen}`);
  const { data: foldersDB } = useFetchData(`citizenFolders/${idCitoyen}`);
  const { data: casesDB } = useFetchData(`citizenCases/${idCitoyen}`);
  const [bulletins, setBulletins] = useState([]);
  const [arrestations, setArrestations] = useState([]);
  const [arrestationsByFolder, setArrestationsByFolder] = useState([]);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [legal_records, setLegalRecords] = useState('');
  const [legalRecordsEdited, setLegalRecordsEdited] = useState(false);
  const [reason, setReason] = useState('');
  const [showArchived, setShowArchived] = useState(false); // Nouvelle variable d'état
  const { postData } = usePostData();
  const [selectedArrestId, setSelectedArrestId] = useState(null);

  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [folderDescription, setFolderDescription] = useState('');
  const [expandedFolders, setExpandedFolders] = useState({});
  const [selectedFolderId, setSelectedFolderId] = useState(null);

  const [isFodlerDeleteModalOpen, setIsFolderDeleteModalOpen] = useState(false);

  const [isSelectFolderModalOpen, setIsSelectFolderModalOpen] = useState(false);
  const [moveArrestId, setMoveArrestId] = useState(null);

  const [expandedIndex, setExpandedIndex] = useState(null);

  const [isDeleteBulletinModalOpen, setIsDeleteBulletinModalOpen] = useState(false);
  const [selectedBulletinId, setSelectedBulletinId] = useState(null);

  const handleDeleteBulletin = (bulletinId) => {
    setSelectedBulletinId(bulletinId);
    setIsDeleteBulletinModalOpen(true);
  };

  const confirmDeleteBulletin = async () => {
    // Supprimer le bulletin via l'API
    if (!selectedBulletinId) return;
    const response = await postData('deleteBulletin', { id: selectedBulletinId });
    if (response) {
      // Mettre à jour l'état local pour refléter la suppression
      setBulletins(bulletins.filter(b => b.id !== selectedBulletinId));
      toast({
        title: "Bulletin supprimé",
        description: "Le bulletin a été supprimé avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la suppression du bulletin.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    }
    setIsDeleteBulletinModalOpen(false);
  };

  const handleEditBulletin = (bulletinId) => {
    // Trouver les données du bulletin pour les préremplir dans le formulaire
    const bulletin = bulletins.find(b => b.id === bulletinId);
    if (bulletin) {
      setBulletinType(bulletin.bulletin_number.toString());
      setPenaltyDetails(bulletin.penalty_details);
      setRehabilitationInfo(bulletin.rehabilitation_info);
      setSelectedBulletinId(bulletinId);
    }
    // Ouvrir le modal de modification
    setIsBulletinModalOpen(true);
  };

  const handleToggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  // Bulletin modal
  const [isBulletinModalOpen, setIsBulletinModalOpen] = useState(false);
  const [bulletinType, setBulletinType] = useState('');
  const [penaltyDetails, setPenaltyDetails] = useState('');
  const [rehabilitationInfo, setRehabilitationInfo] = useState('');

  const openBulletinModal = () => {
    setBulletinType('');
    setPenaltyDetails('');
    setRehabilitationInfo('');
    setSelectedBulletinId(null);
    setIsBulletinModalOpen(true);
  };

  const createBulletin = async (createNew = true) => {

    // Vérifier que le bulletin est complet
    if (bulletinType === '') return toast({
      title: "Erreur",
      description: "Le numéro de bulletin ne peut pas être vide.",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right"
    });

    if (penaltyDetails === '') return toast({
      title: "Erreur",
      description: "Les détails de la peine ne peuvent pas être vides.",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right"
    });

    if (createNew) {
      const response = await postData(`createBulletin/${idCitoyen}`, { bulletinType, penaltyDetails, rehabilitationInfo: rehabilitationInfo || null });
      if (response) {
        toast({
          title: "Bulletin créé",
          description: "Le bulletin a été créé avec succès. (Rafrachissez la page pour voir le nouveau bulletin)",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });
        setIsBulletinModalOpen(false);
      } else {
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de la création du bulletin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });
      }
    } else {
      const response = await postData(`updateBulletin/${selectedBulletinId}`, { bulletinType, penaltyDetails, rehabilitationInfo });
      if (response) {
        toast({
          title: "Bulletin modifié",
          description: "Le bulletin a été modifié avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });
        setIsBulletinModalOpen(false);

        // Mettre à jour l'état local pour refléter la modification
        try {
          setBulletins(prevBulletins => prevBulletins.map(b => {
            if (b.id === selectedBulletinId) {
              return { ...b, bulletin_number: parseInt(bulletinType, 10), penalty_details: penaltyDetails, rehabilitation_info: rehabilitationInfo };
            }
            return b;
          }));
        } catch (error) {
          console.error(error);
        }

      } else {
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de la modification du bulletin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });
      }
    }
  };

  const openSelectFolderModal = (arrestId) => {
    setMoveArrestId(arrestId);
    setIsSelectFolderModalOpen(true);
  };

  const toggleFolder = (folderId) => {
    setExpandedFolders(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  const handleDeleteArrest = async (arrestId) => {
    const response = await postData('archiveArrest', { id: arrestId, reason });
    if (response) {
      // Notification de succès
      toast({
        title: "Archivage réussi",
        description: "L'arrestation a été archivée avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
      try {
        // Mise à jour de l'état pour refléter le changement
        setArrestations(prevArrestations => prevArrestations.map(arrestation => {
          if (arrestation.id === arrestId) {
            return { ...arrestation, is_archived: true, archived_date: new Date().toISOString(), archived_reason: reason };
          }
          return arrestation;
        }));

        // Mise à jour de l'état des arrestations par dossier (set is_archived to true et archived_date to now et archived_reason to reason)
        setArrestationsByFolder(prevFolders => {
          const newFolders = { ...prevFolders };
          Object.keys(newFolders).forEach(fid => {
            newFolders[fid].arrestations = newFolders[fid].arrestations.map(a => {
              if (a.id === arrestId) {
                return { ...a, is_archived: true, archived_date: new Date().toISOString(), archived_reason: reason };
              }
              return a;
            });
          });
          return newFolders;
        });

      } catch (error) {
        console.error(error);
      }

      // Fermer le modal
      setIsOpen(false);
    } else {
      // Notification d'erreur
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de l'archivage de l'arrestation.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    }
  }

  const handleDeleteFolderModal = (folderId) => {
    setSelectedFolderId(folderId);
    setIsFolderDeleteModalOpen(true);
  };

  const handleOpenFolderModal = (folderId = null) => {
    setSelectedFolderId(folderId);
    if (folderId) {
      const folderData = arrestationsByFolder[folderId];
      setFolderName(folderData.folder_name);
      setFolderDescription(folderData.folder_description);
    } else {
      setFolderName('');
      setFolderDescription('');
    }
    setIsFolderModalOpen(true);
  };

  const toast = useToast();
  const onClose = () => {
    setIsOpen(false);
    setReason('');
  };
  const cancelRef = React.useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (casesDB) {
      setBulletins(casesDB);
    }
  }, [casesDB]);

  useEffect(() => {
    if (arrestationsDB && foldersDB) {
      setArrestations(arrestationsDB);

      let arrestationsByFolder = arrestationsDB.reduce((acc, arrestation) => {
        if (arrestation.folder_id !== null) {
          if (!acc[arrestation.folder_id]) {
            acc[arrestation.folder_id] = {
              arrestations: [],
              folder_name: arrestation.folder_name,
              folder_description: arrestation.folder_description,
              folder_date_creation: arrestation.folder_date_creation
            };
          }
          acc[arrestation.folder_id].arrestations.push(arrestation);
        }
        return acc;
      }, {});

      // Ajouter les dossiers qui sont vides
      foldersDB.forEach(folder => {
        if (!arrestationsByFolder[folder.id]) {
          arrestationsByFolder[folder.id] = {
            arrestations: [],
            folder_name: folder.name,
            folder_description: folder.description,
            folder_date_creation: folder.date_creation
          };
        }
      });

      setArrestationsByFolder(arrestationsByFolder);
    }
  }, [arrestationsDB, foldersDB]);

  useEffect(() => {
    if (citoyens) {
      setCitoyen(citoyens[0]);
    }
  }, [citoyens]);

  useEffect(() => {
    if (citoyen) {
      if (citoyen.legal_records == null) {
        setLegalRecords("Aucune mention judiciaire n'a été enregistrée pour " + citoyen.firstname + " " + citoyen.name + ".");
      } else {
        setLegalRecords(citoyen.legal_records);
      }
    }
  }, [citoyen]);

  useEffect(() => {
    if (citoyen && arrestations) {
      setIsLoading(false);
    }
  }, [citoyen, arrestations]);

  const handleOpenArrestDetails = (arrestId) => {
    history.push(`/admin/pv/${arrestId}`);
  };

  const moveArrestToFolder = async (folderId) => {
    if (folderId === '') folderId = null;
    const response = await postData('moveArrest', { arrestId: moveArrestId, folderId });
    if (response) {
      // Notification de succès
      toast({
        title: "Déplacement réussi",
        description: "L'arrestation a été classée avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });

      // Mise à jour de l'état des arrestations pour refléter le changement
      setArrestations(prevArrestations => {
        return prevArrestations.map(arrest => {
          if (arrest.id === moveArrestId) {
            return { ...arrest, folder_id: folderId };
          }
          return arrest;
        });
      });

      // Mise à jour de l'état des arrestations par dossier
      setArrestationsByFolder(prevFolders => {
        const newFolders = { ...prevFolders };
        // Retirer l'arrestation de l'ancien dossier
        Object.keys(newFolders).forEach(fid => {
          newFolders[fid].arrestations = newFolders[fid].arrestations.filter(a => a.id !== moveArrestId);
        });
        // Ajouter l'arrestation au nouveau dossier
        if (folderId) {
          if (!newFolders[folderId]) {
            newFolders[folderId] = {
              arrestations: [],
              folder_name: foldersDB.find(f => f.id === folderId).name,
              folder_description: foldersDB.find(f => f.id === folderId).description,
              folder_date_creation: foldersDB.find(f => f.id === folderId).date_creation
            };
          }
          newFolders[folderId].arrestations.push(arrestations.find(a => a.id === moveArrestId));
        }
        return newFolders;
      });

    } else {
      // Notification d'erreur
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors du déplacement de l'arrestation.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    }
    setIsSelectFolderModalOpen(false);
  };

  const editFolder = async () => {
    try {
      if (folderName === '') return toast({
        title: "Erreur",
        description: "Le nom du dossier ne peut pas être vide.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });

      if (folderDescription.length > 2000) return toast({
        title: "Erreur",
        description: "La description du dossier ne peut pas dépasser 2000 caractères.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });

      const response = await postData('editFolder', { folderId: selectedFolderId, folderName, folderDescription });
      if (response) {
        toast({
          title: "Dossier modifié",
          description: "Le dossier a été modifié avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });
        setIsFolderModalOpen(false);
        setFolderName('');
        setFolderDescription('');

        // Mettre à jour l'état pour refléter le changement
        setArrestationsByFolder(prev => ({
          ...prev,
          [selectedFolderId]: {
            ...prev[selectedFolderId],
            folder_name: folderName,
            folder_description: folderDescription
          }
        }));
      } else {
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de la modification du dossier.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la modification du dossier.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    }
  };

  const createFolder = async () => {
    try {

      if (folderName === '') return toast({
        title: "Erreur",
        description: "Le nom du dossier ne peut pas être vide.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });

      if (folderDescription.length > 2000) return toast({
        title: "Erreur",
        description: "La description du dossier ne peut pas dépasser 2000 caractères.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });

      const response = await postData('createFolder', { idCitoyen, folderName, folderDescription });
      if (response) {
        toast({
          title: "Dossier créé",
          description: "Le dossier a été créé avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });
        setIsFolderModalOpen(false);
        setFolderName('');
        setFolderDescription('');

        // Add the folder to the list of folders
        const newFolder = {
          id: response.insertId,
          name: folderName,
          description: folderDescription,
          date_creation: new Date().toISOString()
        };
        setArrestationsByFolder(prev => ({
          ...prev,
          [response.insertId]: {
            arrestations: [],
            folder_name: folderName,
            folder_description: folderDescription,
            folder_date_creation: new Date().toISOString()
          }
        }));

      } else {
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de la création du dossier.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la création du dossier.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    }
  };

  const handleDeleteFolder = async (folderId) => {
    // Appel API pour supprimer le dossier
    const response = await postData('deleteFolder', { folderId });
    if (response) {
      // Notification de succès
      toast({
        title: "Dossier supprimé",
        description: "Le dossier a été supprimé avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });

      // Mise à jour de l'état des arrestations pour enlever le folder_id
      setArrestations(prevArrestations => prevArrestations.map(arrestation => {
        if (arrestation.folder_id === folderId) {
          return { ...arrestation, folder_id: null };
        }
        return arrestation;
      }));

      // Mise à jour de l'état des dossiers pour enlever le dossier supprimé et redistribuer les arrestations
      setArrestationsByFolder(prevFolders => {
        const newFolders = { ...prevFolders };
        const removedArrestations = newFolders[folderId].arrestations;
        delete newFolders[folderId];  // Supprimer le dossier de l'état  
        return newFolders;
      });

      setIsFolderDeleteModalOpen(false);  // Fermer le modal de suppression
    } else {
      // Notification d'erreur
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la suppression du dossier.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    }
  };

  const handleRestoreArrest = async (arrestId) => {
    try {
      // Appel du backend pour restaurer l'arrestation avec juste l'ID comme paramètre
      const response = await postData('restoreArrest', { id: arrestId });

      if (response) {
        // Mettre à jour l'état pour refléter que l'arrestation a été restaurée
        const newArrestations = arrestations.map((arrestation) => {
          if (arrestation.id === arrestId) {
            arrestation.is_archived = false;
            arrestation.archived_date = null;
            arrestation.archived_reason = null;
          }
          return arrestation;
        });
        setArrestations(newArrestations);
        try {
          setArrestationsByFolder(prevFolders => {
            const newFolders = { ...prevFolders };
            Object.keys(newFolders).forEach(fid => {
              newFolders[fid].arrestations = newFolders[fid].arrestations.map(a => {
                if (a.id === arrestId) {
                  return { ...a, is_archived: false, archived_date: null, archived_reason: null };
                }
                return a;
              });
            });
            return newFolders;
          });
        } catch (error) {
          console.error(error);
        }

        toast({
          title: "Arrestation restaurée",
          description: "L'arrestation n°" + arrestId + " a été restaurée",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
    catch (error) {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la restauration de l'arrestation",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <VStack spacing={5} p={5} borderRadius="md" pt={{ base: "120px", md: "75px" }}>

      {/* Informations sur le citoyen */}
      <CardCitoyen idCitoyen={idCitoyen} />

      {/* Mentions judiciaires (legal_records) */}
      <Card position="relative">

        {/* Affichage */}
        <VStack spacing={4} align="stretch" w="full">
          <Heading as="h1" size="xl" color="white">Mentions judiciaires</Heading>
          {(config.role("staff") || config.role("tribunal", true)) && <Text color='lightgray'>⭐ <b>Vous avez les droits pour modifier les mentions judiciaires de ce citoyen !</b> Pour le faire, vous n'avez qu'à modifier le texte ci-dessous en cliquant dessus.</Text>}
          <Textarea value={legal_records} color="white" bg="transparent" border={"none"} onChange={(e) => { setLegalRecords(e.target.value); setLegalRecordsEdited(true); }} h={legal_records.split('\n').length * 1.5 + "rem"} isReadOnly={!(config.role("staff") || config.role("tribunal", true))} />
        </VStack>

        {/* Bouton d'édition */}
        {(config.role("staff") || config.role("tribunal", true)) && (
          <Button
            colorScheme="green"
            onClick={() => {
              try {
                const response = postData(`setLegalRecords/${idCitoyen}`, { legal_records });
                if (response) {
                  setLegalRecordsEdited(false);
                  toast({
                    title: "Mentions judiciaires modifiées",
                    description: "Les mentions judiciaires ont été modifiées avec succès.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                  });
                } else {
                  toast({
                    title: "Erreur",
                    description: "Une erreur est survenue lors de la modification des mentions judiciaires.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                  });
                }
              } catch (error) {
                console.error(error);
                toast({
                  title: "Erreur",
                  description: "Une erreur est survenue lors de la modification des mentions judiciaires.",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                  position: "top-right"
                });
              }
            }}
            position="absolute"
            top="0"
            right="0"
            zIndex="1"
            m="1rem"
            visibility={legalRecordsEdited ? "visible" : "hidden"}
            leftIcon={<MdEdit />}
          >
            Enregistrer les modifications
          </Button>
        )}

      </Card>

      {/* Section des arrestations */}
      <Card position="relative">
        {(config.role("responsable_worker") || config.role("tribunal", true)) && (
          <div style={{ position: 'absolute', top: '0', right: '0', zIndex: '1', display: 'flex', alignItems: 'right' }}>
            <Button
              colorScheme="green"
              onClick={() => handleOpenFolderModal(null)}
              mr="1rem"
              mt="1rem"
              leftIcon={<MdCreateNewFolder />}
            >
              Créer un dossier
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => setShowArchived(!showArchived)}
              mr="1rem"
              mt="1rem"
              leftIcon={showArchived ? <MdVisibility /> : <MdVisibilityOff />}
            >
              Voir PV archivés
            </Button>
          </div>
        )}
        <VStack spacing={4} align="stretch" w="full">
          <Heading as="h1" size="xl" color="white">Procès-verbaux</Heading>
          {arrestations.length === 0 && (
            <Text fontSize="md" color="white">{citoyen.firstname} {citoyen.name} n'a jamais été arrêté.</Text>
          )}
          <List spacing={3}>

            {/* Affichage des arrestations avec dossier */}
            {Object.keys(arrestationsByFolder).map((folderId, index) => (
              <VStack key={index} align="stretch" w="full">
                <HStack as="h2" size="lg" color="white" onClick={() => toggleFolder(folderId)} style={{ cursor: 'pointer' }}>
                  <ListIcon as={expandedFolders[folderId] ? MdFolderOpen : MdFolder} color="blue.500" />
                  <Text fontSize={"18px"}>{folderId && arrestationsByFolder[folderId] ? arrestationsByFolder[folderId].folder_name : "Dossier sans nom"}</Text>
                  <ListIcon as={MdEdit} onClick={() => handleOpenFolderModal(folderId)} color="gray.500" cursor="pointer" _hover={{ color: "gray.300" }} />
                  <ListIcon as={MdDelete} onClick={() => handleDeleteFolderModal(folderId)} color="red.500" cursor="pointer" _hover={{ color: "red.300" }} />
                </HStack>
                {expandedFolders[folderId] && (
                  <>
                  {arrestationsByFolder[folderId].folder_description && (
                    <Card pl={4} pr={4} bg="rgba(255, 255, 255, 0.1)" pb={2} pt={2}>
                    <Text fontSize="md" color="gray.400">{arrestationsByFolder[folderId].folder_description}</Text>
                    </Card>
                  )}
                    <List spacing={3}>

                      {arrestationsByFolder[folderId].arrestations.map((arrestation, index) => (
                        <ListItem key={index} display={!showArchived && arrestation.is_archived ? "none" : "block"}>
                          <HStack justifyContent="space-between">
                            <HStack ml={4}>
                              <ListIcon
                                as={arrestation.is_archived ? MdCancel : MdDescription}
                                color={arrestation.is_archived ? "red.500" : "white"}
                                opacity={arrestation.is_archived ? 0.4 : 1}
                              />
                              <VStack spacing={0} align="stretch">
                                <Text fontSize="md" color="white" opacity={arrestation.is_archived ? 0.4 : 1}>
                                  PV N°4500-{arrestation.id} <Text as="span" fontSize="sm" color="gray.400">{arrestation.arrest_type ? "(" + arrestation.arrest_type + ")" : ""}</Text>
                                </Text>
                                {arrestation.is_archived ? (
                                  <Text fontSize='sm' color='gray.400' fontWeight='normal' opacity={arrestation.is_archived ? 0.4 : 1}>
                                    Archivé le {moment(arrestation.archived_date).format('D MMMM yyyy')} {arrestation.archived_reason ? "pour la raison suivante : " + arrestation.archived_reason : ""}
                                  </Text>
                                ) : (
                                  <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                                    Rédigé par {arrestation.officer_firstname} {arrestation.officer_name}, le {moment(arrestation.date_creation).format('D MMMM yyyy')}
                                  </Text>
                                )}
                              </VStack>
                            </HStack>
                            <HStack>
                              {!arrestation.is_archived && (
                                <>
                                  {(config.role("responsable_worker") || config.role("tribunal", true)) && (
                                    <Button colorScheme="gray" onClick={() => { openSelectFolderModal(arrestation.id) }}><MdFolder /></Button>
                                  )}
                                  {(config.role("staff") || config.role("tribunal", true)) && (
                                    <Button colorScheme="red" onClick={() => { setSelectedArrestId(arrestation.id); setIsOpen(true); }}><MdArchive color="white" size={24} /> Archiver</Button>
                                  )}
                                  <Button colorScheme="blue" onClick={() => handleOpenArrestDetails(arrestation.id)} leftIcon={<MdDescription color="white" size={24} />}>Lire le procès-verbal</Button>
                                </>
                              )}
                              {arrestation.is_archived && (config.role("staff") || config.role("tribunal", true)) && (
                                <Button colorScheme="green" onClick={() => handleRestoreArrest(arrestation.id)} leftIcon={<MdRestore color="white" size={24} />}>Restaurer</Button>
                              )}
                            </HStack>
                          </HStack>
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}
              </VStack>
            ))}

            {/* Affichage des arrestations sans dossier */}
            {arrestations.filter((arrestation) => arrestation.folder_id === null).map((arrestation, index) => (
              <ListItem key={index} display={!showArchived && arrestation.is_archived ? "none" : "block"}>
                <HStack justifyContent="space-between">
                  <HStack>
                    <ListIcon
                      as={arrestation.is_archived ? MdCancel : MdDescription}
                      color={arrestation.is_archived ? "red.500" : "white"}
                      opacity={arrestation.is_archived ? 0.4 : 1}
                    />
                    <VStack spacing={0} align="stretch">
                      <Text fontSize="md" color="white" opacity={arrestation.is_archived ? 0.4 : 1}>
                        PV N°4500-{arrestation.id} <Text as="span" fontSize="sm" color="gray.400">{arrestation.arrest_type ? "(" + arrestation.arrest_type + ")" : ""}</Text>
                      </Text>
                      {arrestation.is_archived ? (
                        <Text fontSize='sm' color='gray.400' fontWeight='normal' opacity={arrestation.is_archived ? 0.4 : 1}>
                          Archivé le {moment(arrestation.archived_date).format('D MMMM yyyy')} {arrestation.archived_reason ? "pour la raison suivante : " + arrestation.archived_reason : ""}
                        </Text>
                      ) : (
                        <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                          Rédigé par {arrestation.officer_firstname} {arrestation.officer_name}, le {moment(arrestation.date_creation).format('D MMMM yyyy')}
                        </Text>
                      )}
                    </VStack>
                  </HStack>
                  <HStack>
                    {!arrestation.is_archived && (
                      <>
                        {(config.role("responsable_worker") || config.role("tribunal", true)) && (
                          <Button colorScheme="gray" onClick={() => { openSelectFolderModal(arrestation.id) }}><MdFolder /></Button>
                        )}
                        {(config.role("staff") || config.role("tribunal", true)) && (
                          <Button colorScheme="red" onClick={() => { setSelectedArrestId(arrestation.id); setIsOpen(true); }}><MdArchive color="white" size={24} /> Archiver</Button>
                        )}
                        <AlertDialog
                          isOpen={isOpen}
                          leastDestructiveRef={cancelRef}
                          onClose={onClose}
                        >
                          <AlertDialogOverlay>
                            <AlertDialogContent>
                              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Archiver le procès verbal ?
                              </AlertDialogHeader>
                              <AlertDialogBody>
                                <VStack align="stretch">
                                  <Text>Êtes-vous sûr de vouloir archiver le PV n°4500-{selectedArrestId} ?</Text>
                                  <Input
                                    type="text"
                                    placeholder="Raison de l'archivage"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                  />
                                </VStack>
                              </AlertDialogBody>
                              <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                  Annuler
                                </Button>
                                <Button colorScheme="red" onClick={() => handleDeleteArrest(selectedArrestId)} ml={3}>
                                  Archiver
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialogOverlay>
                        </AlertDialog>
                        <Button colorScheme="blue" onClick={() => handleOpenArrestDetails(arrestation.id)} leftIcon={<MdDescription color="white" size={24} />}>Lire le procès-verbal</Button>
                      </>
                    )}
                    {arrestation.is_archived && (config.role("staff") || config.role("tribunal", true)) && (
                      <Button colorScheme="green" onClick={() => handleRestoreArrest(arrestation.id)} leftIcon={<MdRestore color="white" size={24} />}>Restaurer</Button>
                    )}
                  </HStack>
                </HStack>
              </ListItem>
            ))}
          </List>
        </VStack>
      </Card>

      {/* SECTION CASIER JUDICIAIRE */}
      <Card position="relative">
        {(config.role("staff") || config.role("tribunal", true)) && (
          <div style={{ position: 'absolute', top: '0', right: '0', zIndex: '1', display: 'flex', alignItems: 'right' }}>
            <Button
              colorScheme="green"
              onClick={openBulletinModal}
              mr="1rem"
              mt="1rem"
              leftIcon={<MdCreateNewFolder />}
            >
              Créer un bulletin
            </Button>
          </div>
        )}
        <VStack spacing={4} align="stretch" w="full">
          <Heading as="h1" size="xl" color="white">Casier Judiciaire</Heading>

          {/* Liste des bulletins */}
          {bulletins.length > 0 ? (
            <>
              <Text fontSize="md" color="white">Le casier judiciaire de {citoyen.firstname} {citoyen.name} contient {bulletins.length} bulletin{bulletins.length > 1 ? 's' : ''}.</Text>
              <List spacing={3}>
                {bulletins.map((bulletin, index, expanded = false) => (
                  <Card key={index} bg={bulletin.bulletin_number === 1 ? "rgba(255, 165, 0, 0.2)" : (bulletin.bulletin_number === 2 ? "rgba(144, 238, 144, 0.2)" : "rgba(255, 127, 80, 0.2)")}>
                    <ListItem>
                      <HStack justifyContent="space-between">
                        <HStack>
                          <ListIcon as={MdDescription} color={bulletin.bulletin_number === 1 ? "orange" : (bulletin.bulletin_number === 2 ? "lightgreen" : "coral")} />
                          <VStack spacing={0} align="stretch">
                            <Text fontSize="md" color="white">
                              Bulletin n°{bulletin.bulletin_number} <Text as="span" fontSize="sm" color="gray.400">({bulletin.id})</Text>
                            </Text>
                            <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                              Rédigé par {bulletin.redactor_firstname} {bulletin.redactor_name} ({bulletin.redactor_grade_name}), le {moment(bulletin.date_creation).format('D MMMM yyyy à HH:mm')}
                            </Text>
                          </VStack>
                        </HStack>
                        <HStack>
                          {/* DELETE BUTTON */}
                          {(config.role("staff") || config.role("tribunal", true)) && (
                            <Button
                              colorScheme="red"
                              onClick={() => handleDeleteBulletin(bulletin.id)}
                              leftIcon={<MdDelete color="white" size={24} />}
                              pr="0"
                              pl="2"
                            />

                          )}

                          {/* EDIT BUTTON */}
                          {(config.role("staff") || config.role("tribunal", true)) && (
                            <Button
                              colorScheme="green"
                              onClick={() => handleEditBulletin(bulletin.id)}
                              leftIcon={<MdEdit color="white" size={24} />}
                              pr="0"
                              pl="2"
                            />
                          )}

                          {/* EXPAND BUTTON */}
                          <Button colorScheme="blue" onClick={() => handleToggleExpand(index)} leftIcon={expandedIndex === index ? <MdExpandLess color="white" size={24} /> : <MdExpandMore color="white" size={24} />}>
                            {expandedIndex === index ? 'Fermer' : 'Ouvrir'}
                          </Button>
                        </HStack>
                      </HStack>
                      <Collapse in={expandedIndex === index} animateOpacity>
                        <Card borderRadius="lg" p={4} bg="white" width="70%" margin="auto" mt={4}>
                          <Heading as="h2" size="md" align="center" mt={4}>REPUBLIQUE FRANCAISE</Heading>
                          <Heading as="h2" size="md" align="center" >MINISTÈRE DE LA JUSTICE</Heading>
                          <Heading as="h2" size="md" align="center" >Direction des affaire criminelles et des grâces</Heading>
                          <Heading as="h2" size="md" align="center" >Casier judiciaire national</Heading>
                          <br /><br />
                          <Text fontSize="xl" align="center" mb={4}>BULLETIN NUMÉRO {bulletin.bulletin_number}</Text>
                          <Divider borderColor="black" />
                          <Text fontSize="md" pt={4} pl={20}>Bulletin délivré le {moment(bulletin.date_creation).format('D MMMM yyyy à HH:mm')}</Text>
                          <Grid templateColumns="repeat(2, 1fr)" gap={4} pl={20} pr={20} pt={10} pb={5}>
                            <VStack align="stretch" spacing={0}>
                              <Text fontSize="md">Vérifiable auprès:</Text>
                              <Text fontSize="md"><b>Tribunal Judiciaire de {config.tenantName}</b></Text>
                              <Text fontSize="md">Date de délivrance: {moment(bulletin.date_creation).format('D MMMM yyyy')}</Text>
                              <Text fontSize="md">Heure de délivrance: {moment(bulletin.date_creation).format('HH:mm')}</Text>
                              <Text fontSize="md">Identifiant document: {bulletin.id}</Text>
                            </VStack>
                            <VStack align="stretch" spacing={0}>
                              <Text fontSize="md" fontWeight={"bold"}>IDENTITÉ</Text>
                              <Text fontSize="md">Nom: {bulletin.citizen_name}</Text>
                              <Text fontSize="md">Prénom: {bulletin.citizen_firstname}</Text>
                              <Text fontSize="md">Sexe: {bulletin.citizen_sexe}</Text>
                              <Text fontSize="md">Date de naissance: {moment(bulletin.citizen_birthdate).format('D MMMM yyyy')}</Text>
                              <Text fontSize="md">Lieu: {bulletin.citizen_address}</Text>
                            </VStack>
                          </Grid>
                          <Divider borderColor="black" />
                          <Flex align="center" justify="center">
                            <Text fontSize="md" pt={5} pb={5}>Relevé des condamnations devant figurer au bulletin numéro {bulletin.bulletin_number} (article 777 du Code de procédure pénale)</Text>
                          </Flex>
                          <Divider borderColor="black" />
                          <VStack align="stretch" spacing={0} pl={20} pr={20}>
                            <Text fontSize="md" pt={5}>Détails de la peine:</Text>
                            <Text fontSize="md" pl={20} pr={20} pb={5}>{bulletin.penalty_details}</Text>
                            <Text fontSize="md" pt={5}>Informations de réhabilitation:</Text>
                            <Text fontSize="md" pl={20} pr={20} pb={5}>{bulletin.rehabilitation_info}</Text>
                          </VStack>
                          <Divider borderColor="black" />
                          <Text fontSize="md" pt={5} pb={5} fontFamily={"COMIC SANS MS"} fontWeight={"bold"}>En l’absence de condamnation devant figurer au bulletin numéro {bulletin.bulletin_number}, celui–ci comporte une barre transversale (article R. 84 du Code de procédure pénale)</Text>
                          <Divider borderColor="black" />
                          {/* Text aligned to the right */}
                          <Text fontSize="sm" pr={5} align="right">Le magistrat, délivrant de l’extrait du Casier Judiciaire National</Text>
                          <Text fontSize="sm" pr={5} align="right" pb={5}>{bulletin.redactor_grade_name}, {bulletin.redactor_firstname} {bulletin.redactor_name}</Text>
                        </Card>
                      </Collapse>
                    </ListItem>
                  </Card>
                ))}
              </List>
            </>
          ) : (
            <Text fontSize="md" color="lightgreen">Le casier judiciaire de {citoyen.firstname} {citoyen.name} est vide.</Text>
          )}
        </VStack>
      </Card>

      {/* Modal pour la suppression d'un bulletin */}
      <AlertDialog
        isOpen={isDeleteBulletinModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteBulletinModalOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le bulletin ?
            </AlertDialogHeader>
            <AlertDialogBody>
              Êtes-vous sûr de vouloir supprimer ce bulletin ? Cette action est irréversible.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteBulletinModalOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteBulletin} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Modal pour la création de bulletin */}
      <AlertDialog isOpen={isBulletinModalOpen} onClose={() => setIsBulletinModalOpen(false)} leastDestructiveRef={cancelRef} width='90%' size='xl'>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {selectedBulletinId ? "Modifier le bulletin" : "Créer un bulletin"}
            </AlertDialogHeader>
            <AlertDialogBody>
 <VStack spacing={4}>            
                <Select placeholder="Choisir le type de bulletin" onChange={(e) => setBulletinType(e.target.value)} color={bulletinType ? (bulletinType === "1" ? 'darkorange' : (bulletinType === "2" ? 'green' : 'red')) : 'black'} value={bulletinType ? bulletinType : ''}>
                  <option value="1" style={{ color: 'darkorange' }}>Bulletin n°1</option>
                  <option value="2" style={{ color: 'green' }}>Bulletin n°2</option>
                  <option value="3" style={{ color: 'red' }}>Bulletin n°3</option>
                </Select>
                <Text fontSize="md" color={"darkorange"}>Bulletin numéro 1: <Text as="span" color={"gray"}>Historique judiciaire de la personne concernée, et comporte l'ensemble des condamnations pénales</Text></Text>
            <Text fontSize="md" color={"green"}>Bulletin numéro 2: <Text as="span" color={"gray"}>Comporte les peines d'emprisonnement ferme pour crimes et délits</Text></Text>
            <Text fontSize="md" color={"red"} mb={2}>Bulletin numéro 3: <Text as="span" color={"gray"}>Contient les condamnations pour crimes et délits punis de plus de 2 ans de prison sans sursis, l'interdiction d'exercer une activité professionnelle impliquant des mineurs, les déchéances ou incapacités</Text></Text>
             
                <Textarea placeholder="Détails de la peine" value={penaltyDetails} onChange={(e) => setPenaltyDetails(e.target.value)} maxLength={2000} />
                <Textarea placeholder="Informations de réhabilitation (facultatif)" value={rehabilitationInfo} onChange={(e) => setRehabilitationInfo(e.target.value)} maxLength={2000} />
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsBulletinModalOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="blue" onClick={() => { createBulletin(!selectedBulletinId) }} ml={3}>
                {selectedBulletinId ? "Modifier" : "Créer"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* MODAL suppression de dossier */}
      <AlertDialog
        isOpen={isFodlerDeleteModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsFolderDeleteModalOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le dossier ?
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack align="stretch">
                <Text>Êtes-vous sûr de vouloir supprimer ce dossier ? Tous les procès-verbaux qu'il contient seront déclassés.</Text>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsFolderDeleteModalOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={() => handleDeleteFolder(selectedFolderId)} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* MODAL selection du dossier */}
      <AlertDialog
        isOpen={isSelectFolderModalOpen}
        onClose={() => setIsSelectFolderModalOpen(false)}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Classer le procès-verbal
            </AlertDialogHeader>
            <AlertDialogBody>
              <Select placeholder="Choisir un dossier" onChange={(e) => moveArrestToFolder(e.target.value)}>
                <option value="" style={{ color: 'darkkorange' }}>Sans dossier</option>
                {Object.keys(arrestationsByFolder).map((folderId) => (
                  <option key={folderId} value={folderId}>
                    {arrestationsByFolder[folderId].folder_name}
                  </option>
                ))}
              </Select>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsSelectFolderModalOpen(false)}>
                Annuler
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* MODAL creation de dossier */}
      <AlertDialog
        isOpen={isFolderModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsFolderModalOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {selectedFolderId ? "Modifier le dossier" : "Créer un dossier"}
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack spacing={4}>
                <Input
                  placeholder="Nom du dossier"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                />
                <Textarea
                  placeholder="Description (facultative)"
                  value={folderDescription}
                  onChange={(e) => setFolderDescription(e.target.value)}
                />

              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setIsFolderModalOpen(false)} ml={3}>
                  Annuler
                </Button>
                <Button colorScheme="blue" onClick={() => { selectedFolderId ? editFolder() : createFolder() }} ml={3}>
                  {selectedFolderId ? "Modifier" : "Créer"}
                </Button>
              </AlertDialogFooter>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
}

export default CitoyenDetail;