import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Text,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  useToast,
  Table,
  Thead,
  Th,
  Tbody,
  Icon,
} from "@chakra-ui/react";
import { useFetchData, usePostData } from "apiService";
import { AiOutlineProfile, AiOutlinePlus } from "react-icons/ai";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Loading from "misc/Loading";
import TableArrestTypes from "components/Tables/TableArrestTypes";

function ListArrestTypes() {
  const { data: allTypes } = useFetchData('arrestsTypes');
  const [types, setTypes] = useState([]);
  const [newTypeName, setNewTypeName] = useState("");
  const [isAddingType, setIsAddingType] = useState(false);
  const { postData } = usePostData();
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(types.length / itemsPerPage);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    setTypes(allTypes);
    setCurrentPage(1);
  }, [allTypes]);

  useEffect(() => {
    if (allTypes.length > 0) {
      setTypes(allTypes);
      setLoading(false);
    }
  }, [allTypes]);

  const handleAddType = async () => {
    if (newTypeName.trim() === "") {
      toast({
        title: "Erreur",
        description: "Le nom du type ne peut pas être vide.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      const response = await postData('arrestsType', { name: newTypeName });

      if (response) {
        setNewTypeName("");
        setIsAddingType(false);
        setTypes([...types, { id: response.id, name: newTypeName }]);

        toast({
          title: "Succès",
          description: "Le nouveau type a été ajouté avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Erreur",
          description: "Une erreur s'est produite lors de l'ajout du type.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error adding type:", error);
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de l'ajout du type.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleDeleteType = async (id) => {
    try {
      const response = await postData('deleteArrestType', { id });

      if (response) {
        // Refresh type data
        setTypes(types.filter(type => type.id !== id));

        toast({
          title: "Type supprimé",
          description: "Le type a été supprimé",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
    catch (error) {
      toast({
        title: "Oops",
        description: "Impossible de supprimer le type",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  if(loading) return (<Loading />);

  return (
    <>
      <Card my='22px' overflowX={{ sm: 'scroll', xl: 'hidden' }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex direction='row' justify='space-between' w={"100%"}>
            <Flex direction='column' align='flex-start' w='50%'>
              <Text fontSize='lg' color='#fff' fontWeight='bold' mb='.5rem'>
                Types de procès-verbal
              </Text>
              <Flex align='center'>
                <Icon
                  as={AiOutlineProfile}
                  color='green.500'
                  w='15px'
                  h='15px'
                  me='5px'
                />
                <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                  <Text fontWeight='bold' as='span' color='gray.400'>
                    {types.length}
                  </Text>{" "}
                  types d'actes disponibles
                </Text>
              </Flex>
            </Flex>
            <Button
              mr={2}
              colorScheme='green'
              onClick={() => setIsAddingType(true)}
            >
              <Icon as={AiOutlinePlus} w='20px' h='20px' mr={2} />
              Ajouter un nouveau type
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          {types.length < 1 ? (
            <Text color='gray.400' mb='20px'>
              Vous n'avez aucun type pour le moment.
            </Text>
          ) : (
            <>
              <Table variant='simple' color='#fff'>
                <Thead>
                  <Tr my='.8rem' ps='0px'>
                    <Th
                      color='gray.400'
                      fontFamily='Plus Jakarta Display'
                      borderBottomColor='#56577A'>
                      Nom
                    </Th>
                    <Th borderBottomColor='#56577A'></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {types.slice(startIndex, endIndex).map((row) => (
                    <TableArrestTypes
                      key={row.id}
                      id={row.id}
                      name={row.name}
                      handleDeleteType={handleDeleteType}
                      lastItem={row.id === types[types.length - 1].id}
                    />
                  ))}
                </Tbody>
              </Table>
            </>
          )}
        </CardBody>
        {/* Pagination */}
        {totalPages > 0 && (
        <Flex justify="flex-start" mt="20px" mb="20px">
            <Button
              variant="ghost"
              color="white"
              _hover={{ bg: "brand.100" }}
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              <BiChevronLeft fontSize={32} />
            </Button>
            {Array.from({ length: totalPages }).map((_, index) => (
              <Button
                key={index}
                variant={currentPage === index + 1 ? "solid" : "ghost"}
                bg={currentPage === index + 1 ? "brand.200" : "transparent"}
                _hover={{ bg: "brand.100" }}
                color="white"
                onClick={() => goToPage(index + 1)}
                mx="2px"
              >
                {index + 1}
              </Button>
            ))}
            <Button
              variant="ghost"
              color="white"
              _hover={{ bg: "brand.100" }}
              onClick={nextPage}
              disabled={endIndex >= types.length}
            >
              <BiChevronRight fontSize={32} />
            </Button>
          </Flex>
        )}
      </Card>

      {/* Modal for adding new type */}
      <Modal isOpen={isAddingType} onClose={() => setIsAddingType(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un nouveau type</ModalHeader>
          <ModalBody>
            <Input
              placeholder="Nom du type"
              value={newTypeName}
              onChange={(e) => setNewTypeName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddType}>
              Ajouter
            </Button>
            <Button onClick={() => setIsAddingType(false)}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ListArrestTypes;