import { Flex, Text, Link, Avatar } from "@chakra-ui/react";
import React from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";

function StatsLatestArrests(props) {
  const { arrest } = props;

  return (
    <Flex alignItems="center" minH="78px" justifyContent="start" mb="5px">
      <Avatar
        size="sm"
        name={`${arrest.author_firstname} ${arrest.author_name}`}
        src={`${arrest.author_avatar_url}`}
        bg="gray.500"
        color="white"
        mb="5px"
        mr="15px"
      />
      <Flex direction="column" justifyContent="center">
        <Link
          as={RouterLink}
          fontSize="sm"
          fontWeight="bold"
          cursor="pointer"
          to={`/admin/citoyens/${arrest.author_id}`}
          _hover={{ color: "purple.100" }}
          color="white"
          target="_blank" // Open in new tab
          rel="noopener noreferrer" // Open in new tab (security)
        >
          {`${arrest.author_firstname} ${arrest.author_name}`}
        </Link>
        <Text fontSize="sm" color="gray.400" fontWeight="normal">
          {`Rédigé par ${arrest.redactor_firstname} ${arrest.redactor_name} le ${moment(
            arrest.date_creation
          ).format("D MMMM YYYY à HH:mm:ss")}`}
        </Text>
      </Flex>
    </Flex>
  );
}

export default StatsLatestArrests;
