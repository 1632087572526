import React, { useState } from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Icon,
  Button,
  useColorModeValue,
  Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import moment from 'moment';
import 'moment/locale/fr';

function TableValidateUsers(props) {
  const { username, name, firstname, date, lastItem, handleRefuse, roles, grades } = props;
  const textColor = useColorModeValue("gray.700", "white");

  const [selectedRole, setSelectedRole] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  const handleValidateClick = () => {
    if (selectedRole && selectedGrade) {

      // get id of the role and grade
      const role = roles.find(role => role.name === selectedRole).key;
      const grade = grades.find(grade => grade.name === selectedGrade).id;

      props.handleValidate(username, role, grade);
      setSelectedRole('');
      setSelectedGrade('');
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <Tr>
      <Td
        minWidth={{ base: "250px" }}
        ps='0px'
        borderBottomColor='#56577A'
        border={lastItem ? "none" : null}>
        <Flex alignItems='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Icon as={FaUser} h={"20px"} w={"20px"} me='18px' />
          <Text fontSize='sm' color='#fff' minWidth='100%'>
            {username}
          </Text>
        </Flex>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null} minWidth={{ base: "150px" }}>
        <Text fontSize='sm' color='#fff' fontWeight='bold' pb='.5rem'>
          {firstname}
        </Text>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null} minWidth={{ base: "150px" }}>
        <Text fontSize='sm' color='#fff' fontWeight='bold' pb='.5rem'>
          {name}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
        <Text fontSize='sm' color='#fff' fontWeight='normal'>
          {moment(date).format('DD.MM.yyyy à H[h]mm')}
        </Text>
      </Td>
      <Td
        border={lastItem ? "none" : null}
        borderBottomColor='#56577A'
        textAlign='right'>
        <Button
          onClick={handleValidateClick}
          bg='brand.200'
          color='white'
          _hover={{ bg: "brand.100" }}
          _active={{ bg: "brand.100" }}
          _focus={{ boxShadow: "none" }}
          size='sm'
          px='1.5rem'
          fontSize='sm'
          fontWeight='normal'>
          Valider
        </Button>
        <Button
          onClick={() => handleRefuse(username)}
          bg='red.400'
          color='white'
          _hover={{ bg: "red.300" }}
          _active={{ bg: "red.300" }}
          _focus={{ boxShadow: "none" }}
          size='sm'
          px='1.5rem'
          fontSize='sm'
          fontWeight='normal'
          ms='1rem'>
          Refuser
        </Button>
        <AlertDialog isOpen={isOpen} onClose={onClose}>
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Sélectionnez un grade et un rôle pour {firstname} {name}
            </AlertDialogHeader>
            <AlertDialogBody>
              <Select
                value={selectedGrade}
                onChange={(e) => setSelectedGrade(e.target.value)}
                placeholder="Assigner un grade"
                size='sm'
                mb="1rem"
              >
                {grades && grades.map(grade => (
                  <option key={grade.id} value={grade.name}>{grade.name}</option>
                ))}
              </Select>
              <Select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                placeholder="Assigner un rôle"
                size='sm'
              >
                {roles && roles.map(role => (
                  <option key={role.id} value={role.name}>{role.name}</option>
                ))}
              </Select>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button colorScheme="brand" onClick={handleValidateClick}>
                Valider
              </Button>
              <Button colorScheme="red" ml={3} onClick={onClose}>
                Annuler
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </Td>
    </Tr>
  );
}

export default TableValidateUsers;
