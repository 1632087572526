
const utils = {
    convertDuration(duration) {
        if (duration === 0) {
            return "0s";
        }
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;

        let result = "";
        if (hours > 0) {
            result += `${hours}h `;
        }
        if (minutes > 0) {
            result += `${minutes}m `;
        }
        if (seconds > 0) {
            result += `${seconds}s`;
        }

        return result.trim();
    }
};

export default utils;
























