// apiService.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from 'config';
import { useToast } from '@chakra-ui/react';

// Hook pour GET
export const useFetchData = (url) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const getAuthHeaders = () => ({
      Authorization: `Bearer ${Cookies.get('token')}`
    });

    const fetchData = async () => {
      try {
        if (!url) {
          // no URL
          return;
        }

        if (Cookies.get('token')) {
          // Fetch data
          const response = await axios.get(`${config.apiUrl}/${url}`, {
            headers: getAuthHeaders()
          });

          if (response.status !== 200) {
            if (response.status === 403) {
              toast({
                title: "Oops!",
                description: "Vous n’avez pas les droits pour effectuer cette action",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });
            } else if (response.status === 423) {
              toast({
                title: "Erreur",
                description: "Votre compte est désactivé",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });
              Cookies.remove('token');
              window.location.reload();
            } else {
              toast({
                title: "Erreur",
                description: "Impossible de charger les données",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });
            }
          }

          setData(response.data);
        }

      } catch (err) {
        setError(err);
        if (err.response && err.response.status === 403) {
          // Forbidden
        } else if (err.response.status === 423) {
          toast({
            title: "Erreur",
            description: "Votre compte est désactivé",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          Cookies.remove('token');
          window.location.reload();
        } else {
          toast({
            title: "Erreur",
            description: "Impossible de charger les données",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, isLoading, error };
};

// Hook pour POST
export const usePostData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();

  const postData = async (url, data) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${config.apiUrl}/${url}`, data, {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`
        }
      });

      if (response.status !== 200) {
        toast({
          title: "Erreur",
          description: "Impossible d’envoyer les données",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }

      return response.data;
    } catch (err) {
      setError(err);
      if (err.response && err.response.status === 403) {
        toast({
          title: "Oops!",
          description: "Vous n’avez pas les droits pour effectuer cette action",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Erreur",
          description: "Impossible d’envoyer les données",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { postData, isLoading, error };
};