import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Text,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  useToast,
  Table,
  Thead,
  Th,
  Tbody,
  Icon,
  Tooltip,
  Td,
  Select,
  Heading,
} from "@chakra-ui/react";
import { useFetchData, usePostData } from "apiService";
import { AiOutlineProfile, AiOutlinePlus } from "react-icons/ai";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Loading from "misc/Loading";
import TableServices from "components/Tables/TableServices";
import { AiOutlineInfoCircle } from "react-icons/ai";
import InfoBubble from "misc/InfoBubble";

function ListLogsService() {
  const [servicesUrl, setServicesUrl] = useState('services');
  const { data: userServices, isLoading: loadingUserServices } = useFetchData(servicesUrl);
  const { data: allUsers } = useFetchData('users');
  const [selectedUser, setSelectedUser] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(services.length / itemsPerPage);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    setServices(userServices);
    setLoading(loadingUserServices);
  }, [userServices, loadingUserServices]);

  const handleUserChange = (e) => {
    const userId = e.target.value;
    setSelectedUser(userId);
    setServicesUrl(!isNaN(userId) ? `services/${userId}` : 'services');
  };

  if (loading) return (<Loading />);

  return (
    <>
      <Card my='22px' overflowX={{ sm: 'scroll', xl: 'hidden' }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex direction='row' justify='space-between' w={"100%"}>
            <Flex direction='column' align='flex-start' w='50%'>
              <Heading as="h1" color='#fff' fontWeight='bold' mb='.5rem'>
                Logs des services
                {services.length >= 200 && (
                  <InfoBubble message="Il s'agit ci-dessous des 200 dernières prises de service et non de la totalité." />
                )}
              </Heading>
              <Flex align='center'>
                <Icon
                  as={AiOutlineProfile}
                  color='green.500'
                  w='15px'
                  h='15px'
                  me='5px'
                />
                <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                  <Text fontWeight='bold' as='span' color='gray.400'>
                    {services.length}
                  </Text>{" "}
                  dernières prises de service
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <Select mb={"10px"} _hover={{ backgroundColor: '#030617', transform: 'scale(1.005)', cursor: 'pointer' }}
          border={"none"} value={selectedUser} onChange={handleUserChange}>
          <option style={{ backgroundColor: '#030617', color: 'white' }}>Tous</option>
          {allUsers && allUsers.map(user => (
            <option style={{ backgroundColor: '#030617', color: 'white' }} key={user.id} value={user.id}>{user.firstname} {user.name}</option>
          ))}
        </Select>
        <CardBody>
          {services.length < 1 ? (
            <Text color='gray.400' mb='20px'>
              Aucun service trouvé.
            </Text>
          ) : (
            <>
              <Table variant='simple' color='#fff'>
                <Thead>
                  <Tr my='.8rem' ps='0px'>
                    <Th
                      color='gray.400'
                      fontFamily='Plus Jakarta Display'
                      borderBottomColor='#56577A'>
                      Agent
                    </Th>
                    <Th
                      color='gray.400'
                      fontFamily='Plus Jakarta Display'
                      borderBottomColor='#56577A'>
                      Date de début
                    </Th>
                    <Th
                      color='gray.400'
                      fontFamily='Plus Jakarta Display'
                      borderBottomColor='#56577A'>
                      Date de fin
                    </Th>
                    <Th
                      color='gray.400'
                      fontFamily='Plus Jakarta Display'
                      borderBottomColor='#56577A'>
                      Durée
                    </Th>
                    <Th borderBottomColor='#56577A'></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {services.slice(startIndex, endIndex).map((row) => (
                    <TableServices
                      service={row}
                      lastItem={row.id === services[services.length - 1].id}
                    />
                  ))}
                </Tbody>
              </Table>
            </>
          )}
        </CardBody>
        {/* Pagination */}
        {totalPages > 0 && (
          <Flex justify="flex-start" mt="20px" mb="20px">
            <Button
              variant="ghost"
              color="white"
              _hover={{ bg: "brand.100" }}
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              <BiChevronLeft fontSize={32} />
            </Button>
            {Array.from({ length: totalPages }).map((_, index) => (
              <Button
                key={index}
                variant={currentPage === index + 1 ? "solid" : "ghost"}
                bg={currentPage === index + 1 ? "brand.200" : "transparent"}
                _hover={{ bg: "brand.100" }}
                color="white"
                onClick={() => goToPage(index + 1)}
                mx="2px"
              >
                {index + 1}
              </Button>
            ))}
            <Button
              variant="ghost"
              color="white"
              _hover={{ bg: "brand.100" }}
              onClick={nextPage}
              disabled={endIndex >= services.length}
            >
              <BiChevronRight fontSize={32} />
            </Button>
          </Flex>
        )}
      </Card>
    </>
  );
}

export default ListLogsService;