import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Input
} from "@chakra-ui/react";
import moment from 'moment';
import { usePostData } from "apiService";
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

function TableCrimes(props) {
  const { id, name, lastItem, handleDeleteCrime } = props;
  const toast = useToast();
  const { postData } = usePostData();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [newCrimeName, setNewCrimeName] = useState(""); // Ajout du nouvel état pour stocker le nouveau nom du crime
  const onCloseEdit = () => setIsEditOpen(false);

  useEffect(() => {
    // Mettre à jour le nom du crime dans le champ d'entrée lorsque la boîte de dialogue est ouverte
    if (isEditOpen) {
      setNewCrimeName(name);
    }
  }, [isEditOpen, name]);

  useEffect(() => {
    setNewCrimeName(name);
  }, [name]);

  const handleEdit = () => {
    setIsEditOpen(true);
  }

  const handleDelete = () => {
    setIsDeleteOpen(true);
  }

  const onCloseDelete = () => setIsDeleteOpen(false);

  const confirmDelete = async () => {
    try {
      // Appeler la méthode handleDeleteCrime du parent
      handleDeleteCrime(id);

      // Fermer la boîte de dialogue
      setIsDeleteOpen(false);
    } catch (err) {
      toast({
        title: "Erreur",
        description: "Impossible de supprimer le crime",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  const confirmEdit = async () => {
    try {
      // Mettre à jour le crime avec le nouveau nom
      const response = await postData(`updateCrime`, { id: id, name: newCrimeName });

      if (response) {
        setIsEditOpen(false);
        toast({
          title: "Crime modifié",
          description: "Le crime a été modifié",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (err) {
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour le crime",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Tr>
        <Td
          minWidth={{ sm: "250px" }}
          ps='0px'
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'>
          <Flex align='center' minWidth='100%' flexWrap='nowrap'>
            <Flex direction='column'>
              <Text
                fontSize='sm'
                color='#fff'
                fontWeight='normal'
                minWidth='100%'>
                {newCrimeName}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
          <Flex justifyContent="flex-end">
            <Button
            mr={2}
              onClick={() => handleDelete(id)}
              bg='red.400'
              color='white'
              _hover={{ bg: "red.300" }}
              _active={{ bg: "red.300" }}
              _focus={{ boxShadow: "none" }}
              size='sm'
              px='1.5rem'
              fontSize='sm'
              fontWeight='normal'
              ms='1rem'>
              <DeleteIcon/>
            </Button>
            <Button
              onClick={handleEdit}
              bg='brand.200'
              color='white'
              _hover={{ bg: "brand.100" }}
              _active={{ bg: "brand.100" }}
              _focus={{ boxShadow: "none" }}
              size='sm'
              px='1.5rem'
              fontSize='sm'
              fontWeight='normal'>
              <EditIcon/>
            </Button>
          </Flex>
        </Td>
      </Tr>

      {/* Popup de modification */}
      <AlertDialog
        isOpen={isEditOpen}
        leastDestructiveRef={null}
        onClose={onCloseEdit}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Modifier</AlertDialogHeader>
            <AlertDialogBody>
              <Input
                value={newCrimeName}
                onChange={(e) => setNewCrimeName(e.target.value)}
                placeholder="Nouveau nom du crime"
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onCloseEdit}>Annuler</Button>
              <Button colorScheme="blue" ml={3} onClick={confirmEdit}>
                Modifier
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Popup de suppression */}
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={null}
        onClose={onCloseDelete}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Confirmation de suppression</AlertDialogHeader>
            <AlertDialogBody>
              Êtes-vous sûr de vouloir supprimer le délit <Text as={"span"} fontWeight="bold">{name}</Text> ?<br /><br />⚠️ Cette action est irréversible et le délit sera supprimé de manière permanente de tous les documents associés.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onCloseDelete}>Annuler</Button>
              <Button colorScheme="red" ml={3} onClick={confirmDelete}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default TableCrimes;
