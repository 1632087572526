import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Flex,
  Button,
  Icon,
  ScaleFade,
} from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';
import "react-datepicker/dist/react-datepicker.css";
import PV from 'components/PV/PV';

function Arrest() {
  const { idArrest } = useParams();
  const [showPV, setShowPV] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setShowPV(true);
  }, []);

  return (
    <Flex direction='column' pt={"5%"} pb={"5%"}>
      <ScaleFade initialScale={0.1} in={showPV}>
        <PV buttonGoToCitizen={true} isEditing={false} idPV={idArrest} />
      </ScaleFade>
    </Flex>
  );
}

export default Arrest;
