import React, { useEffect, useState } from "react";
import { FaHeart, FaSave } from 'react-icons/fa';
import {
  Flex,
  Grid,
  GridItem,
  VStack,
  HStack,
  Text,
  Input,
  Button,
  Link,
  Switch,
  Select,
  Textarea,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Img,
} from "@chakra-ui/react";
import Loading from "../../misc/Loading";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import config from "config";
import InfoBubble from "misc/InfoBubble";
import { usePostData, useFetchData } from "apiService";
import { useToast } from "@chakra-ui/react";
import PV from "components/PV/PV.js";

function Configuration() {
  const [isLoading, setIsLoading] = useState(true);
  const { data: allSectors } = useFetchData('sectors');
  const { data: myCustomerName } = useFetchData("myCustomerName");
  const { data: myCustomerLogo } = useFetchData("myCustomerLogo");
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [textAreaOne, setTextAreaOne] = useState("");
  const [textAreaTwo, setTextAreaTwo] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = React.useRef();
  const [tenantName, setTenantName] = useState(config.tenantName);
  const [tenantLogo, setTenantLogo] = useState(config.logo);
  const [saveButton, setSaveButton] = useState(false);
  const toast = useToast();
  const { postData } = usePostData();

  const onChangeTenantName = (e) => {
    setTenantName(e.target.value);

    // Enable or disable the save button
    if (config.tenantName === e.target.value && config.logo === tenantLogo) {
      setSaveButton(false);
    } else {
      setSaveButton(true);
    }
  };

  const onChangeTenantLogo = (e) => {
    setTenantLogo(e.target.value);

    // Enable or disable the save button
    if (config.logo === e.target.value && config.tenantName === tenantName) {
      setSaveButton(false);
    } else {
      setSaveButton(true);
    }
  };

  const saveTenantLogo = async (e) => {
    e.preventDefault();

    const response = await postData("updateMyCustomerLogo", { logo_url: tenantLogo });
    if (response) {
      // Change the tenant logo
      config.setLogo(tenantLogo);
      setSaveButton(false);
    }

  };

  const saveTenant = async (e) => {
    e.preventDefault();

    if (tenantName !== config.tenantName) {
      saveTenantName(e);
    }

    if (tenantLogo !== config.logo) {
      saveTenantLogo(e);
    }

    toast({
      title: "Modification enregistrée",
      description: "Vos modifications ont été enregistrées avec succès.",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };


  const saveTenantName = async (e) => {
    e.preventDefault();

    const response = await postData("updateMyCustomerName", { name: tenantName });
    if (response) {
      // Change the tenant name
      config.setTenantName(tenantName);
      setSaveButton(false);
    }
  };

  useEffect(() => {
    if (myCustomerName && myCustomerLogo) {
      const tenantName = myCustomerName[0];
      setTenantName(tenantName ? tenantName?.name : "");
      const logo = myCustomerLogo[0];
      setTenantLogo(logo ? logo?.logo_url : "");
    }
  }, [myCustomerName, myCustomerLogo]);

  useEffect(() => {
    if (allSectors) {
      setSectors(allSectors);
      setIsLoading(false);
    }
  }, [allSectors]);

  const handleSectorChange = (e) => {
    if (isDirty) {
      setIsOpen(true);
      return;
    }
    const sectorId = e.target.value;
    const sSector = sectors.find(sector => sector.id === parseInt(sectorId));
    if (sSector) {
      setSelectedSector(sSector);
      setTextAreaOne(sSector.text_introduction);
      setTextAreaTwo(sSector.text_left_sidebar);
      setIsDirty(false);
    }
  };

  const resetForm = () => {
    setTextAreaOne("");
    setTextAreaTwo("");
    setIsDirty(false);
  };

  const handleSave = async () => {
    if (!isDirty) {
      return;
    }
    setIsDirty(false);
    const response = await postData(`updateSector`, {
      id: selectedSector.id,
      text_introduction: textAreaOne,
      text_left_sidebar: textAreaTwo,
    });

    if (response) {

      // Update the sector in the sectors array
      let updatedSectors = sectors.map(sector => {
        if (sector.id === selectedSector.id) {
          return {
            ...sector,
            text_introduction: textAreaOne,
            text_left_sidebar: textAreaTwo,
          };
        }
        return sector;
      });
      setSectors(updatedSectors);

      // Success
      toast({
        title: "Modifications enregistrées",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const onClose = () => setIsOpen(false);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {/* TITLE - paramètres & infos */}
      <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
        <Card>
          <VStack align="left">
            <Text fontSize='xl' fontWeight='bold' color={"white"}>
              Configuration de <Text as="span" fontWeight="extrabold" color={"#41C5E6"}>{config.appName}</Text>
            </Text>
            <HStack>
              <Text fontSize='sm' color={"white"}>
                Merci infiniment d'utiliser <Text as="span" fontWeight="bold">{config.appName}</Text> !
              </Text>
              <FaHeart color='red' />
            </HStack>
            <Text fontSize='sm' color={"white"}>
              {config.appName} est une application en constante évolution. Si vous avez des suggestions ou des idées d'amélioration, n'hésitez pas à nous en faire part depuis le bouton "Signaler" en haut de l'écran.
            </Text>
            <Text fontSize='sm' color={"white"}>
              Si vous avez des questions ou des retours, n'hésitez pas à nous contacter par mail à <Text as="span" color={"#41C5E6"}><a href="mailto:contact@nelx.net">contact@nelx.net</a></Text>.
              <br />
              Retrouvez tous les paramètres de l'application ci-dessous.
            </Text>
          </VStack>
        </Card>
        <Grid templateColumns="repeat(2, 1fr)" gap={6} width={"100%"} mt={22}>

          {/* GAUCHE */}
          <Card>
            <GridItem colSpan={1}>
              <Text as="span" fontWeight="bold" color={"white"} fontSize={"xl"}>Paramètres:</Text>
              <VStack align="left">

                {/* Nom de votre serveur */}
                <Text as="span" fontWeight="bold" color={"white"} mt="10px">Nom de votre serveur:</Text>
                <Input placeholder="Nom de votre serveur" color="white" width={"250px"} value={tenantName} onChange={onChangeTenantName} />
                <VStack align="left">
                  <Text as="span" fontWeight="bold" color={"white"} mt="10px">URL de votre logo:<InfoBubble message="Il est déconseillé d'insérer un lien vers une image discord car celles-ci peuvent ne pas être accessible par tous le monde et/ou peuvent disparaitre avec le temps." /></Text>
                  <HStack>
                    <Input placeholder="URL de votre logo" color="white" value={tenantLogo} onChange={onChangeTenantLogo} />
                    <Img src={tenantLogo} alt="logo" width="50px" borderRadius="10px" />
                  </HStack>
                </VStack>
                <Flex justify="flex-end" width="100%">
                  <Button
                    colorScheme="teal"
                    leftIcon={<FaSave />}
                    disabled={!saveButton}
                    onClick={saveTenant}
                    width={"250px"}
                  >
                    Enregistrer
                  </Button>
                </Flex>

                {/* 
                <Flex align='center' mb='20px'>
                  <Switch variant='brand' colorScheme='brand' me='10px' />
                  <Text noOfLines={1} fontSize='sm' color={'white'} fontWeight='400'>
                    Mode maintenance
                  </Text>
                </Flex>
                <Flex align='center' mb='20px'>
                  <Switch colorScheme='brand' me='10px' />
                  <Text noOfLines={1} fontSize='sm' color={'white'} fontWeight='400'>
                    Email me when someone answers on my post
                  </Text>
                </Flex>
                <Flex align='center' mb='20px'>
                  <Switch colorScheme='brand' me='10px' defaultChecked />
                  <Text noOfLines={1} fontSize='sm' color={'white'} fontWeight='400'>
                    Email me when someone mentions me
                  </Text>
                </Flex>
                */}}

              </VStack>
            </GridItem>
          </Card>

          {/* DROITE */}
          <Card>
            <GridItem colSpan={1}>
              <Text as="span" fontWeight="bold" color={"white"} fontSize={"xl"}>Informations:</Text>
              <HStack align="left">
                <Text as="span" fontWeight="bold" color={"white"}>Version de {config.appName}:</Text>
                <Text color={"white"}>{config.version}</Text>
              </HStack>
              <HStack align="left">
                <Text as="span" fontWeight="bold" color={"white"}>Mon abonnement:</Text>
                <Text color={"green.200"}>Actif{" "}
                  <Text as="span" color="white">(
                    <Link color="blue.200"
                      href={config.my_licence_url}
                      _hover={{ color: "blue.300" }}
                      target="_blank"
                      fontSize={"12px"}>Gérer mon abonnement</Link>
                    )</Text>
                </Text>
              </HStack>
            </GridItem>
            <GridItem colSpan={1} mt={22}>
              <Text as="span" fontWeight="bold" color={"white"} fontSize={"xl"}>Parrainage:</Text>
              <br />
              <Text as="span" color={"white"}>Pour vous remercier de votre fidélité, nous vous proposons <Text as="span" color={"orange"}>5 €</Text> de réduction sur votre facture mensuelle pour chaque client abonné par votre intermédiaire ! Pour ce faire, le client doit simplement indiquer qu'il a été parrainé par vous lors de son inscription.</Text>
            </GridItem>
          </Card>
        </Grid>
      </Flex>

      {/* Configuration du PV */}
      {config.role("admin") && (
        <Flex direction='column'>
          <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
            <Text as="span" fontWeight="bold" color={"white"} fontSize={"xl"}>Configuration des procès-verbaux:</Text>
            <CardHeader p='6px 0px 22px 0px'>
              <Select
                mb={"10px"}
                _hover={{ backgroundColor: '#030617', transform: 'scale(1.005)', cursor: 'pointer' }}
                border={"none"}
                value={selectedSector ? selectedSector.id : null}
                onChange={handleSectorChange}
                color='white'
                fontSize={"24x"}
              >
                <option style={{ backgroundColor: '#030617', color: 'white' }}>Sélectionner un secteur</option>
                {sectors.map(sector => (
                  <option style={{ backgroundColor: '#030617', color: 'white' }} key={sector.id} value={sector.id}>
                    {sector.name}
                  </option>
                ))}
              </Select>
            </CardHeader>
            <CardBody>
              {selectedSector && (
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <GridItem w="100%">
                    <Text fontSize='32px' color='#fff' fontWeight='bold' mb='.5rem'>
                      Paramètres
                    </Text>
                    <Text fontSize='sm' color='gray.400' fontWeight='bold'>Texte d'introduction</Text>
                    <Textarea
                      placeholder="Votre texte ici..."
                      color="white"
                      height="150px"
                      value={textAreaTwo}
                      onChange={(e) => { setTextAreaTwo(e.target.value); setIsDirty(true); }}
                      mb="4"
                    />
                    <Textarea
                      placeholder="Votre texte ici..."
                      color="white"
                      value={textAreaOne}
                      width="100%"
                      height="150px"
                      onChange={(e) => { setTextAreaOne(e.target.value); setIsDirty(true); }}
                      mb="4"
                    />
                    <Button
                      opacity={isDirty ? 1 : 0.3}
                      colorScheme="blue"
                      onClick={handleSave}>
                      Enregistrer les modifications
                    </Button>
                  </GridItem>
                  <GridItem w="100%" mb={"30px"}>
                    <Text fontSize='32px' color='#fff' fontWeight='bold' mb='.5rem'>
                      Prévisualisation
                      <InfoBubble message="Les champs éditables sont affichés en rouge" />
                    </Text>

                    <PV confMode={true} width="100%" text_introduction={textAreaOne} text_left_sidebar={textAreaTwo} buttonBack={false} />
                  </GridItem>
                </Grid>
              )}
            </CardBody>
          </Card>

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Modifications non enregistrées
                </AlertDialogHeader>

                <AlertDialogBody>
                  Vous avez des modifications non enregistrées. Voulez-vous les abandonner ?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Rester sur la page
                  </Button>
                  <Button colorScheme="red" onClick={() => { resetForm(); setIsOpen(false); }} ml={3}>
                    Abandonner les modifications
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Flex>
      )}
    </>
  );
}

export default Configuration;
