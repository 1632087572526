import React, { useState, useEffect } from "react";
import Loading from "../../misc/Loading";

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Icon,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// Table Components
import TableValidateUsers from "components/Tables/TableValidateUsers";

// Icons
import { AiOutlineProfile, AiOutlinePlus } from "react-icons/ai";
import { useToast } from "@chakra-ui/react";

// Api service
import { useFetchData, usePostData } from "apiService";
import ListCrimes from "components/Lists/ListCrimes";
import ListGrades from "components/Lists/ListGrades";
import ListArrestTypes from "components/Lists/ListArrestTypes";
import config from "config";

function Admin() {

  const { data: allUsers, isLoading: isLoadingUsers } = useFetchData('usersToValidate');
  const [allUsersToValidate, setAllUsersToValidate] = useState([]);
  const { data: allGrades } = useFetchData('grades');
  const { data: allRoles } = useFetchData('allRoles');

  const toast = useToast();
  const { postData } = usePostData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (allUsers.length > 0) {
      setAllUsersToValidate(allUsers);
    }
  }, [allUsers]);


  if (isLoadingUsers) return <Loading />;

  const handleValidate = async (username, selectedRole, selectedGrade) => {
    try {
      const response = await postData('validateUser', { username, selectedRole, selectedGrade });

      if (response) {
        // Refresh user data
        setAllUsersToValidate(allUsersToValidate.filter(user => user.username !== username));

        toast({
          title: "Utilisateur validé",
          description: username + " a été validé",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }

    } catch (error) {

      toast({
        title: "Oops",
        description: "Impossible de valider l'utilisateur",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  const handleRefuse = async (username) => {
    try {
      const response = await postData('deleteUser', { username });

      if (response) {
        // Refresh user data
        setAllUsersToValidate(allUsersToValidate.filter(user => user.username !== username));

        toast({
          title: "Utilisateur refusé",
          description: username + " a été refusé",
          status: "warning",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
    catch (error) {
      toast({
        title: "Oops",
        description: "Impossible de refuser l'utilisateur",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      {/* Demandes d'accès */}
      {config.role("staff") && (
      <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex direction='column'>
            <Text fontSize='lg' color='#fff' fontWeight='bold' mb='.5rem'>
              Demandes d'accès
            </Text>
            <Flex align='center'>
              <Icon
                as={AiOutlineProfile}
                color='green.500'
                w='15px'
                h='15px'
                me='5px'
              />
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                <Text fontWeight='bold' as='span' color='gray.400'>
                  {allUsersToValidate.length}
                </Text>{" "}
                nouvelles demandes
              </Text>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          {allUsersToValidate < 1 ? (
            <Text color="gray.400" mb="20px">Vous n'avez aucune demande d'accès à valider pour le moment.</Text>
          ) : (
            <Table variant='simple' color='#fff'>
              <Thead>
                <Tr my='.8rem' ps='0px'>
                  <Th
                    ps='0px'
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    Identifiant
                  </Th>
                  <Th
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    Nom
                  </Th>
                  <Th
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    Prénom
                  </Th>
                  <Th
                    color='gray.400'
                    fontFamily='Plus Jakarta Display'
                    borderBottomColor='#56577A'>
                    Date et heure de la demande
                  </Th>
                  <Th borderBottomColor='#56577A'></Th>
                </Tr>
              </Thead>
              <Tbody>
                {allUsersToValidate.map((row, index, arr) => {
                  return (
                    <TableValidateUsers
                      name={row.name}
                      firstname={row.firstname}
                      username={row.username}
                      date={row.date_creation}
                      handleValidate={handleValidate}
                      handleRefuse={handleRefuse}
                      roles={allRoles}
                      grades={allGrades}
                      lastItem={index === arr.length - 1 ? true : false}
                    />
                  );
                })}
              </Tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      )}

      {/* Crimes */}
      {config.role("admin") && ( <ListCrimes /> )}


      {/* Grades */}
      {config.role("admin") && ( <ListGrades /> )}

      {/* Type d'arrestation */}
      {config.role("admin") && ( <ListArrestTypes /> )}
    </Flex>
  );
}

export default Admin;
