import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Select,
  Switch,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  Link,
  HStack,
  VStack,
  Heading,
  Textarea,
  Grid,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import moment from 'moment';
import { usePostData } from "apiService";
import { Link as RouterLink } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { Separator } from "components/Separator/Separator";
import InfoBubble from "misc/InfoBubble";
import { nanoid } from "nanoid";

function TableCustomers(props) {
  const { id, customer, selectedCustomerID, onSelectCustomer, stats } = props;
  const [expanded, setExpanded] = useState(false);
  const toast = useToast();
  const { postData } = usePostData();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editCustomer, setEditCustomer] = useState({
    name: '', email: '', is_disabled: true, accessRequestToken: '', notes: '', is_disabled_reason: ''
  });
  const [deleteConfirmText, setDeleteConfirmText] = useState('');

  useEffect(() => {
    if (customer) {
      setEditCustomer({
        name: customer.name,
        email: customer.email,
        is_disabled: customer.is_disabled,
        accessRequestToken: customer.access_request_token,
        notes: customer.notes,
        is_disabled_reason: customer.is_disabled_message
      });
    }
  }, [customer]);

  // Fonction pour gérer le changement de status
  const handleStatusChange = () => {
    setEditCustomer({ ...editCustomer, is_disabled: !editCustomer.is_disabled });
  };

  // Fonction pour générer un nouveau token
  const handleGenerateToken = () => {
    setEditCustomer({ ...editCustomer, accessRequestToken: nanoid() });
  };

  const handleDeleteCustomer = async () => {
    if (deleteConfirmText === customer.name) {
      const response = await postData('deleteCustomer', { id: customer.id });
      if (response) {
        toast({
          title: "Client supprimé",
          description: "Le client a été supprimé avec succès. REFRESH LA PAGE",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Erreur",
          description: "Une erreur s'est produite lors de la suppression du client.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      }
      setDeleteModalOpen(false);
    } else {
      toast({
        title: "Erreur",
        description: "Le nom du client ne correspond pas.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  // Fonction pour la soumission du formulaire
  const handleSubmit = async () => {
    if (editCustomer.is_disabled && !editCustomer.is_disabled_reason) {
      toast({
        title: "Erreur",
        description: "Veuillez fournir une raison pour la désactivation du client.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const response = await postData('updateCustomer', {
      id: customer.id,
      name: editCustomer.name,
      email: editCustomer.email,
      is_disabled: editCustomer.is_disabled,
      is_disabled_reason: editCustomer.is_disabled_reason,
      access_request_token: editCustomer.accessRequestToken,
      notes: editCustomer.notes,
    });
    if (response) {
      toast({
        title: "Client modifié",
        description: "Le client a été modifié avec succès.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la modification du client.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }

    setEditModalOpen(false);
  };

  return (
    <>
      <Card borderColor={selectedCustomerID === customer.id ? "green" : "transparent"} borderWidth={selectedCustomerID === customer.id ? "2px" : "1px"} >
        <CardHeader>
          <Flex align="center" justifyContent="space-between" width="100%"> {/* Ajout de justifyContent et width */}
            <Flex align="center" flexGrow={1}> {/* Conteneur pour Avatar et Heading */}
              <Avatar size="sm" name={customer.name} src={customer.avatar} marginRight="20px" /> {/* Ajout de marginRight */}
              <Heading as="h1" size="xl" color="white" whiteSpace={"nowrap"} height={"50px"}>
                {customer.name}
              </Heading>
            </Flex>
            <Button
              colorScheme="blue"
              size="sm"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Réduire" : "Voir plus"}
            </Button>
          </Flex>
        </CardHeader>
        {expanded && (
          <CardBody>
            <HStack spacing={4} align="stretch" flex="1" pr={5} width={"200px"}>
              <VStack spacing={4} align="stretch" flex="1">
                <HStack spacing={4} align="stretch" flex="1">
                  <VStack spacing={1} align="stretch" flex="1">
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Identifiant:</b> {customer && customer.id}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Nom:</b> {editCustomer && editCustomer.name}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Email:</b> {editCustomer && editCustomer.email}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Status:</b> {editCustomer && editCustomer.is_disabled ? <><Text as="span" color="red">Inactif</Text> <Text as="span" color="coral">({editCustomer && editCustomer.is_disabled_reason})</Text></> : <Text as="span" color="green">Actif</Text>}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Access request token:</b> {editCustomer && editCustomer.accessRequestToken}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Date de création:</b> {customer && moment(customer.date_creation).format('DD/MM/YYYY')}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Notes:</b></Text>
                    <Textarea color={"white"} readOnly ml={2} width={"100%"} value={editCustomer && editCustomer.notes} />
                  </VStack>
                  <VStack spacing={1} align="stretch" flex="1" pl={150}>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Quantité d'utilisateurs:</b> {stats && stats.users_count}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Quantité d'arrestations:</b> {stats && stats.arrests_count}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Quantité de type d'arrestations:</b> {stats && stats.arrest_types_count}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Quantité de citoyens:</b> {stats && stats.citizens_count}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Quantité de crimes:</b> {stats && stats.crimes_count}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Quantité de grades:</b> {stats && stats.grades_count}</Text>
                    <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Quantité de prises de services:</b> {stats && stats.services_count}</Text>
                  </VStack>
                </HStack>
                <Separator />

                {/* ACTIONS */}
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <HStack spacing={4} align="stretch" flex="1" justify="left">

                    {/* Sélectionné */}
                    <Button
                      colorScheme={selectedCustomerID === customer.id ? "green" : "blue"}
                      onClick={() => onSelectCustomer(customer.id)}
                    >
                      {selectedCustomerID === customer.id ? "Sélectionné" : "Sélectionner"}
                    </Button>
                    <InfoBubble message="Sélectionner un client permet de l'afficher dans les autres menus ;)" />

                  </HStack>
                  <HStack spacing={4} align="stretch" flex="1" justify="right">
                    {/* SUPPRIMER */}
                    <Button
                      backgroundColor={"red.900"}
                      color={"white"}
                      onClick={() => setDeleteModalOpen(true)}
                    >
                      Supprimer
                    </Button>

                    {/* MODIFIER */}
                    <Button
                      colorScheme="blue"
                      onClick={() => setEditModalOpen(true)}
                    >
                      Modifier
                    </Button>

                  </HStack>
                </Grid>

              </VStack>
            </HStack>
          </CardBody>
        )}
      </Card>

      {/* Modale pour la suppression */} {/* ask to rewrite the server name for confirmation */}
      <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Supprimer le Client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Êtes-vous sûr de vouloir supprimer le client <b>{customer.name}</b> ?</Text>
            <Text color="red">Cette action est irréversible.</Text>
            <Text>Si vous êtes sûr, veuillez écrire le nom du client ci-dessous pour confirmer.</Text>
            <Text color="red">Attention à ne pas l'avoir sélectionné, sinon good bye le user</Text>
            <Input
              value={deleteConfirmText}
              onChange={(e) => setDeleteConfirmText(e.target.value)}
              placeholder="Nom du client"
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteCustomer}>
              Supprimer
            </Button>
            <Button onClick={() => setDeleteModalOpen(false)}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modale pour la modification */}
      <Modal isOpen={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier le Client</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Nom</FormLabel>
              <Input
                value={editCustomer.name}
                onChange={(e) => setEditCustomer({ ...editCustomer, name: e.target.value })}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input
                value={editCustomer.email}
                onChange={(e) => setEditCustomer({ ...editCustomer, email: e.target.value })}
              />
            </FormControl>

            <FormControl mt={4} display="flex" alignItems="center">
              <FormLabel>Activé</FormLabel>
              <Switch
                isChecked={!editCustomer.is_disabled}
                onChange={handleStatusChange}
              />
            </FormControl>
            {editCustomer && editCustomer.is_disabled && (
              <FormControl mt={4}>
                <FormLabel>Raison de la désactivation</FormLabel>
                <Input
                  value={editCustomer.is_disabled_reason}
                  onChange={(e) => setEditCustomer({ ...editCustomer, is_disabled_reason: e.target.value })}
                />
              </FormControl>
            )}

            <FormControl mt={4}>
              <FormLabel>Access Request Token</FormLabel>
              <Input value={editCustomer.accessRequestToken} isReadOnly />
              <Button mt={2} onClick={handleGenerateToken}>Générer</Button>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Notes</FormLabel>
              <Textarea
                value={editCustomer.notes}
                onChange={(e) => setEditCustomer({ ...editCustomer, notes: e.target.value })}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Enregistrer
            </Button>
            <Button onClick={() => setEditModalOpen(false)}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>



    </>
  );
}

export default TableCustomers;